import { initApiKeysTable, loadKeysForAccount } from './js/portal/apikeys';
import { Datatable } from './Datatable';
import AccountDropdown from './jsx/components/forms/AccountDropdown';

const tableId = 'apiKeysTable';

export default function ItsmManageApiKeysPage() {
  const page = (
    <>
      <div class="row">
        <div class="col-lg-12">
          <div id="form-error" class="alert alert-danger" style="display: none">
            <ul />
          </div>
          <div id="form-success" class="alert alert-success" style="display: none">
            <ul />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-7 px-2 mb-4 d-flex flex-column">
          <div id="infobox" class="card-body alert alert-warning">
            <div class="card-body">
              <p>
                <strong>
                  The ITSM.Next Team has launched its own API with more capabilities instead of the AWS ITSM Bridge.
                  Consider to use the ITSM.Next API directly instead of the AWS ITSM Bridge.
                </strong>
              </p>
              <p>
                The AWS ITSM Bridge is still available under the existing SLAs. New features which will become available
                with the migration into ITSM.Next will only be provided in the ITSM.Next API. We highly recommend to use
                the ITSM.Next API directly instead of the AWS ITSM Bridge and the new api keys will not autoapproved
                anymore.
              </p>
              <p>
                Documentation about the ITSM.Next API can be found here:&nbsp;
                <a href="https://atc.bmwgroup.net/confluence/x/6DTQyw " target="_blank" rel="noopener noreferrer">
                  ITSM Next Documentation
                </a>
              </p>
            </div>
          </div>
          <div class="pt-4 px-1 flex-grow-1">
            Manage your API keys for the AWS ITSM Gateway. Please find more information about the AWS ITSM Gateway in
            this short{' '}
            <a
              class="nobr"
              href="https://developer.bmwgroup.net/docs/public-cloud-platform-aws/3_runyourapplication/monitoring_and_alerting/itsm_gateway/itsm_gateway/"
              target="_blank"
              rel="noopener noreferrer">
              <i class="fas fa-book" /> HOW TO
            </a>
            &nbsp;
            <small>(internal Documentation)</small>
          </div>
          <div class="my-3">
            <AccountDropdown label="none" id="aws-account-id" onchange={loadKeysForAccount} short lookup />
          </div>
        </div>

        <div class="col-md-5 order-first order-md-last">
          <div id="infobox" class="card infobox">
            <div class="card-body">
              <ul class="overview">
                <li class="overview">
                  <i class="fas fa-sign-in-alt" /> (Public) API Endpoint:{' '}
                  <a href="https://itsm-gateway.aws.bmw.cloud/v1/itsm" target="_blank" rel="noopener noreferrer">
                    https://itsm-gateway.aws.bmw.cloud/v1/itsm
                  </a>
                </li>
                <li class="overview">
                  <a
                    class="nobr"
                    href="https://atc.bmwgroup.net/bitbucket/projects/FPCBMW/repos/aws-itsm-api/raw/src/create_itsm_ticket/itsm_schema.json?at=refs%2Fheads%2Fmaster"
                    target="_blank"
                    rel="noopener noreferrer">
                    <i class="fas fa-book" /> JSON Schema
                  </a>{' '}
                  for validation <small>(internal repository)</small>
                </li>
                <li class="overview">
                  <a
                    class="nobr"
                    href="https://developer.bmwgroup.net/docs/public-cloud-platform-aws/3_runyourapplication/monitoring_and_alerting/itsm_gateway/itsm_gateway/"
                    target="_blank"
                    rel="noopener noreferrer">
                    <i class="fas fa-book" /> Highlevel Overview, HowTo and FAQs
                  </a>
                  &nbsp;
                  <small>(internal Documentation)</small>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <br />

      <div class="row" id="edit-api-key-form" style="display: none" />

      <Datatable tableId={tableId} />
    </>
  );

  function onPageReady() {
    initApiKeysTable(tableId, 'itsm');
  }

  return [page, onPageReady];
}
