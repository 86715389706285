import {
  addCopyButton,
  addLoadTrainingDetailsButton,
  configureTableColumns,
  RowDetails,
  rowDetailsClickHandler,
  InfoTable,
  InfoTableWide,
  addContentButtonWithTooltip,
  addToast,
  generateRandom,
} from './main';
import { getData } from './databases';
import { apiAxios, showError } from './api';
import swal from 'sweetalert';
import { collectTrainingData, setTrainingAccountCount } from './trainingsPlan';
import { TrainingPlanFormBody } from '../../TrainingsPlanPage';
import { addSpinner, addTableSpinner, removeSpinners } from './sidebar';
import { initDataTable } from './datatable';
import { Modal } from 'bootstrap';

// Column configuration for the datatable
export const trainingsTableColumns = [
  { id: 'select_col' },
  { id: 'training_id_col', name: 'Training Id', attribute_name: 'training_id' },
  { id: 'type_col', name: 'Type', attribute_name: 'type' },
  { id: 'proposer_col', name: 'Proposer', attribute_name: 'proposer' },
  { id: 'creation_date_col', name: 'Creation Date', attribute_name: 'creation_date' },
  { id: 'start_date_col', name: 'Start Date', attribute_name: 'start_date' },
  { id: 'end_date_col', name: 'End Date', attribute_name: 'end_date' },
  { id: 'accounts_requested_col', name: 'Accounts Requested', attribute_name: 'accounts_requested' },
  { id: 'accounts_assigned_col', name: 'Accounts Assigned / Requested', attribute_name: 'accounts_assigned' },
  { id: 'trainers_col', name: 'Trainers', attribute_name: 'trainers' },
  { id: 'status_col', name: 'Status', attribute_name: 'status' },
  { id: 'status_update_col', name: 'Status Update', attribute_name: 'status_update' },
  { id: 'actions_col', name: 'Actions' },
];

export const trainingInfoFields = [
  { Name: 'Training Id', Value: 'training_id' },
  { Name: 'Type', Value: 'type' },
  { Name: 'Start Date', Value: 'start_date' },
  { Name: 'End Date', Value: 'end_date' },
  { Name: 'Trainers', Value: 'trainers' },
  { Name: 'Accounts Requested', Value: 'accounts_requested' },
  { Name: 'Notes', Value: 'notes' },
  { Name: 'Proposer', Value: 'proposer' },
  { Name: 'Creation Date', Value: 'creation_date' },
  { Name: 'Status', Value: 'status' },
  { Name: 'Status Update (UTC)', Value: 'status_update' },
];

/**
 *
 * @param {string} tableId
 */
export function initTrainingsTable(tableId) {
  const permissions = localStorage.permissions || [];

  configureTableColumns(tableId, trainingsTableColumns);

  initDataTable(
    tableId,
    'lCfrtpBi',
    [
      {
        text: 'Update Training Status',
        action: checkTrainingStatus,
        titleAttr: 'Check and update the training states',
        enabled: permissions.includes('manage_all_trainings') ? true : false,
      },
      {
        extend: 'excelHtml5',
        text: 'Export Excel',
        exportOptions: {
          columns: ':visible',
        },
        titleAttr: 'Export the visible columns as Excel file',
      },
      {
        extend: 'csvHtml5',
        text: 'Export CSV',
        exportOptions: {
          columns: ':visible',
        },
        titleAttr: 'Export the visible columns as CSV file',
      },
      {
        extend: 'copyHtml5',
        text: 'Copy',
        exportOptions: {
          columns: ':visible',
        },
        titleAttr: 'Copy the visible columns into your clipboard',
      },
      {
        extend: 'resetTable',
        ajaxReload: false,
        titleAttr: 'Reset all filters in the table footer',
      },
      {
        extend: 'reloadTable',
        text: 'Reload Accounts',
        ajaxReload: false,
        methodReload: loadTrainingsData,
        titleAttr: 'Reload AWS accounts (no-cache)',
      },
    ],
    [
      {
        visible: true,
        defaultContent: '',
        orderable: false,
        searchable: false,
        data: null,
        name: 'select_col',
        class: 'details-control',
        width: '20px',
      },
      {
        visible: false,
        defaultContent: '',
        orderable: true,
        searchable: true,
        data: 'training_id',
        name: 'training_id_col',
        title: 'Training Id',
        createdCell: function (td) {
          addCopyButton(td);
          addLoadTrainingDetailsButton(td);
        },
      },
      {
        visible: true,
        defaultContent: 'unknown',
        orderable: true,
        searchable: true,
        data: 'type',
        name: 'type_col',
        title: 'Type',
        createdCell: addCopyButton,
      },
      {
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'proposer',
        name: 'proposer_col',
        title: 'Proposer',
        createdCell: addCopyButton,
      },
      {
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'creation_date',
        name: 'creation_date_col',
        title: 'Creation Date',
        createdCell: addCopyButton,
      },
      {
        visible: true,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'start_date',
        name: 'start_date_col',
        title: 'Start Date',
        createdCell: addCopyButton,
      },
      {
        visible: true,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'end_date',
        name: 'end_date_col',
        title: 'End Date',
        createdCell: addCopyButton,
      },
      {
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'accounts_requested',
        name: 'accounts_requested_col',
        title: 'Accounts Requested',
        createdCell: addCopyButton,
      },
      {
        visible: true,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: null,
        name: 'accounts_assigned_col',
        title: 'Accounts Assigned / Requested',
        createdCell: function (td, _cellData, rowData) {
          const accountsRequested = rowData.accounts_requested;
          const accountsAssigned = rowData.accounts_assigned ?? [];

          if (accountsAssigned.length == accountsRequested) {
            $(td).addClass('portal-success');
          } else if (accountsAssigned.length && accountsAssigned.length != accountsRequested) {
            $(td).addClass('portal-danger');
          }

          $(td).addCopyButton();
        },
        render: function (_data, type, rowData, _meta) {
          const accountsRequested = rowData.accounts_requested ?? 0;
          const accountsAssigned = rowData.accounts_assigned ?? [];
          const displayText = accountsAssigned.length + ' of ' + accountsRequested;

          if (type === 'display') {
            return displayText;
          } else if (type === 'sort') {
            return accountsAssigned.length;
          } else {
            return displayText;
          }
        },
      },
      {
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'trainers',
        name: 'trainers_col',
        title: 'Trainers',
        createdCell: addCopyButton,
      },
      {
        visible: true,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'status',
        name: 'status_col',
        title: 'Status',
        createdCell: function (td, cellData) {
          addCopyButton(td);

          if (['ACTIVE', 'ASSIGNED', 'PREPARED'].includes(cellData)) {
            $(td).addClass('portal-success');
          } else if (['NEW', 'CANCELED'].includes(cellData)) {
            $(td).addClass('portal-neutral');
          } else {
            $(td).addClass('portal-danger');
          }
        },
      },
      {
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'status_update',
        name: 'status_update_col',
        title: 'Status Update (UTC)',
        createdCell: addCopyButton,
      },
      {
        visible: true,
        defaultContent: '',
        orderable: false,
        data: null,
        name: 'actions_col',
        title: 'Actions',
        createdCell: addTrainingTableActions,
        class: 'details-edit',
        width: '50px',
      },
    ],
    function (row, data) {
      if (data.status === 'DELETED') {
        $(row).addClass('row-deleted');
      } else if (data.status === 'CANCELED') {
        $(row).addClass('row-inactive');
      }
    },
    {
      order: [[5, 'desc']],
    },
  );

  rowDetailsClickHandler({ tableId: tableId, rowDetailCallback: loadTrainingDataRowDetails });
  loadTrainingsData(tableId);
}

/**
 *
 * @param {string} tableId
 * @param {Array} headers
 * @param {Boolean} forceReload
 */
function loadTrainingsData(tableId, headers, forceReload) {
  $(() => addSpinner());
  $(() => addTableSpinner());

  const dropdownColumns = ['type', 'status'];
  const searchColumns = [
    'training_id_col',
    'proposer_col',
    'creation_date_col',
    'start_date_col',
    'end_date_col',
    'accounts_requested_col',
    'accounts_assigned_col',
    'trainers_col',
    'order_id_col',
    'status_update_col',
  ];

  getData({
    apiPath: '/trainings',
    dataAttribute: 'trainings',
    tableId: tableId,
    tableColumns: trainingsTableColumns,
    dropdownColumns: dropdownColumns,
    searchColumns: searchColumns,
    headers: headers,
    forceReload: forceReload,
  });
}

/**
 *
 * @param {*} row
 */
function loadTrainingDataRowDetails(row) {
  // Load the complete training data before the child row will be formatted
  const trainingDetailsNavigation = [
    {
      Value: 'training',
      Name: 'Training',
      active: true,
      renderDetails: formatTrainingsTab,
    },
  ];

  apiAxios
    .get('/trainings/' + row.data().training_id)
    .then(response => {
      const rowDetails = (
        <RowDetails
          tabDefinition={trainingDetailsNavigation}
          rowData={response.data.training}
          tabIdAttribute={'training_id'}
        />
      );
      row.child(rowDetails);
      row.child()[0].setAttribute('class', 'rowDetails');
    })
    .finally();
}

/**
 *
 * @param {Dict} rowData
 * @returns JSX.Element
 */
function formatTrainingsTab({ rowData }) {
  return (
    <>
      <div class="col-lg-8 col-md-12" name="training-details">
        <ChildRowTrainingDetails training={rowData} />
      </div>
      <div class="col-lg-4 col-md-12" name="training-details-accounts">
        {rowData.accounts_assigned && <ChildRowTrainingDetailsAccounts training={rowData} />}
      </div>
      <div class="col-lg-12 col-md-12" name="training-details-button">
        <ChildRowTrainingDetailsButtons training={rowData} />
      </div>
    </>
  );
}

/**
 *
 * @param {Dict} training
 * @returns JSX.Element
 */
function ChildRowTrainingDetailsAccounts({ training }) {
  const accountIds = training.accounts_assigned ?? [];
  const rowInfos = [];
  accountIds.forEach(account_id => {
    rowInfos.push({ 'Account Id': account_id });
  });

  return (
    <div class="detailsContent">
      <h4>Assigned Accounts</h4>
      <div>
        <InfoTableWide data={rowInfos} colInfos={['Account Id']} rowNumbers={true} />
      </div>
    </div>
  );
}

/**
 *
 * @param {object} training
 * @returns JSX.Element
 */
function ChildRowTrainingDetails({ training }) {
  return (
    <div class="detailsContent">
      <h4>Training Details</h4>
      <div>
        <InfoTable data={training} rowInfos={trainingInfoFields} />
      </div>
    </div>
  );
}

/**
 *
 * @param {Dict} training
 * @returns Dict
 */
function configureButtonVisibility({ training }) {
  const permissions = localStorage.permissions || [];

  // Dates to evaluate until when a training can be edited or canceled
  const today = new Date();
  const maxEditDate = new Date(training.start_date);
  maxEditDate.setDate(maxEditDate.getDate() - 5);
  const maxCancelDate = new Date(training.end_date);
  const maxDeleteDate = new Date(training.end_date);
  maxDeleteDate.setDate(maxDeleteDate.getDate() + 5);

  // Defines if the Edit Training button should be visible or not
  const showEditButton =
    (permissions.includes('manage_trainings') || permissions.includes('manage_all_trainings')) &&
    training.status !== 'CANCELED' &&
    maxEditDate > today;

  // Defines if the Cancel Training button should be visible or not
  const showCancelButton =
    (permissions.includes('manage_trainings') || permissions.includes('manage_all_trainings')) &&
    training.status !== 'CANCELED' &&
    maxCancelDate > today;

  // Defines if the Delete Training button should be visible or not
  const showDeleteButton =
    (permissions.includes('manage_all_trainings') && training.status === 'CANCELED') ||
    (permissions.includes('manage_all_trainings') && today >= maxDeleteDate);

  return {
    showEditButton: showEditButton,
    showCancelButton: showCancelButton,
    showDeleteButton: showDeleteButton,
  };
}

/**
 *
 * @param {object} training
 * @returns JSX.Element
 */
function ChildRowTrainingDetailsButtons({ training }) {
  // Configure the visibility of the buttons
  const { showEditButton, showCancelButton, showDeleteButton } = configureButtonVisibility({
    training: training,
  });

  // Define the edit training button and add the onclick handler
  const editButton = (
    <button type="button" class="btn-sm space" title="Edit Training Details">
      <i class="fas fa-pencil-alt"></i> Edit Training
    </button>
  );
  $(editButton).on('click', () => editTraining(training));

  // Define the cancel button and add the onclick handler
  const cancelButton = (
    <button type="button" class="btn-sm space" title="Cancel Training">
      <i class="fas fa-ban"></i> Cancel Training
    </button>
  );
  $(cancelButton).on('click', () => cancelTraining(training));

  // Define the delete button and add the onclick handler
  const deleteButton = (
    <button type="button" class="btn-sm space btn-danger" title="Delete Training">
      <i class="fas fa-trash-alt"></i> Delete Training
    </button>
  );
  $(deleteButton).on('click', () => deleteTraining(training));

  if (showEditButton || showCancelButton || showDeleteButton) {
    // Return all buttons
    return (
      <div class="detailsContent">
        <div class="btn-group detail-actions-btn-group">
          {showEditButton ? editButton : undefined}
          {showCancelButton ? cancelButton : undefined}
          {showDeleteButton ? deleteButton : undefined}
        </div>
      </div>
    );
  }
}

/**
 *
 * @param {*} td
 * @param {*} cellData
 * @param {*} rowData
 * @param {*} row
 * @param {*} col
 */
function addTrainingTableActions(td, _cellData, rowData, _row, _col) {
  // Configure the visibility of the buttons
  const { showEditButton, showCancelButton, showDeleteButton } = configureButtonVisibility({
    training: rowData,
  });

  // Define a div which contains all the action buttons
  const btnGroup = <div class="table-action-button-group" />;
  td.appendChild(btnGroup);

  if (showEditButton) {
    addContentButtonWithTooltip(btnGroup, 'Edit Training', 'fas fa-pencil-alt', editTraining, rowData, false);
  }

  if (showCancelButton) {
    addContentButtonWithTooltip(btnGroup, 'Cancel Training', 'fas fa-ban', cancelTraining, rowData, false);
  }

  if (showDeleteButton) {
    addContentButtonWithTooltip(btnGroup, 'Delete Training', 'fas fa-trash-alt', deleteTraining, rowData, false);
  }
}

/**
 *
 * @param {Dict} trainingData
 */
function editTraining(trainingData) {
  // Create a random id to identify all the objects in this popup
  const id = generateRandom();

  // Create the actual form with all the dropdowns and inputs
  const form = (
    <form class="needs-validation" novalidate>
      <TrainingPlanFormBody
        id={id}
        type={trainingData.type}
        trainers={trainingData.trainers}
        startDate={trainingData.start_date}
        endDate={trainingData.end_date}
        accountsRequested={trainingData.accounts_requested}
        notes={trainingData.notes}
      />
    </form>
  );

  // Create the cancel button to close the modal without any action
  const cancelButton = (
    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
      Cancel
    </button>
  );

  // Create the update button to trigger the update process of the training
  const updateButton = (
    <button type="submit" class="btn btn-success">
      Update Training
    </button>
  );

  // Add the click handler to the update button, incl. a form validation
  $(updateButton).on('click', event => {
    if (!form.checkValidity()) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      updateTraining({ trainingData: trainingData, id: id });
    }
    form.classList.add('was-validated');
  });

  // Create the modal incl. header, body and footer
  const modal = (
    <div
      class="modal fade"
      id={'modal-' + id}
      data-bs-backdrop="static"
      data-bs-keyboard="true"
      tabindex="-1"
      aria-labelledby="trainingDataModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="trainingDataModalLabel">
              Edit Training
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">{form}</div>
          <div class="modal-footer">
            {cancelButton}
            {updateButton}
          </div>
        </div>
      </div>
    </div>
  );
  const bsModal = new Modal(modal);

  // Update some of the numbers once the modal is shown. Only after the modal is shown
  $(modal).on('shown.bs.modal', function () {
    setTrainingAccountCount({
      id: id,
      setValue: false,
      startDate: trainingData.start_date,
      endDate: trainingData.end_date,
      trainingId: trainingData.training_id,
    });
  });

  // Destroy the modal once it's hidden. Nobody needs you anymore!
  $(modal).on('hidden.bs.modal', function () {
    bsModal.dispose();
    $(modal).remove();
  });

  // show the modal
  bsModal.show();
}

function updateTraining({ trainingData, id }) {
  const updates = collectTrainingData(id);
  const payload = {
    training_data: trainingData,
    updates: updates,
  };

  apiAxios
    .patch('/trainings/' + trainingData.training_id, payload, {
      headers: { 'Content-Type': 'application/json' },
    })
    .then(response => {
      addToast('Training Updated', response.data.message, 6000);
      loadTrainingsData('table-trainings', undefined, true);
    })
    .catch(patchError => {
      showError(patchError);
    })
    .finally(() => {
      const modal = $('#modal-' + id);
      const bootstrapModal = Modal.getInstance(modal[0]);
      bootstrapModal.dispose();
      modal.remove();
    });
}

/**
 *
 * @param {Dict} trainingData
 */
function cancelTraining(trainingData) {
  const swalContent = (
    <div>
      <p>
        Do you want to cancel <strong>{trainingData.type}</strong> training{' '}
        <strong class="text-nowrap">{trainingData.training_id}</strong>
        <br /> between <strong>{trainingData.start_date}</strong> and <strong>{trainingData.end_date}</strong>?
      </p>
    </div>
  );

  swal({
    title: 'Cancel Training',
    content: swalContent,
    buttons: {
      cancel: {
        text: 'No',
        value: null,
        visible: true,
      },
      okay: {
        text: 'Yes',
        value: true,
        visible: true,
      },
    },
  }).then(swalResponse => {
    if (swalResponse) {
      const payload = {
        training_data: trainingData,
        updates: {
          status: 'CANCELED',
        },
      };

      apiAxios
        .patch('/trainings/' + trainingData.training_id, payload, {
          headers: { 'Content-Type': 'application/json' },
        })
        .then(response => {
          addToast('Training Deleted', response.data.message, 6000);
          loadTrainingsData('table-trainings', undefined, true);
        })
        .catch(patchError => {
          showError(patchError);
        });
    }
  });
}

// /**
//  *
//  * @param {Dict} trainingData
//  */
// function assignTrainingAccount(trainingData) {
//   const orderPayload = {
//     action: 'training-assign-accounts',
//     training_id: trainingData.training_id,
//     description: '(Re-)Assign Training Accounts for Training ' + trainingData.training_id,
//   };
//   const swalContent = (
//     <div>
//       <p>
//         Do you want to assign training accounts to training{' '}
//         <strong class="text-nowrap">{trainingData.training_id}</strong>?
//       </p>
//     </div>
//   );

//   swal({
//     title: '(Re-)Assign Training Accounts?',
//     content: swalContent,
//     dangerMode: true,
//     buttons: {
//       cancel: {
//         text: 'Cancel',
//         value: null,
//         visible: true,
//       },
//       nuke: {
//         text: 'Create Order',
//         value: true,
//         visible: true,
//         className: 'swal-button swal-button--confirm swal-button--danger',
//       },
//     },
//   }).then(swalResponse => {
//     if (swalResponse) {
//       baseApiAxios
//         .createOrder(orderPayload)
//         .then(orderResponse => {
//           addToast('Create Order', orderResponse.message, 6000);
//         })
//         .catch(orderError => {
//           showError(orderError);
//         });
//     }
//   });
// }

/**
 *
 * @param {Dict} trainingData
 */
function deleteTraining(trainingData) {
  const swalContent = (
    <div>
      <p>
        Do you want to delete <strong>{trainingData.type}</strong> training{' '}
        <strong class="text-nowrap">{trainingData.training_id}</strong> from the DB?
      </p>
    </div>
  );

  swal({
    title: 'Delete Training',
    content: swalContent,
    icon: 'warning',
    buttons: {
      cancel: {
        text: 'Cancel',
        value: null,
        visible: true,
      },
      okay: {
        text: 'Delete',
        value: true,
        visible: true,
        className: 'swal-button swal-button--confirm swal-button--danger',
      },
    },
    dangerMode: true,
  }).then(swalResponse => {
    if (swalResponse) {
      apiAxios
        .delete('/trainings/' + trainingData.training_id)
        .then(response => {
          addToast('Training Deleted', response.data.message, 6000);
          loadTrainingsData('table-trainings', undefined, true);
        })
        .catch(patchError => {
          showError(patchError);
        });
    }
  });
}

function checkTrainingStatus() {
  addSpinner();
  apiAxios
    .get('/trainings/trigger-updates')
    .then(response => {
      console.log(response);
    })
    .then(() => {
      loadTrainingsData('table-trainings', undefined, true);
    })
    .finally(() => {
      removeSpinners();
    });
}
