import { Modal } from 'bootstrap';
import { COGNITO } from './auth';
import { checkValueAgainstList } from './main';
import { quick_links } from './navigation';
import { removeSpinners } from './sidebar';

// check if the user is responsible for a selected account and return true/false
export function checkResponsibility(account, user) {
  if (!user) {
    user = COGNITO.user.email;
  }

  // Some times COGNITO.user.email seems to be not set when this is called from a DataTable render function
  if (!user) {
    return false;
  }

  const email = user.toLowerCase();
  const name = user.split('@')[0].toLowerCase();
  const domain = user.split('@')[1].toLowerCase();

  const ops_responsibles = account.cost_data?.ops_responsibles
    ? account.cost_data.ops_responsibles.map(responsible => responsible.user_id.toLowerCase())
    : [];

  const responsibles = Array.from(
    new Set([
      String(account.primary_responsible).toLowerCase(),
      String(account.it_responsible).toLowerCase(),
      String(account.sec_responsible).toLowerCase(),
      ...ops_responsibles,
    ]),
  );

  if (checkValueAgainstList(email, responsibles)) {
    return true;
  } else {
    const domains = {
      bmw: ['bmwgroup.com', 'bmw.de', 'bmw.com', 'bmw.in', 'bmw.co.jp'],
      partner: ['partner.bmwgroup.com', 'partner.bmw.de', 'partner.bmw.com', 'partner.bmw.co.jp'],
    };

    const resp_dict = [];
    for (const responsible_email of responsibles) {
      let splitted = responsible_email.split('@');
      resp_dict.push({
        name: splitted[0],
        domain: splitted[1],
      });
    }

    for (let key in domains) {
      if (checkValueAgainstList(domain, domains[key])) {
        for (const resp_obj of resp_dict) {
          if (name === resp_obj.name) {
            if (checkValueAgainstList(resp_obj.domain, domains[key])) {
              return true;
            }
          }
        }
      }
    }

    return false;
  }
}

export function getPermittedPageHashes() {
  const userPermissions = localStorage.permissions.split(',');
  let permittedHashes = [];

  const sidebarItems = document.querySelectorAll('.sidebar-item');
  sidebarItems.forEach(item => {
    const itemPermissions =
      item.id in process.env.PORTAL_CONFIG.menuPermissions ? process.env.PORTAL_CONFIG.menuPermissions[item.id] : [];
    const anchorElement = item.querySelector('a');
    if (anchorElement && anchorElement.dataset.hash) {
      if (!(itemPermissions?.length > 0) || itemPermissions.some(r => userPermissions.includes(r)))
        permittedHashes.push(anchorElement.dataset.hash);
    }
  });

  return permittedHashes;
}

export function showQuickActionsConfigModal() {
  removeSpinners();
  const modal_element = document.getElementById('sharedModal');
  modal_element.classList.add('quick-links-configuration-modal');
  const modal_dialog = document.getElementById('sharedModalDialog');
  if (modal_dialog) modal_dialog.setAttribute('data-type', 'custom-modal');

  const title = document.getElementById('sharedModalTitle');
  if (title) title.innerHTML = '<strong>Quick Links Configuration</strong>';

  const selectField = document.createElement('div');
  selectField.id = 'config-quick-links-dropdown';
  selectField.className = 'dropdown';
  selectField.innerHTML = `<a class="btn btn-link dropdown-toggle w-100 text-align-left" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
  Select Pages
</a>
<ul class="dropdown-menu"></ul>`;

  const dropdownMenu = selectField.querySelector('.dropdown-menu');

  const quickLinksContainer = document.createElement('div');
  quickLinksContainer.id = 'config-quick-links-container';
  quickLinksContainer.className = 'config-quick-links-content';

  const closeIcon = document.createElement('i');
  closeIcon.className = 'fas fa-times remove-item';
  const appendToContainer = (link, label, icon) => {
    const closeIconCopy = closeIcon.cloneNode();
    closeIconCopy.addEventListener('click', removeItem);
    $(quickLinksContainer).append(
      $(
        <div class="config-quick-links-item" data-link={link} data-icon={icon}>
          <i class={`fas ${icon} page-icon`}></i>
          {label}
        </div>,
      ).append(closeIconCopy),
    );
  };

  const addItem = evt => {
    const option = evt.target;
    appendToContainer(option.dataset.link, option.innerText, option.dataset.icon);
    option.parentNode.removeChild(option);
    dropdownMenu.value = '';
  };

  const getDropdownOption = (link, label, icon) => {
    const dropdownOption = $(
      <li>
        <a class="dropdown-item" data-link={link} data-icon={icon}>
          {label}
        </a>
      </li>,
    );
    dropdownOption.on('click', addItem);
    return dropdownOption;
  };

  const removeItem = evt => {
    evt.stopPropagation();
    const linkItem = evt.target.parentElement;
    $(dropdownMenu).append(getDropdownOption(linkItem.dataset.link, linkItem.innerText, linkItem.dataset.icon));
    linkItem.parentNode.removeChild(linkItem);
  };

  const permittedHashes = getPermittedPageHashes();
  const cognitoUser = COGNITO.userpool.getCurrentUser();
  const username = cognitoUser.getUsername();
  const savedValue = localStorage.getItem(`quick_links_${btoa(username)}`);

  const savedLinks = ((savedValue && JSON.parse(savedValue)) || [])
    .filter(linkItem => permittedHashes.includes(linkItem.link))
    .map(linkItem => {
      appendToContainer(linkItem.link, linkItem.label, linkItem.icon);
      return linkItem.link;
    });

  const fieldOptions = quick_links?.filter(
    linkItem => permittedHashes.includes(linkItem.link) && !savedLinks.includes(linkItem.link),
  );

  $(dropdownMenu).append(fieldOptions.map(linkItem => getDropdownOption(linkItem.link, linkItem.label, linkItem.icon)));

  dropdownMenu.addEventListener('change', evt => {
    const option = evt.target;
    appendToContainer(option.dataset.link, option.value, option.dataset.icon);
    option.parentNode.removeChild(option);
    dropdownMenu.value = '';
  });

  const content = document.getElementById('sharedModalContent');
  if (content) {
    content.innerHTML = '';
    $(content).append(
      $('<p class="modal-subheadline">Select your Dashboard Quick Links</p>'),
      $(quickLinksContainer),
      $(`<div class="config-quick-links-content"></div>`).append(selectField),
    );
  }
  const footer = document.getElementById('sharedModalFooter');
  if (footer) {
    footer.innerHTML = '';
    const save_button = $('<button type="button" class="btn btn-success" data-bs-dismiss="modal">Save</button>');
    save_button.on('click', () => {
      let linkItems = Array.from(quickLinksContainer.querySelectorAll('.config-quick-links-item')).map(linkItem => {
        return { icon: linkItem.dataset.icon, label: linkItem.innerText, link: linkItem.dataset.link };
      });
      localStorage.setItem(`quick_links_${btoa(username)}`, JSON.stringify(linkItems));
      window.location.hash = '#dashboard';
      window.dispatchEvent(new Event('hashchange'));
    });
    $(footer).append(
      $('<button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">Cancel</button>'),
      save_button,
    );
  }

  Modal.getInstance(modal_element).show();
}
