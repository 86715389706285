import swal from 'sweetalert';
import { baseApiAxios } from './api';
import { displayError, displaySuccessPopup } from './main';
import { addButtonSpinner, removeSpinners } from './sidebar';

const peeringKeys = [
  'vpc_peering_accepter_vpc_cidr',
  'vpc_peering_accepter_vpc_id',
  'vpc_peering_accepter_vpc_owner_id',
  'vpc_peering_accepter_vpc_region',
  'vpc_peering_connection_id',
  'vpc_peering_requester_vpc_cidr',
  'vpc_peering_requester_vpc_id',
  'vpc_peering_requester_vpc_owner_id',
  'vpc_peering_requester_vpc_region',
  'vpc_peering_status',
];

function fillPeerings(result) {
  const peerings = result['vpc_peerings'];
  const sel = document.getElementById('peering');
  sel.options.length = 0;
  peerings.forEach(function (peering) {
    const opt = document.createElement('option');
    let mainText = peering.vpc_peering_name;
    let subText = peering.vpc_peering_connection_id;
    opt.value = peering.vpc_peering_name;
    opt.innerText = mainText;
    opt.setAttribute('data-subtext', subText);
    peeringKeys.forEach(function (peering_item) {
      opt.setAttribute(peering_item, peering[peering_item]);
      sel.appendChild(opt);
    });
  });
  loadPeeringInfo();

  $('.selectpicker').selectpicker('refresh');
  $('.selectpicker').not('.no-deselect').selectpicker('deselectAll');
}

export function loadPeerings() {
  let accountId = document.getElementById('aws-account-id')?.value?.split(';')[0];
  let region = document.getElementById('region')?.value;

  if (accountId && region) {
    const payload = {
      action: 'list-vpc-peerings',
      region: region,
      account_id: accountId,
    };
    addButtonSpinner();

    baseApiAxios
      .getNetworkItems(payload)
      .then(fillPeerings)
      .catch(err => displayError(`<strong>Error:</strong> ${err.message}`))
      .finally(() => removeSpinners());
  }
}

function resultDeletePeeringFunction(response) {
  removeSpinners();
  displaySuccessPopup(response.message, 'Create Order');
  const form = document.getElementById('destroy-peering-form');
  if (form) {
    form.reset();
    $('.selectpicker').selectpicker('refresh');
    $('.selectpicker').not('.no-deselect').selectpicker('deselectAll');
  }
}

export function loadPeeringInfo() {
  const dropDown = document.getElementById('peering');
  const peeringInfo = dropDown.options[dropDown.selectedIndex];
  peeringKeys.forEach(function (peering_item) {
    const itemTextbox = document.getElementById(peering_item);
    if (dropDown.length > 0) {
      const submit_button = document.getElementById('submitButton');
      if (submit_button) submit_button.disabled = false;
      itemTextbox.value = peeringInfo.getAttribute(peering_item);
    } else {
      itemTextbox.value = '';
    }
  });
}

export function deleteVPCPeeringConfirmation() {
  const dropDown = document.getElementById('peering');
  const peeringInfo = dropDown.options[dropDown.selectedIndex];
  const jobDescription = `${$('#jobTypeDescription')[0].value} ${peeringInfo.getAttribute(
    'vpc_peering_connection_id',
  )}`;

  const body = {
    action: 'create-order',
    payload: {
      action: $('#jobType')[0].value,
      description: jobDescription,
      account_id: $('#aws-account-id')[0].value.split(';')[0],
      vpc_peering_accepter_vpc_id: peeringInfo.getAttribute('vpc_peering_accepter_vpc_id'),
      vpc_peering_accepter_vpc_owner_id: peeringInfo.getAttribute('vpc_peering_accepter_vpc_owner_id'),
      vpc_peering_accepter_vpc_region: peeringInfo.getAttribute('vpc_peering_accepter_vpc_region'),
      vpc_peering_connection_id: peeringInfo.getAttribute('vpc_peering_connection_id'),
      vpc_peering_requester_vpc_id: peeringInfo.getAttribute('vpc_peering_requester_vpc_id'),
      vpc_peering_requester_vpc_owner_id: peeringInfo.getAttribute('vpc_peering_requester_vpc_owner_id'),
      vpc_peering_requester_vpc_region: peeringInfo.getAttribute('vpc_peering_requester_vpc_region'),
    },
  };

  deleteVpcPeering(body);
}

export function deleteVpcPeering(body) {
  const payload = typeof body === 'string' ? JSON.parse(body)?.payload : body.payload;

  swal({
    title: 'Are you sure?',
    text: 'Are you sure you want to delete VPC Peering Connection ' + payload.vpc_peering_connection_id + '?',
    icon: 'warning',
    buttons: {
      cancel: {
        text: 'Cancel',
        value: null,
        visible: true,
      },
      reset: {
        text: 'Delete',
        value: true,
        className: 'swal-button swal-button--confirm swal-button--danger',
      },
    },
    dangerMode: true,
  }).then(function (response) {
    if (response) {
      baseApiAxios
        .createOrder(payload)
        .then(resultDeletePeeringFunction)
        .catch(err => displayError(`<strong>Error:</strong> ${err.message}`));
    }
  });
}
