import {
  addCopyButton,
  addToast,
  refreshRow,
  showErrorFromApiOperation,
  sort,
  removeDisabledOptions,
  encodeEntities,
  addRowLoadingAnimation,
  displaySuccessPopup,
  showAndProcessRegionRestrictWarning,
  validateAcknowledgeDeniedRegion,
} from './main';
import { getSearchParamsUrl, setSearchParamsUrl } from './search_params';
import { addButtonSpinner, addSpinner, removeSpinners } from './sidebar';
import { NetworksCreateSubnets } from '../../_networks_create_subnets';
import { NetworksCreateSubnet } from '../../_networks_create_subnet';
import { showError } from './api';
import { QuotaException } from './quotas';
import swal from 'sweetalert';
import { CONF } from './environment';
import { initDataTable } from './datatable';

/**
 * @class
 * @param {BaseApiAxios} baseApiAxios
 */
export class VPCAPI {
  constructor(baseApiAxios) {
    this.axiosInstance = baseApiAxios;
  }

  checkOvelappingCIDRs = async CIDRs => {
    return await this.axiosInstance.get('vpcs/check-overlapping-cidrs', this.axiosInstance.invalidateCacheHeaders, {
      cidrs: CIDRs.join(','),
    });
  };

  isCIDRContained = async (subnetCIDR, CIDRs) => {
    return await this.axiosInstance.get('vpcs/check-subnet-cidr-contained', this.axiosInstance.invalidateCacheHeaders, {
      subnet_cidr: subnetCIDR,
      cidrs: CIDRs.join(','),
    });
  };

  checkCIDRValidity = async (
    accountId,
    networkType,
    networkSize,
    networkRegion,
    subnetsOptions,
    networkAddress,
    secondaryCIDRs,
  ) => {
    const checkCIDRPayload = {
      account_id: accountId,
      network_type: networkType,
      network_size: networkSize,
      region: networkRegion,
      subnets: subnetsOptions,
    };
    // Fix, can be improved
    switch (networkType) {
      case 'public':
        checkCIDRPayload.cidr = `${networkAddress}/${networkSize}`;
        break;
      case 'private':
        if (networkAddress.startsWith('10.')) {
          checkCIDRPayload.cidr = `${networkAddress}/${networkSize}`;
        }
        // this is actually done in the backend
        // else {`255.255.0.0/${networkSize}`}
        break;
      default:
        console.error(`Cannot enter in this case ${networkType} not allowed`);
        break;
    }
    if (secondaryCIDRs) checkCIDRPayload.secondary_cidrs = secondaryCIDRs;
    return await this.axiosInstance.post('vpcs/check-cidr-validity', checkCIDRPayload);
  };

  getAccountQuotas = async (accountId, regionName) => {
    const params = {
      region_name: regionName,
      action: 'create-vpc',
    };

    return await this.axiosInstance.get(`/accounts/${accountId}/quotas`, null, params).then(data => {
      return data.quotas;
    });
  };

  getAccountResources = async (accountId, regionName) => {
    const resources = ['vpcs', 'vpn_gateways', 'internet_gateways', 'vpc_gateway_endpoints', 'elastic_ips'];
    const params = {
      region_name: regionName,
      resources: resources.join(),
      count: true,
    };
    return await this.axiosInstance.get(`/accounts/${accountId}/vpcs/resources`, null, params).then(data => {
      return data.resources;
    });
  };

  getAvailabilityZones = async (accountId, regionNameNew, regionNameOld) => {
    if (regionNameNew !== regionNameOld) {
      return await this.axiosInstance.get(`/accounts/${accountId}/vpcs/availability-zones`, null, {
        region_name: regionNameNew,
      });
    }
  };

  getElasticIPs = async (accountId, regionName) => {
    const elasticIPs = await this.axiosInstance.get(
      'accounts/' + accountId + '/vpcs/elastic-ips',
      this.axiosInstance.invalidateCacheHeaders,
      {
        region_name: regionName,
      },
    );
    if ($.isEmptyObject(elasticIPs)) return [];
    else return elasticIPs;
  };

  // TODO: remove this function as it may be deprecated
  // moved to getRegionsMetadata()
  getRegions = async type => {
    /** @type {{regions: string[]}} */
    const data = await this.axiosInstance.get('regions', null, { type: type || 'public' });
    return data.regions;
  };

  getRegionsMetadata = async type => {
    /** @type {{regions: string[]}} */
    const data = await this.axiosInstance.get('regions-metadata', null, { type: type || 'public' });
    return data.regions_metadata;
  };

  getVPCExtended = async (accountId, vpcId) => {
    return await this.axiosInstance.getVPC(vpcId, accountId, true);
  };

  createVPC = async vpcCreatePayload => {
    const orderPayload = {
      ...vpcCreatePayload,
      ...{
        action: 'create-vpc',
        description: 'Create new ' + vpcCreatePayload.network_type + ' VPC for account ' + vpcCreatePayload.account_id,
      },
    };
    return await this.axiosInstance.createOrder(orderPayload);
  };

  updateVPC = async vpcUpdatePayload => {
    const orderPayload = {
      ...vpcUpdatePayload,
      ...{
        action: 'update-vpc',
        description:
          'Update ' +
          vpcUpdatePayload.network_type +
          ' VPC ' +
          vpcUpdatePayload.vpc_id +
          ' for account ' +
          vpcUpdatePayload.account_id,
      },
    };
    return await this.axiosInstance.createOrder(orderPayload);
  };

  settings = async () => {
    return await this.axiosInstance.getSettingsByKey(['networking']);
  };
}

class CidrException {
  constructor(message, regex, cidr) {
    this.message = message;
    this.name = 'CidrException';
    this.regex = regex;
    this.cidr = cidr;
    this.code = 400;
  }
}

/**
 * @typedef {'public' | 'private' | 'intranet'} NetworkType
 */

/**
 * @typedef {object} NatGatewayAddress
 * @property {string} PublicIp
 * @property {string} PrivateIp
 * @property {string} AllocationId
 * @property {string} NetworkInterfaceId
 */

/**
 * @typedef {object} NatGateway
 * @property {NatGatewayAddress[]} NatGatewayAddresses
 */

/**
 * @typedef {object} Subnet
 * @property {string} subnet_id
 * @property {string} name
 * @property {string} cidr
 * @property {string} availability_zone_id
 * @property {NatGateway[]} nat_gateways
 */

/**
 * @typedef {object} VPCData
 * @property {string} vpc_id
 * @property {string} name
 * @property {string} aws_account_id
 * @property {string} aws_region
 * @property {string} status
 * @property {NetworkType} network_type
 * @property {{[p: NetworkType]: Subnet}} subnets
 * @property {string} cidr
 * @property {string[]?} secondary_cidrs
 */

/**
 * @constructor
 * @param {VPCAPI} vpcAPI {VPCAPI}
 */
export function VPCFormOperations(vpcAPI) {
  let accountId,
    accountType,
    accountStage,
    networkType,
    networkRegion,
    networkSize,
    networkAddressPlaceholder,
    networkAddressRegex,
    CIDR,
    secondaryCIDRs = [],
    // dummy index to keep track of the div id used in aria-labelled
    divFeedbackIndexCounter = 0,
    subnetsNumbers = {},
    subnetsOptions = {
      public: [],
      private: [],
      intranet: [],
    },
    availabilityZones = [],
    elasticIPs = [],
    //edit fields
    vpcId,
    /** @type VPCData */
    appliedQuotas = {},
    deployedResources = {},
    accountIdDropdown,
    accountIdDropdownRefresh,
    existingVPCData = {},
    vpcIdInput,
    networkTypeDropdown,
    networkRegionDropdown,
    networkNameInput,
    networkAddressInput,
    networkSizeDropdown,
    subnetsDropdowns,
    addSecondaryCIDRButton,
    secondaryCIDRDiv,
    subnetsNumberDropdown,
    dispaySubnetsOptionsButton,
    subnetsContainerDiv,
    networkCreateForm,
    networkFormError,
    networkFormSuccess,
    submitButton,
    initialized = false;

  const subnetIps28 = 16,
    minSubnetSize = 28,
    minSubnetSizeMapping = {
      public: 16,
      private: 25,
    },
    awsReservedSubnetIPs = 5,
    privateDefaultSize = 27,
    publicDefaultSize = 24,
    addressValuePlaceholder = '10.19.0.0',
    publicAddressRegex = /((\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])\.){3}(\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])/,
    privateAddressRegex = /10\.19\.((\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])\.)(\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])/,
    privateNASubnetNumberTitle = 'Add Secondary CIDR to enable the options',
    subnetTypesInfo = {
      public: {
        availableFor: ['public'],
        defaultOptions: {
          name: 'public-',
          cidr: 'auto',
          'availability-zone': 'auto',
          'nat-gateway': 'false',
          'allocation-id': null,
          'elastic-ip': null,
          'edit-mode': false,
        },
        title: 'Public Subnets',
        description:
          'Public Subnets have a direct route to an Internet Gateway. All resources must have a public IP address in order to have an Internet connection',
      },
      private: {
        availableFor: ['public', 'private'],
        defaultOptions: {
          name: 'private-',
          cidr: 'auto',
          'availability-zone': 'auto',
          'nat-gateway': null,
          'allocation-id': null,
          'elastic-ip': null,
          'edit-mode': false,
        },
        title: 'Private Subnets',
        description: 'Private Subnets do not have a direct connection to the Internet or to the BMW on premise network',
      },
      intranet: {
        availableFor: ['private'],
        defaultOptions: {
          name: 'intranet-',
          cidr: 'auto',
          'availability-zone': 'auto',
          'nat-gateway': 'false',
          'allocation-id': null,
          'elastic-ip': null,
          'edit-mode': false,
        },
        title: 'Intranet Subnets',
        description:
          'Intranet Subnets do not have a direct connection to the Internet but have connection to the BMW on premise network',
      },
    };

  /**
   * Initialize the form to create a VPC, this should be the only public function to be used
   */
  this.initForm = async () => {
    console.debug('Init create/update VPC form');
    initialized = true;
    vpcId = getSearchParamsUrl('vpc_id');
    addSpinner();

    // init jquery items
    accountIdDropdown = $('#aws-account-id');
    accountIdDropdownRefresh = $('#refresh-accounts');
    vpcIdInput = $('#vpc-id');
    networkTypeDropdown = $('#network-type');
    networkRegionDropdown = $('#region');
    networkNameInput = $('#network-name');
    networkAddressInput = $('#network-address');
    networkSizeDropdown = $('#network-size');
    subnetsDropdowns = {
      public: $('#public-subnets-number'),
      private: $('#private-subnets-number'),
      intranet: $('#intranet-subnets-number'),
    };
    addSecondaryCIDRButton = $('#add-secondary-cidr');
    secondaryCIDRDiv = $('#secondary-network-cidr');
    subnetsNumberDropdown = $('[id$="-subnets-number"]');
    dispaySubnetsOptionsButton = $('#display-subnets-options');
    subnetsContainerDiv = $('#subnets-container');
    networkCreateForm = $('#network-create-form');
    networkFormError = $('#form-error');
    networkFormSuccess = $('#form-success');
    submitButton = $('#submitButton');

    // register the events to be triggered at the change of Network Type dropdown
    networkTypeDropdown.on('change', onNetworkTypeChange);

    // assign the value to the local region variable when we change region value
    networkRegionDropdown.on('change', onNetworkRegionChange);

    // register event on change of the IP address on the primary CIDR
    networkAddressInput.on('change', onNetworkAddressInputChange);

    // register event on the change of the network size on the primary CIDR
    networkSizeDropdown.on('change', onNetworkSizeDropdownChange);

    // add secondary CIDR event to generate the needed HTML
    addSecondaryCIDRButton.on('click', addSecondaryCIDR);

    // register the events on all the subnets dropdown, assign the value to the local variable
    subnetsNumberDropdown.on('change', event => onSubnetsNumberDropdownChange(event));

    // button to dispay all the possible options for every subnet
    dispaySubnetsOptionsButton.on('click', () => displaySubnetsOptions(false));

    if (!vpcId) {
      updateNetworkTypeDropdown();
    }
    if (addSecondaryCIDRButton.attr('disabled')) {
      addSecondaryCIDRButton.removeAttr('disabled');
      addSecondaryCIDRButton.parent().removeAttr('style');
    }

    // register the form submission event
    formSubmission(vpcId ? vpcAPI.updateVPC : vpcAPI.createVPC);

    removeSpinners();
  };

  /**
   *
   * @param {JSX.Element} selectedOption
   * @param {string} selectedAccountId
   * @param {string} selectedAccountType
   * @param {string} selectedAccountStage
   */
  this.onAccountChange = async (
    _eventTypeId,
    _eventCategory,
    _selectedOption,
    selectedAccountId,
    selectedAccountType,
    selectedAccountStage,
  ) => {
    addSpinner();
    const loading_network_types = document.querySelector('.loading-network-types');
    if (loading_network_types) loading_network_types.classList.add('loading-animation');

    accountId = selectedAccountId;
    accountType = selectedAccountType;
    accountStage = selectedAccountStage;

    // init the form components if they are not yet initialized
    if (!initialized) {
      await this.initForm();
    }

    // this is the update VPC form
    if (accountId && vpcId) {
      await insertFormWithVpcData();
    }

    updateNetworkTypeDropdown();
  };

  const showQuotaError = async (quota, currentNumber, newResources) => {
    let updateText;
    let newResourcesText;
    submitButton.attr('disabled', true);
    submitButton.parent().css('cursor', 'not-allowed');

    if (newResources) {
      newResourcesText = (
        <>
          With your current request, we need to deploy <strong>{newResources.toString()}</strong> additional{' '}
          {quota.QuotaName}.
        </>
      );
    }

    const errorText = (
      <p>
        You have deployed already{' '}
        <strong>
          {currentNumber.toString()} out of max {quota.Value.toString()}
        </strong>{' '}
        {quota.QuotaName}. {newResourcesText}
      </p>
    );

    if (quota.Adjustable) {
      const quotaLink =
        'https://' +
        networkRegion +
        '.console.aws.amazon.com/servicequotas/home/services/' +
        quota.ServiceCode +
        '/quotas/' +
        quota.QuotaCode;

      updateText = (
        <p>
          Please increase the limit before you can create the order:{' '}
          <a
            href={quotaLink}
            target="_blank"
            title="Link to AWS. Please login to AWS first before you can follow this link.">
            AWS Console <i class="fas fa-external-link-alt size-1" />
          </a>
        </p>
      );
    } else {
      updateText = <p>This quota is not adjustable. You can't increase the max. number of {quota.QuotaName}.</p>;
    }

    const swalContent = (
      <div>
        {errorText}
        {updateText}
      </div>
    );

    return swal({
      title: 'Quota Error',
      content: swalContent,
      icon: 'error',
    }).then(() => {
      throw new QuotaException(quota, currentNumber);
    });
  };

  const onNetworkAddressInputChange = () => {
    if (networkType === 'public') {
      CIDR = networkAddressInput.val() + '/' + networkSizeDropdown.val();
    } else {
      CIDR = null;
    }
  };

  const onNetworkSizeDropdownChange = () => {
    networkSize = networkSizeDropdown.val();
    if (networkType === 'public') CIDR = networkAddressInput.val() + '/' + networkSizeDropdown.val();
    updateSubnetsNumberDropdown();
    subnetsNumberDropdown.trigger('change');
  };

  const onSubnetsNumberDropdownChange = event => {
    const subnetsDropdown = $(event.target);
    subnetsNumbers[subnetsDropdown.data('subnet-type')] = parseInt(subnetsDropdown.val());
    if (
      subnetsContainerDiv.find('ul').length &&
      (areSubnetsOptionsChanged() ||
        subnetsOptions[subnetsDropdown.data('subnet-type')].length !== parseInt(subnetsDropdown.val()))
    ) {
      resetSubnetsOptions();
    }
  };

  const formSubmission = vpcAPIRestCall => {
    $.validator.addMethod(
      'secondarycidr',
      (value, element, regexp) => $(element).attr('readonly') && new RegExp(regexp).test(value),
      // this is not being used since it use the "title" attribute instead
      'Please enter a valid Network Address (IP Address) for each secondary CIDR and "Associate" the CIDR to the VPC (for Private VPC mandatory starts with 10.9.).',
    );
    $.validator.addMethod(
      'subnetcidr',
      (value, element, regexp) => {
        const subnetType = $(element).closest('table').data('subnet-type');
        return (
          $(element).val() === 'Automatically assigned' || subnetType === 'intranet' || new RegExp(regexp).test(value)
        );
      },
      // this is not being used since it use the "title" attribute instead
      'Please insert a valid Subnet CIDR (contained in any Primary or Secondary CIDR)',
    );

    networkCreateForm
      .on('submit', event => {
        event.preventDefault();
        $('[data-secondary-cidr="validation"]').map((_, item) => {
          $(item).rules('add', {
            required: true,
            secondarycidr: networkAddressRegex,
          });
        });
        $('[data-subnet-option="address"]').map((_, item) => {
          $(item).rules('add', {
            required: true,
            subnetcidr: networkAddressRegex,
          });
        });
        //cannot really understand why but otherwise the validator get crazy
        $('#display-subnets-options').rules('remove');
        networkFormError.hide();
        networkFormSuccess.hide();
      })
      .validate({
        errorContainer: '#form-error',
        errorLabelContainer: '#form-error ul',
        wrapper: 'li',
        messages: {
          'aws-account-id': {
            required: 'Please select your target AWS Account ID.',
          },
          'network-type': {
            required: 'Please select the Network Type.',
          },
          'network-name': {
            required: 'Please select enter a Network Name.',
          },
          region: {
            required: 'Please select the target AWS region.',
          },
          'network-address': {
            required: 'Please insert a Network Address',
          },
          'network-size': {
            required: 'Please select a Network Size',
          },
        },
        submitHandler: async () => {
          await submitHandler(vpcAPIRestCall);
        },
        invalidHandler: (_event, validator) => {
          const errors = validator.numberOfInvalids();
          console.error('Errors in VPC validation form:' + errors);
        },
      });
  };

  const submitHandler = async vpcAPIRestCall => {
    console.debug('Execute submit handler');

    const payload = await buildPayload();
    addSpinner();
    addButtonSpinner();

    Promise.all([
      CONF.awsLoginDetails.awsPartition == 'aws' ? checkQuotas(payload) : undefined,
      vpcAPI.checkCIDRValidity(
        accountId,
        networkType,
        networkSize,
        networkRegion,
        payload.subnets,
        networkAddressInput.val(),
        secondaryCIDRs,
      ),
      validateAcknowledgeDeniedRegion(
        'acknowledge-denied-region',
        document.getElementById('selected-denied-region').checked,
      ),
    ])
      .then(() => {
        // acknowledge_denied_region value has to be set after validateAcknowledgeDeniedRegion
        payload['acknowledge_denied_region'] = document.getElementById('acknowledge-denied-region').checked;
        // Create a new order
        return vpcAPIRestCall(payload);
      })
      .then(result => {
        // Reset the form if the order was successfully created
        displaySuccessPopup(result.message, 'Create Order');
        setSearchParamsUrl();
        return this.resetForm();
      })
      .catch(err => {
        if (err.response && err.response.status === 400) {
          const result = err.response.data;
          const formErrorContent = $('<strong>Warning: ' + result.message + '</strong>');
          if (result.errors && result.errors.length) {
            formErrorContent.append($('<ul>').append(result.errors.map(x => $('<li>' + x + '</li>'))));
          }
          networkFormError.empty();
          networkFormError.append(formErrorContent);
          networkFormError.show();
          window.scrollTo(0, 0);
        } else if (err.name === 'QuotaException') {
          console.error(err);
        } else {
          return showErrorFromApiOperation('Error Creating VPC Order')(err);
        }
      })
      .finally(() => {
        removeSpinners();
      });
  };

  const checkQuotas = async payload => {
    // vpc_gateway_endpoints, elastic_ips
    const checks = {
      elastic_ips: 0,
      internet_gateways: 0,
      vpc_gateway_endpoints: 0,
      vpc_peerings: 0,
      vpn_gateways: 0,
    };

    if (payload.network_type === 'public') {
      // For public VPCs we need to verify that there are enough internet gateways available
      ++checks.internet_gateways;
    } else if (payload.network_type === 'private' && !existingVPCData.vpn_gateways) {
      // For private VPCs we need to verify that there are enough VPN gateways available
      ++checks.vpn_gateways;
    }

    const publicSubnets = payload.subnets.public ?? [];
    publicSubnets.forEach(subnet => {
      if (subnet.nat_gateway) {
        ++checks.elastic_ips;
      }
    });

    const existingPublicSubnets =
      existingVPCData && existingVPCData.subnets && existingVPCData.subnets.public
        ? existingVPCData.subnets.public
        : [];
    existingPublicSubnets.forEach(subnet => {
      if (subnet.nat_gateway) {
        --checks.elastic_ips;
      }
    });

    for (const [key, newResources] of Object.entries(checks)) {
      if (newResources && appliedQuotas[key]['Value'] < deployedResources[key] + checks[key]) {
        await showQuotaError(appliedQuotas[key], deployedResources[key], newResources);
      }
    }
  };

  const buildPayload = async () => {
    const vpcPayload = {
      account_id: accountId,
      network_name: networkNameInput.val(),
      network_type: networkType,
    };

    if (secondaryCIDRs.length) {
      vpcPayload['secondary_cidrs'] = secondaryCIDRs;
    }
    if (networkType === 'public' && !vpcId) {
      vpcPayload['cidr'] = CIDR;
    }

    vpcPayload['subnets'] = {};
    for (const [key, value] of Object.entries(subnetTypesInfo)) {
      if (value.availableFor.includes(networkType)) {
        vpcPayload['subnets'][key] = mapSubnetsIntoPayload(key, value.defaultOptions);
      } else {
        delete vpcPayload['subnets'][key];
      }
    }

    // only for edit
    if (vpcId) {
      vpcPayload['vpc_id'] = vpcId;
    } else {
      vpcPayload['region'] = networkRegion;
      vpcPayload['network_size'] = networkSize;
    }

    return vpcPayload;
  };

  const mapSubnetsIntoPayload = (subnetType, defaultOptions) => {
    let vpcPayloadSubnetType = [];
    if (
      parseInt(subnetsNumbers[subnetType]) === subnetsOptions[subnetType].length ||
      parseInt(subnetsNumbers[subnetType]) > subnetsOptions[subnetType].length
    ) {
      vpcPayloadSubnetType = subnetsOptions[subnetType].map(singleSubnetOption => {
        let subnetItem = {};
        if (singleSubnetOption['edit-mode']) {
          subnetItem = {
            // subnet_id: singleSubnetOption.id,
            name: singleSubnetOption.name,
            cidr: singleSubnetOption.cidr,
            availability_zone_id: singleSubnetOption['availability-zone'],
          };
          if (singleSubnetOption['nat-gateway'] && singleSubnetOption['nat-gateway'] === 'true') {
            subnetItem.nat_gateway = true;
          }
          if (
            subnetType !== 'intranet' &&
            singleSubnetOption['allocation-id'] &&
            singleSubnetOption['allocation-id'] !== 'auto' &&
            singleSubnetOption['elastic-ip']
          ) {
            subnetItem.elastic_ip = singleSubnetOption['elastic-ip'];
          }
        } else {
          for (const [subnetKey, subnetValue] of Object.entries(singleSubnetOption))
            if (subnetValue !== defaultOptions[subnetKey]) {
              switch (subnetKey) {
                case 'availability-zone':
                  subnetItem.availability_zone_id = subnetValue;
                  break;
                case 'nat-gateway':
                  if (subnetValue === 'true') {
                    subnetItem.nat_gateway = true;
                  }
                  break;
                case 'allocation-id':
                  console.debug(`allocation-id -> ${subnetValue} is not accepted in the payload`);
                  break;
                case 'elastic-ip':
                  if (
                    subnetType !== 'intranet' &&
                    singleSubnetOption['allocation-id'] &&
                    singleSubnetOption['allocation-id'] !== 'auto' &&
                    singleSubnetOption['elastic-ip']
                  )
                    subnetItem.elastic_ip = subnetValue;
                  break;
                default:
                  subnetItem[subnetKey] = subnetValue;
                  break;
              }
            }
        }

        return subnetItem;
      });
    }
    if (parseInt(subnetsNumbers[subnetType]) > subnetsOptions[subnetType].length) {
      [...Array(parseInt(subnetsNumbers[subnetType]) - subnetsOptions[subnetType].length).keys()].forEach(i => {
        vpcPayloadSubnetType.push({
          name: subnetType + '-' + (i + subnetsOptions[subnetType].length),
        });
      });
    }
    return vpcPayloadSubnetType;
  };

  /**
   * Retrieve the VPC data for a given VPC Id from the REST API and insert the data into the form fields.
   * Disables all the fields that can't be updated or changed anymore.
   */
  const insertFormWithVpcData = async () => {
    console.debug('Render edit form for ' + vpcId);

    // Title and text for the alert popup
    let title, text;

    // Retrieve the data for a given VPC Id from the REST API
    try {
      existingVPCData = await vpcAPI.getVPCExtended(accountId, vpcId);
    } catch (err) {
      if (err.response && err.response.status === 404) {
        title = 'Not Found VPC';
        text = 'The provided VPC ID do not match any existing VPC';
      } else {
        return showErrorFromApiOperation('Error Retrieving VPC')(err);
      }
    }
    // Insert the VPC data and enables/disables the inputs
    if (existingVPCData && existingVPCData.status === 'active') {
      // Set page headers and texts to Update VPC
      document.title = 'AWS | Update VPC';
      $('#mainTitle').text('Update VPC');
      $('#header-title').text('Update Existing VPC');
      $('#card-body-span').text('With this form you can update an existing VPC in your target AWS Account');
      submitButton.val('Update VPC');
      submitButton.text('Update VPC');

      // Disable the Account Id dropdown
      accountIdDropdown.attr('disabled', true);
      accountIdDropdownRefresh.attr('disabled', true);

      // Show and set VPC Id input
      vpcIdInput.val(vpcId).closest('.form-group').show();

      // Set to VPC network type and disable the dropdown
      networkType = existingVPCData.network_type;
      networkTypeDropdown.val(networkType).attr('disabled', true);

      // Set to VPC region, disable the dropdown and load the available Availability Zones
      networkRegion = existingVPCData.aws_region;
      networkRegionDropdown.attr('disabled', true);
      availabilityZones = await vpcAPI.getAvailabilityZones(accountId, networkRegion);

      // Set to VPC name and disable the input
      networkNameInput.val(existingVPCData.name).removeAttr('pattern minlength maxlength title').attr('readonly', true);
      networkAddressPlaceholder = addressValuePlaceholder;
      networkAddressRegex = networkType === 'public' ? publicAddressRegex : privateAddressRegex;

      // Set to VPC CIDR and disable the network input and network size dropdown
      networkAddressInput.removeAttr('pattern title');
      networkAddressInput.val(existingVPCData.cidr.split('/')[0]);
      networkAddressInput.attr('readonly', true);
      networkSizeDropdown.val(existingVPCData.cidr.split('/')[1]);
      networkSizeDropdown.attr('disabled', true).trigger('change');
      networkSizeDropdown.trigger('change');

      // Set values for secondary CIDRs if the VPC has already secondary CIDRs
      if (existingVPCData.secondary_cidrs) {
        secondaryCIDRs = existingVPCData.secondary_cidrs;
        secondaryCIDRs.map(secondaryCIDR => {
          const secondaryCIDRInputGroup = addSecondaryCIDR();
          const secondaryCIDRInputText = secondaryCIDRInputGroup.find('.secondary-cidr-address');
          secondaryCIDRInputText
            .removeAttr('data-secondary-cidr pattern title')
            .val(secondaryCIDR.split('/')[0])
            .attr('readonly', true);
          const secondaryCIDRSizeDropdown = secondaryCIDRInputGroup.find('.secondary-cidr-size');
          secondaryCIDRSizeDropdown.val(secondaryCIDR.split('/')[1]).attr('disabled', true);
          secondaryCIDRInputGroup.find('button[data-action="delete"]').attr('disabled', true);
          secondaryCIDRInputGroup.find('button[data-action="associate"]').attr('disabled', true);
        });

        // There can be a maximum of 4 secondary CIDRs attached to a VPC
        if (secondaryCIDRs.length < 4) {
          addSecondaryCIDRButton.removeAttr('disabled');
          addSecondaryCIDRButton.parent().removeAttr('style');
        } else {
          addSecondaryCIDRButton.attr('disabled', true);
          addSecondaryCIDRButton.parent().css('cursor', 'not-allowed');
        }
      }

      // Set values for existing intranet, private and public subnets
      for (const [key, value] of Object.entries(existingVPCData.subnets)) {
        if (subnetTypesInfo[key].availableFor.includes(existingVPCData.network_type)) {
          subnetsNumbers[key] = value.length;
          if (key === 'public' || key === 'intranet') {
            subnetsDropdowns[key].attr('disabled', true);
            subnetsDropdowns[key].parent().attr('title', key + ' subnets cannot be updated');
          } else if (value.length) {
            [...Array(value.length - 1).keys()].forEach(i =>
              subnetsDropdowns[key].find('option:eq(' + i + ')').remove(),
            );
          }
          subnetsDropdowns[key].val(value.length);
          setSubnetOptionFromVPC(key, value, subnetTypesInfo[key].defaultOptions);
        } else {
          delete subnetsNumbers[key];
          subnetsOptions[key] = [];
        }
        subnetsDropdowns[key].trigger('change');
      }

      // Refresh all selectpickers on the page
      $('.selectpicker').selectpicker('refresh');
    } else {
      // Prepare a warning message in case that the VPC can't be updated
      if (existingVPCData && !title) {
        title = 'The provided VPC is not valid for Update';
        text =
          'VPC ' +
          existingVPCData.vpc_id +
          ' in account ' +
          existingVPCData.aws_account_id +
          ' is in status ' +
          existingVPCData.status +
          '. Please restore the VPC to status active before proceed with the update.';
      }
      // Show and alert and resets the form in case of any error
      swal({
        title: title,
        text: text,
        icon: 'warning',
        buttons: {
          okay: {
            text: 'Ok',
            value: true,
            visible: true,
          },
        },
      }).then(() => {
        this.resetForm();
      });
    }
  };

  /**
   *
   * @param subnetType {NetworkType}
   * @param subnetsFromVPC {Subnet[]}
   * @param defaultOptions
   */
  const setSubnetOptionFromVPC = (subnetType, subnetsFromVPC, defaultOptions) => {
    if (subnetsFromVPC.length === 0) {
      subnetsOptions[subnetType] = [];
    } else {
      subnetsOptions[subnetType] = subnetsFromVPC.map(x => {
        const subnetOptionToHtml = {
          id: x.subnet_id,
          name: x.name,
          cidr: x.cidr,
          'nat-gateway': x.nat_gateways && x.nat_gateways.length ? 'true' : defaultOptions['nat-gateway'],
          'availability-zone': x.availability_zone_id,
          'edit-mode': true,
        };
        if (subnetOptionToHtml['nat-gateway'] === 'true') {
          const natGatewayAddress =
            x.nat_gateways[0].NatGatewayAddresses && x.nat_gateways[0].NatGatewayAddresses.length
              ? x.nat_gateways[0].NatGatewayAddresses[0]
              : null;
          subnetOptionToHtml['elastic-ip'] = natGatewayAddress
            ? natGatewayAddress.PublicIp || natGatewayAddress.PrivateIp
            : defaultOptions['allocation-id'];
          subnetOptionToHtml['allocation-id'] = natGatewayAddress
            ? natGatewayAddress.AllocationId || natGatewayAddress.NetworkInterfaceId
            : defaultOptions['allocation-id'];
        } else {
          subnetOptionToHtml['allocation-id'] = defaultOptions['allocation-id'];
        }
        return subnetOptionToHtml;
      });
    }
  };

  this.resetForm = async () => {
    // Reset URL and page headers and texts
    setSearchParamsUrl();
    document.title = 'AWS | Create VPC';
    $('#mainTitle').text('Create VPC');
    $('#header-title').text('Create New VPC');
    $('#card-body-span').text('With this form you can request a new VPC in your target AWS Account');
    networkFormError.empty();
    networkFormError.hide();

    // Reset Account Id dropdown
    accountId = undefined;
    accountIdDropdown.attr('disabled', false);
    accountIdDropdownRefresh.attr('disabled', false);

    // Hide VPC Id input
    vpcId = undefined;
    vpcIdInput.attr('disabled', true);
    vpcIdInput.closest('.form-group').hide();

    // Reset Network Type dropdown
    networkType = undefined;
    networkTypeDropdown.empty();
    networkTypeDropdown.append($('<option value="" disabled selected>- Please select a network type -</option>'));
    networkTypeDropdown.attr('disabled', false);

    // Reset Network Region dropdown
    networkRegion = undefined;
    removeDisabledOptions(networkRegionDropdown[0]);
    networkRegionDropdown.attr('disabled', false);

    // Reset Network Name input
    networkNameInput.val('');
    networkNameInput.attr('disabled', false);
    networkNameInput.attr('readonly', false);

    // Reset Network Address and Size
    networkAddressInput.val('');
    networkAddressInput.attr('disabled', false);
    networkAddressInput.attr('readonly', false);
    networkSizeDropdown.attr('disabled', false);

    // Reset Add Secondary CIDR button and clear Secondary CIDRs
    if (addSecondaryCIDRButton.attr('disabled')) {
      addSecondaryCIDRButton.removeAttr('disabled');
      addSecondaryCIDRButton.parent().removeAttr('style');
    }
    secondaryCIDRDiv.empty().hide();

    // Reset Subnet Number dropdowns and subnet details button
    for (const [key, value] of Object.entries(subnetsDropdowns)) {
      value.empty();
      value.append(
        $('<option value="" disabled selected>- Please select the target number of ' + key + ' subnets -</option>'),
      );
    }
    dispaySubnetsOptionsButton.find('.fas').removeClass('fa-chevron-down').addClass('fa-chevron-right');
    await toggleSubnetsContainer(false);

    // Reset the submit button value and text
    submitButton.val('Create VPC');
    submitButton.text('Create VPC');

    // Reset variables
    appliedQuotas = {};
    deployedResources = {};
    existingVPCData = {};

    // Reset form validations
    networkCreateForm.trigger('reset');

    // Deselect and refresh all selectpickers
    $('.selectpicker').selectpicker('val', '');
    $('.selectpicker').not('.no-deselect').selectpicker('deselectAll');
    $('.selectpicker').selectpicker('refresh');

    // Scroll to the top of the page
    window.scrollTo(0, 0);
  };

  /**
   * @name updateNetworkTypeDropdown
   * @description populate the network type dropdown based on the selected account
   */
  const updateNetworkTypeDropdown = function () {
    // my suggestion would be to define models/contracts between frontend and backend
    // and not read directly from the DB in the backend
    vpcAPI.settings().then(settings => {
      // Read and store the settings from the backend
      let private_vpc_account_stages = [];
      let private_vpc_account_types = [];
      let public_vpc_account_stages = [];
      let public_vpc_account_types = [];
      let vpc_validation = settings.networking.vpc_validation;
      try {
        private_vpc_account_stages = vpc_validation.private_vpc_account_stages || [];
        private_vpc_account_types = vpc_validation.private_vpc_account_types || [];
        public_vpc_account_stages = vpc_validation.public_vpc_account_stages || [];
        public_vpc_account_types = vpc_validation.public_vpc_account_types || [];
      } catch (error) {
        console.error(error);
      }

      // Update the info text with the currently configured values from the backend
      if (private_vpc_account_stages) {
        document.getElementById('network-type-infobox-private-account-stages').textContent =
          private_vpc_account_stages.join(', ');
      }
      if (private_vpc_account_types) {
        document.getElementById('network-type-infobox-private-account-types').textContent =
          private_vpc_account_types.join(', ');
      }
      if (public_vpc_account_stages) {
        document.getElementById('network-type-infobox-public-account-stages').textContent =
          public_vpc_account_stages.join(', ');
      }
      if (public_vpc_account_types) {
        document.getElementById('network-type-infobox-public-account-types').textContent =
          public_vpc_account_types.join(', ');
      }

      // Create an object which will contain all the network type actions
      const networkTypeOptions = {};

      // Add the option to create public VPCs only to accounts with the following account type
      if (public_vpc_account_types.includes(accountType) && public_vpc_account_stages.includes(accountStage)) {
        networkTypeOptions['public'] = {
          name: 'Public VPC',
          description: "A single VPC with an Internet Gateway. There's no connection to the Corporate Network.",
        };
      }

      // Add the option to create private VPCs only to accounts with the following account type
      if (private_vpc_account_types.includes(accountType) && private_vpc_account_stages.includes(accountStage)) {
        networkTypeOptions['private'] = {
          name: 'Private VPC',
          description:
            "A single VPC with a direct connection to the BMW Corporate Network. There's no direct route to the Internet",
        };
      }

      networkTypeDropdown.empty();
      for (const [key, value] of Object.entries(networkTypeOptions)) {
        networkTypeDropdown.append($('<option>').val(key).text(value.name).attr('data-subtext', value.description));
      }

      if (networkType) {
        networkTypeDropdown.val(networkType);
      }
      networkTypeDropdown.trigger('change');
    });
  };

  const onNetworkTypeChange = async () => {
    networkType = networkTypeDropdown.val();
    if (networkType) {
      document.getElementById('network-configuration-details').style.display = '';

      if (!vpcId) {
        networkAddressInput.removeAttr('readonly');
        networkSizeDropdown.removeAttr('disabled');
      }

      networkAddressPlaceholder = addressValuePlaceholder;
      networkAddressRegex = networkType === 'public' ? publicAddressRegex : privateAddressRegex;

      const networkAddressTitle =
        'Please insert a valid Network Address, for example ' +
        networkAddressPlaceholder +
        ' (without network size /##). Regex pattern: ' +
        networkAddressRegex.source;

      switch (networkType) {
        case 'private':
          networkAddressInput.attr('disabled', true);
          if (!vpcId) {
            networkAddressInput.val('New private IPv4 address will be assigned automatically');
          }
          break;
        case 'public':
          networkAddressInput.attr({
            pattern: networkAddressRegex.source,
            title: networkAddressTitle,
            placeholder: 'e.g. ' + networkAddressPlaceholder,
          });
          if (!vpcId) {
            networkAddressInput.removeAttr('disabled');
            networkAddressInput.val(networkAddressPlaceholder);
          }
          break;
        default:
          showError(`Network Type cannot have ${networkType} value`);
      }

      networkAddressInput.trigger('change');
      await loadRegions();
      updateNetworkSizeDropdown();
      networkSizeDropdown.trigger('change');

      if (addSecondaryCIDRButton.attr('disabled')) {
        if (existingVPCData && existingVPCData.secondary_cidrs.length < 4) {
          addSecondaryCIDRButton.removeAttr('disabled');
          addSecondaryCIDRButton.parent().removeAttr('style');
        }
      }

      if (dispaySubnetsOptionsButton.attr('disabled')) {
        dispaySubnetsOptionsButton.removeAttr('disabled');
        dispaySubnetsOptionsButton.parent().css('cursor', '');
      } else if (areSubnetsOptionsChanged()) {
        resetSubnetsOptions();
      }
    } else {
      removeSpinners();
      document.getElementById('network-configuration-details').style.display = 'none';
      $('.selectpicker').selectpicker('refresh');
    }
  };

  const onNetworkRegionChange = async () => {
    showAndProcessRegionRestrictWarning('region', 'acknowledge-denied-region', 'selected-denied-region');

    if (accountId) {
      addSpinner();

      const loading_network_region = document.querySelector('.loading-network-region');
      if (loading_network_region) loading_network_region.classList.add('loading-animation');

      const networkRegionErrorHandler = err => {
        console.error(err);
        submitButton.attr('disabled', true);
        submitButton.parent().css('cursor', 'not-allowed');
        showError(`Access to account ${accountId} in region ${networkRegionDropdown.val()} failed.`);
      };

      if (CONF.awsLoginDetails.awsPartition == 'aws') {
        // Collect all quotas based on a given account and region for AWS Global
        Promise.all([
          vpcAPI.getAccountQuotas(accountId, networkRegionDropdown.val()),
          vpcAPI.getAccountResources(accountId, networkRegionDropdown.val()),
          vpcAPI.getAvailabilityZones(accountId, networkRegionDropdown.val(), networkRegion),
        ])
          .then(response => {
            appliedQuotas = response[0];
            deployedResources = response[1];
            availabilityZones = response[2] ?? availabilityZones;

            // Block the VPC deployment if the number of existing VPCs hits already the quota limit
            if (appliedQuotas.vpcs.Value === deployedResources.vpcs && !vpcId) {
              showQuotaError(appliedQuotas.vpcs, deployedResources.vpcs).catch(err => {
                console.error(err);
              });
            } else {
              if (networkRegion !== networkRegionDropdown.val() || areSubnetsOptionsChanged()) {
                resetSubnetsOptions();
              }
              submitButton.attr('disabled', false);
            }
          })
          .catch(networkRegionErrorHandler)
          .finally(() => {
            networkRegion = networkRegionDropdown.val();
            removeSpinners();
          });
      } else {
        // In AWS China the quota service is not available yet. Therefore we have to skip the quota check there
        Promise.all([vpcAPI.getAvailabilityZones(accountId, networkRegionDropdown.val(), networkRegion)])
          .then(response => {
            availabilityZones = response[0] ?? availabilityZones;

            // Block the VPC deployment if the number of existing VPCs hits already the quota limit
            if (networkRegion !== networkRegionDropdown.val() || areSubnetsOptionsChanged()) {
              resetSubnetsOptions();
            }
            submitButton.attr('disabled', false);
          })
          .catch(networkRegionErrorHandler)
          .finally(() => {
            networkRegion = networkRegionDropdown.val();
            removeSpinners();
          });
      }
    }
  };

  const loadRegions = async () => {
    const regions = await vpcAPI.getRegionsMetadata(networkType);
    removeDisabledOptions(networkRegionDropdown);
    networkRegionDropdown.append(
      regions.map(region => {
        let optionContent =
          `<span class="bs-dropdown-item-text">${region.name}</span>` +
          `<span class="bs-dropdown-badge new">${region.id}</span>`;
        if (region.restriction_type === 'Denied') {
          optionContent += `<span class="bs-dropdown-badge">Future Deny List</span>`;
        }

        return $('<option>')
          .val(region.id)
          .attr('data-content', optionContent)
          .attr('data-restriction-type', region.restriction_type)
          .attr(
            'selected',
            region.id === (networkRegion || 'eu-central-1') || region.id === (networkRegion || 'cn-north-1'),
          );
      }),
    );
    networkRegionDropdown.trigger('change');
    $('.selectpicker').selectpicker('refresh');
  };

  /**
   * @name populateCIDRSizeDropdown
   * @description populate the CIDR size dropdown with the pertinent option starting from the minimum
   * @param {JQuery} CIDRSizeDropdown the jQuery CIDR size select element
   * @param {number} minimum the minimum size of the CIDR
   */
  const populateCIDRSizeDropdown = (CIDRSizeDropdown, minimum) => {
    Array.from({ length: minSubnetSize - minimum + 1 }, (_, i) => {
      const optionValue = i + minimum;
      const option = $('<option>')
        .val(optionValue)
        .text('/' + optionValue)
        .attr(
          'data-subtext',
          'max ' + (Math.pow(2, minSubnetSize - optionValue) * subnetIps28 - awsReservedSubnetIPs) + ' IPs',
        );
      return CIDRSizeDropdown.append(option);
    });
  };

  /**
   * Populate the network size dropdown of the primary CIDR based on the network type selected
   */
  const updateNetworkSizeDropdown = () => {
    let minimum;
    if (existingVPCData && existingVPCData.cidr) {
      minimum = parseInt(existingVPCData.cidr.split('/')[1]);
    } else {
      minimum = minSubnetSizeMapping[networkType];
    }

    networkSizeDropdown.empty();
    populateCIDRSizeDropdown(networkSizeDropdown, minimum);

    if (existingVPCData && existingVPCData.cidr) {
      networkSizeDropdown.val(minimum);
    } else {
      if (networkType === 'private') {
        networkSizeDropdown.val(privateDefaultSize);
      } else {
        networkSizeDropdown.val(publicDefaultSize);
      }
    }

    $('.selectpicker').selectpicker('refresh');
  };

  /**
   * Update all the subnets number dropdown based on the network type selected before
   */
  const updateSubnetsNumberDropdown = () => {
    // Clear the number of subnets selectpickers
    subnetsDropdowns.public.empty();
    subnetsDropdowns.private.empty();
    subnetsDropdowns.intranet.empty();

    // Prepare the new number of subnets selectpicker data and disable all
    // subnet options that aren't available for the selected network type
    const subnetsDropdownsToPopulate = [subnetsDropdowns.private];
    const disabledOptionTitle = 'subnet not available for ' + networkType + ' VPC';
    switch (networkType) {
      case 'private':
        subnetsDropdownsToPopulate.push(subnetsDropdowns.intranet);
        subnetsDropdowns.intranet.removeAttr('disabled title');
        subnetsDropdowns.public.attr('disabled', true);
        subnetsDropdowns.public.parent().attr('title', 'Public ' + disabledOptionTitle);
        delete subnetsNumbers.public;
        break;
      case 'public':
        subnetsDropdownsToPopulate.push(subnetsDropdowns.public);
        subnetsDropdowns.intranet.attr('disabled', true);
        subnetsDropdowns.public.removeAttr('disabled title');
        subnetsDropdowns.intranet.parent().attr('title', 'Intranet ' + disabledOptionTitle);
        delete subnetsNumbers.intranet;
        break;
      default:
        console.warn(networkType + ' is not a valid option');
    }

    // Set the default number of subnets based on the selected network size
    let subnetCount = 0;
    switch (networkSize) {
      case '28':
        subnetCount = 1;
        break;
      case '27':
        subnetCount = 2;
        break;
      default:
        subnetCount = 3;
    }

    // Add new options to the dropdown
    subnetsDropdownsToPopulate.forEach(subnetsDropdown => {
      // Add the dropdown options (numbers from 1 to 3, depending on the subnetCound above)
      // and disables the private subnet options for private VPCs if there's no secondary CIDR
      const subnetType = subnetsDropdown.data('subnet-type');

      // Add 0 as an option for private subnets as they are optional. Disable the option if
      // there are already private subnets deployed in an existing VPC
      if (subnetType === 'private') {
        const subnetCountOption = $('<option>').val(0).text(0).attr('data-subtext', `${subnetType} subnet(s)`);
        if (existingVPCData && existingVPCData.subnets && existingVPCData.subnets[subnetType].length > 0) {
          subnetCountOption.attr('disabled', true);
        }
        subnetsDropdown.prepend(subnetCountOption);
      }

      // Add between 1 and 3 subnet numbers, depending on the selected network size. For existing VPCs,
      // disable all the options that contains a number of subnets less than the already deployed subnets
      [...Array(subnetCount).keys()].forEach(i => {
        const subnetCountOption = $('<option>')
          .val(i + 1)
          .text(i + 1)
          .attr('data-subtext', `${subnetType} subnet(s)`);

        if (
          (networkType === 'private' && subnetType === 'private' && !secondaryCIDRs.length) ||
          (existingVPCData && existingVPCData.subnets && existingVPCData.subnets[subnetType].length > i + 1)
        ) {
          subnetCountOption.attr('disabled', true);
        }

        subnetsDropdown.append(subnetCountOption);
      });

      // Select the target option
      if (networkType === 'private' && subnetType === 'private' && !secondaryCIDRs.length) {
        subnetsDropdown.val(0);
        subnetsDropdown.parent().attr('title', privateNASubnetNumberTitle);
      } else {
        if (existingVPCData && existingVPCData.subnets && existingVPCData.subnets[subnetType]) {
          subnetsDropdown.val(existingVPCData.subnets[subnetType].length);
          if (subnetType === 'intranet') {
            subnetsDropdown.attr('disabled', true);
          }
        } else {
          subnetsDropdown.val(subnetCount === 1 ? 1 : 2);
        }
      }
    });
    $('.selectpicker').selectpicker('refresh');

    // Show warning message for private VPCs if the size is getting to big
    if (networkSize <= 26 && networkType !== 'public' && !vpcId) {
      swal({
        title: 'Please think twice!',
        text:
          "We're running out of private IPv4 addresses. Please help to save IPs and choose (if possible) a smaller " +
          'network size (/27 or /28).',
        icon: 'warning',
        buttons: {
          okay: {
            text: 'Ok',
            value: true,
            visible: true,
          },
        },
      });
    }
  };

  /**
   * @name addSecondaryCIDR
   * @description add a secondary CIDR input, generating the HTML needed
   * @returns {JQuery}
   */
  const addSecondaryCIDR = () => {
    secondaryCIDRDiv.show();
    const inputText = $('<input required type="text" class="form-control secondary-cidr-address"/>');
    const idDivValidFeedback = 'network-address-valid-feedback-' + divFeedbackIndexCounter;
    const idDivInvalidFeedback = 'network-address-invalid-feedback-' + divFeedbackIndexCounter;
    inputText.attr({
      id: 'network-address-' + divFeedbackIndexCounter,
      name: 'network-address-' + divFeedbackIndexCounter,
      value: networkAddressPlaceholder,
      pattern: networkAddressRegex.source,
      title:
        'Please insert a valid secondary CIDR and "Associate" it to the VPC. For example ' +
        networkAddressPlaceholder +
        ' (without network size /##). Regex pattern: ' +
        networkAddressRegex.source,
      placeholder: 'e.g. ' + networkAddressPlaceholder,
      'aria-describedby': idDivValidFeedback + ' ' + idDivInvalidFeedback,
      'data-secondary-cidr': 'validation',
    });
    const selectSize = $(
      '<select required class="form-control form-select selectpicker secondary-cidr-size" data-live-search="true" data-show-subtext="true">',
    );
    selectSize.attr({
      id: 'network-size-' + divFeedbackIndexCounter,
      name: 'network-size-' + divFeedbackIndexCounter,
    });
    populateCIDRSizeDropdown(selectSize, subnetIps28);
    selectSize.val(publicDefaultSize);
    const deleteSecondaryCIDRButton = $(
      '<button type="button" data-action="delete" class="btn btn-light btn-xs" title="Delete Secondary CIDR">',
    );
    deleteSecondaryCIDRButton.html('<i class="fas fa-trash-alt icon-color"></i><span class="ms-2 link">Delete</span>');
    const associateSecondaryCIDRButton = $(
      '<button type="button" data-action="associate" class="btn btn-light btn-xs" title="Associate Secondary CIDR">',
    );
    associateSecondaryCIDRButton.html(
      '<i class="fas fa-check icon-color"></i><span class="ms-2 link">Associate</span>',
    );
    const divValidFeedback = $('<div class="valid-feedback" id="' + idDivValidFeedback + '"></div>');
    const divInvalidFeedback = $('<div class="invalid-feedback" id="' + idDivInvalidFeedback + '"></div>');
    const secondaryCIDRInputGroup = $('<div class="col input-group mb-1 gap-10">');
    secondaryCIDRDiv.append(
      $('<div class="row mt-2">').append(
        $('<label class="col-form-label"></label>'),
        secondaryCIDRInputGroup.append([
          inputText,
          selectSize,
          deleteSecondaryCIDRButton,
          associateSecondaryCIDRButton,
          divValidFeedback,
          divInvalidFeedback,
        ]),
      ),
    );
    deleteSecondaryCIDRButton.on('click', () => deleteSecondaryCIDR(secondaryCIDRInputGroup));
    associateSecondaryCIDRButton.on('click', () => checkOvelappingCIDRs(secondaryCIDRInputGroup));

    if (networkType === 'public') {
      networkAddressInput.attr('readonly', true);
      networkSizeDropdown.attr('disabled', true);
    }
    addSecondaryCIDRButton.attr('disabled', true);
    addSecondaryCIDRButton.parent().css('cursor', 'not-allowed');
    // dummy variable to manage the invalid feedback ids -> aria-describedby
    divFeedbackIndexCounter++;
    $('.selectpicker').selectpicker('refresh');
    return secondaryCIDRInputGroup;
  };

  /**
   * @async
   * @name checkOvelappingCIDRs
   * @description Check if there are overlapping CIDRs in the inputs
   */
  const checkOvelappingCIDRs = async secondaryCIDRInputGroup => {
    let inputText;
    const CIDRs = [];
    const secondaryCIDRsInputs = $('.secondary-cidr-address');
    if (secondaryCIDRsInputs.length) {
      if (networkType === 'public') CIDRs.push(CIDR);

      try {
        secondaryCIDRsInputs.map((_, element) => {
          inputText = $(element);
          const CIDRValue = inputText.val() + '/' + inputText.next().find('.secondary-cidr-size').val();
          if (networkAddressRegex.test(CIDRValue)) {
            CIDRs.push(CIDRValue);
          } else {
            throw new CidrException(
              'Invalid CIDR format. Please insert a valid secondary CIDR and associate it to the VPC.',
              networkAddressRegex,
              CIDRValue,
            );
          }
        });

        addSpinner();
        await vpcAPI.checkOvelappingCIDRs(CIDRs);
        renderValidCIDRs(secondaryCIDRInputGroup, secondaryCIDRsInputs);

        if (networkType === 'public') secondaryCIDRs = CIDRs.filter(x => x !== CIDR);
        else secondaryCIDRs = CIDRs;

        if (secondaryCIDRs.length < 4) {
          addSecondaryCIDRButton.removeAttr('disabled');
          addSecondaryCIDRButton.parent().removeAttr('style');
        }
        if (networkType === 'private' && secondaryCIDRs.length === 1) {
          subnetsDropdowns.private.find('option').map((_, option) => {
            const subnetNumberOptions = $(option);
            if (parseInt(subnetNumberOptions.val()) !== 0) subnetNumberOptions.removeAttr('disabled');
          });
          subnetsDropdowns.private.val(networkSize === 28 ? 1 : 2);
          subnetsDropdowns.private.trigger('change');
          subnetsDropdowns.private.parent().removeAttr('title');
          $('.selectpicker').selectpicker('refresh');
        }
        if (subnetsContainerDiv.find('ul').length && areSubnetsOptionsChanged()) resetSubnetsOptions();
        submitButton.attr('disabled', false);
      } catch (err) {
        submitButton.attr('disabled', true);
        submitButton.parent().css('cursor', 'not-allowed');

        if (err.name === 'CidrException') {
          await showErrorFromApiOperation(err.message)(err);
        } else if (err.response && err.response.status === 400) {
          renderCIDRsOverlapping(err.response.data.errors, secondaryCIDRsInputs);
        } else {
          await showErrorFromApiOperation('Error cheching CIDRs')(err);
        }
      } finally {
        removeSpinners();
      }
    }
    $('.selectpicker').selectpicker('refresh');
  };

  const renderValidCIDRs = (secondaryCIDRInputGroup, secondaryCIDRsInputs) => {
    const associateSecondaryCIDRButton = secondaryCIDRInputGroup.find('button[data-action="associate"]');
    associateSecondaryCIDRButton.attr('disabled', true);
    secondaryCIDRInputGroup.find('input').attr('readonly', true);
    secondaryCIDRInputGroup.find('select').attr('disabled', true);
    if (secondaryCIDRsInputs.length) {
      secondaryCIDRsInputs.addClass('is-valid');
      secondaryCIDRsInputs.removeClass('is-invalid');
      secondaryCIDRsInputs.map((_, element) => {
        const inputText = $(element);
        const select = inputText.next().find('.secondary-cidr-size');
        select.addClass('is-valid');
        select.removeClass('is-invalid');
        select.parent().removeClass('is-invalid');
        inputText
          .attr('aria-describedby')
          .split(' ')
          .forEach(x => {
            $('#' + x).empty();
            $('#' + x).hide();
          });
      });
    }
  };

  const renderCIDRsOverlapping = (overlappingCIDRs, secondaryCIDRsInputs) => {
    const overlappingCIDRText = 'CIDR block %FIRST_CIDR% overlaps with another CIDR block %SECOND_CIDR%';
    overlappingCIDRs.map(CIDRsPair => {
      renderInvalidCIDRError(
        secondaryCIDRsInputs,
        overlappingCIDRText.replace('%FIRST_CIDR%', CIDRsPair[0]).replace('%SECOND_CIDR%', CIDRsPair[1]),
      );
    });
  };

  const renderInvalidCIDRError = (secondaryCIDRsInputs, message) => {
    let secondaryCIDRSelect;
    let secondaryCIDRInput;
    if (secondaryCIDRsInputs.length === 1) secondaryCIDRInput = secondaryCIDRsInputs;
    else secondaryCIDRInput = secondaryCIDRsInputs.last();

    secondaryCIDRInput.focus();
    secondaryCIDRSelect = secondaryCIDRInput.next().find('.secondary-cidr-size');
    secondaryCIDRInput.removeClass('is-valid');
    secondaryCIDRInput.addClass('is-invalid');
    secondaryCIDRSelect.removeClass('is-valid');
    secondaryCIDRSelect.addClass('is-invalid');
    secondaryCIDRInput
      .attr('aria-describedby')
      .split(' ')
      .map(x => {
        if (x.includes('invalid')) $('#' + x).text(message);
      });
  };

  /**
   * Delete the row for the input as secondary CIDR where the deleteSecondaryCIDRButton is placed
   * @param {JQuery} secondaryCIDRInputGroup
   */
  const deleteSecondaryCIDR = secondaryCIDRInputGroup => {
    const deleteSecondaryCIDRButton = secondaryCIDRInputGroup.find('button[data-action="delete"]');
    deleteSecondaryCIDRButton.parent().parent().remove();
    const nCIDRs = secondaryCIDRDiv.find('div.input-group').length;
    const nEditableCIDRs = secondaryCIDRDiv.find('input:not([readonly])').length;
    if (nCIDRs < 4 && nEditableCIDRs === 0) {
      addSecondaryCIDRButton.removeAttr('disabled');
      addSecondaryCIDRButton.parent().removeAttr('style');
    }
    if (nCIDRs < 1) {
      secondaryCIDRDiv.hide();
      secondaryCIDRDiv.empty();
      secondaryCIDRs = [];
      switch (networkType) {
        case 'public':
          if (!vpcId) {
            networkAddressInput.removeAttr('readonly');
            networkSizeDropdown.removeAttr('disabled');
          }
          break;
        case 'private':
          subnetsDropdowns.private.find('option').map((_, option) => {
            if (parseInt($(option).val()) !== 0) {
              $(option).attr('disabled', true);
            }
          });
          subnetsDropdowns.private.val(0);
          subnetsNumbers.private = 0;
          subnetsOptions.private = [];
          subnetsDropdowns.private.parent().attr('title', privateNASubnetNumberTitle);
          break;
      }
      $('.selectpicker').selectpicker('refresh');
    }
    const editableSecondaryCIDRInput = secondaryCIDRInputGroup.find('input:not([readonly])').length;
    if (!editableSecondaryCIDRInput && areSubnetsOptionsChanged()) resetSubnetsOptions();
    submitButton.attr('disabled', false);
  };

  const areSubnetsOptionsChanged = () => {
    if (vpcId && existingVPCData) {
      for (const [key, value] of Object.entries(existingVPCData.subnets)) {
        if (subnetTypesInfo[key].availableFor.includes(existingVPCData.network_type)) {
          if (subnetsOptions[key].length !== value.length) return true;
          const atLeastOneModified = value.find(x => {
            // all the other options are read-only
            const existingOptionsForSubnet = sort({
              'nat-gateway':
                x.nat_gateways && x.nat_gateways.length
                  ? 'true'
                  : subnetTypesInfo[key]['defaultOptions']['nat-gateway'],
            });
            const currentSubnetOption = subnetsOptions[key].find(
              subnetOptionsItem => subnetOptionsItem.id === x.subnet_id,
            );
            const actualSubnetOption = sort({
              'nat-gateway': currentSubnetOption['nat-gateway'],
            });
            return JSON.stringify(existingOptionsForSubnet) !== JSON.stringify(actualSubnetOption);
          });
          if (atLeastOneModified) return true;
        }
      }
      return false;
    } else {
      for (const [key, value] of Object.entries(subnetsOptions)) {
        if (value.length) {
          const atLeastOneModified = value.find(x => {
            const defaultOptionsForSubnet = sort({ ...subnetTypesInfo[key].defaultOptions, ...{ name: '' } });
            const actualSubnetOption = sort({ ...x, ...{ name: '' } });
            return JSON.stringify(defaultOptionsForSubnet) !== JSON.stringify(actualSubnetOption);
          });
          if (atLeastOneModified) return true;
        }
      }
      return false;
    }
  };

  const resetSubnetsOptions = () => {
    if (vpcId) {
      for (const [key, value] of Object.entries(existingVPCData.subnets))
        if (subnetTypesInfo[key].availableFor.includes(existingVPCData.network_type))
          setSubnetOptionFromVPC(key, value, subnetTypesInfo[key].defaultOptions);
    } else {
      subnetsOptions = {
        public: [],
        private: [],
        intranet: [],
      };
    }
    addToast(
      'Subnet Options Reset',
      vpcId
        ? 'All the subnet configuration are reset to initial state'
        : 'All the previously configured subnet options are set to default',
      6000,
    );
    displaySubnetsOptions(true);
  };

  /**
   * Generate the HTML to display all the subnet options
   *
   * @param {boolean} forceHide
   */
  const displaySubnetsOptions = forceHide => {
    const buttonIcon = dispaySubnetsOptionsButton.find('.fas');
    if (buttonIcon.hasClass('fa-chevron-right') && !forceHide) {
      buttonIcon.removeClass('fa-chevron-right').addClass('fa-chevron-down');
      toggleSubnetsContainer(true);
    } else if (buttonIcon.hasClass('fa-chevron-down') || forceHide) {
      buttonIcon.removeClass('fa-chevron-down').addClass('fa-chevron-right');
      toggleSubnetsContainer(false);
    }
  };

  /**
   * Toggle the content of subnetsContainerDiv in order to show or hide the subnets options
   * @param {boolean} show true if we need to show the subnets options, false otherwise
   */
  const toggleSubnetsContainer = show => {
    if (show) {
      const tableIdsAndType = createSubnetsContainer();
      populateSubnetOptions([...new Set(tableIdsAndType.map(item => item.subnetType))]);
      tableIdsAndType.forEach(item => createSubnetsTable(item.id, item.subnetType));
    } else {
      subnetsContainerDiv.empty();
    }
  };

  const populateSubnetOptions = subnetTypes => {
    let iterationMissingToFillOptions = [];
    subnetTypes.forEach(subnetType => {
      if (vpcId) {
        setSubnetOptionFromVPC(
          subnetType,
          existingVPCData.subnets[subnetType],
          subnetTypesInfo[subnetType].defaultOptions,
        );
        iterationMissingToFillOptions = [
          ...Array(subnetsNumbers[subnetType] - existingVPCData.subnets[subnetType].length).keys(),
        ];
      } else if (subnetsOptions[subnetType].length === 0 && parseInt(subnetsNumbers[subnetType]) !== 0) {
        [...Array(parseInt(subnetsNumbers[subnetType])).keys()].forEach(x => {
          const defaultOptions = subnetTypesInfo[subnetType].defaultOptions;
          subnetsOptions[subnetType].push({
            ...subnetTypesInfo[subnetType].defaultOptions,
            ...{ name: defaultOptions.name + x },
          });
        });
      }
      iterationMissingToFillOptions.forEach(x => {
        const defaultOptions = subnetTypesInfo[subnetType].defaultOptions;
        subnetsOptions[subnetType].push({
          ...subnetTypesInfo[subnetType].defaultOptions,
          ...{
            name: vpcId
              ? defaultOptions.name + parseInt(existingVPCData.subnets[subnetType].length + x)
              : defaultOptions.name + x,
          },
        });
      });
    });
  };

  /**
   * Load the NetworksCreateSubnets component into the subnetsContainerDiv and generate all the needed cards/tables
   * @return {{id: string, subnetType: string}[]} list of all the table IDs containing the subnets options
   */
  const createSubnetsContainer = () => {
    const tableIdsAndType = [];
    subnetsContainerDiv.empty().append(<NetworksCreateSubnets />);

    subnetsContainerDiv.find('.tab-content > .tab-pane').each((_, element) => {
      const tabDiv = $(element);
      const tabType = tabDiv.data('tab-type');
      const renderCards = [];
      switch (true) {
        case tabType === 'overview':
          for (const [key, value] of Object.entries(subnetTypesInfo)) {
            if (value.availableFor.includes(networkType)) renderCards.push(renderCard(key, true));
          }
          tabDiv.append(renderCards);
          break;
        case subnetTypesInfo[tabType].availableFor.includes(networkType):
          tabDiv.append(renderCard(tabType, false));
          break;
        default:
          subnetsOptions[tabType] = [];
          subnetsContainerDiv.find('[href="#' + tabDiv.attr('id') + '"]').addClass('disabled');
          break;
      }
      tabDiv.find('.table.dataTable').map((_idx, item) =>
        tableIdsAndType.push({
          id: item.id,
          subnetType: item.dataset.subnetType,
        }),
      );
    });
    subnetsContainerDiv.find('.nav-item').on('click', () => {
      $('.dataTables_wrapper').trigger('resize');
    });
    return tableIdsAndType;
  };

  /**
   * Generates and return the HTML elements needed to show all the table containing the subnet options
   * @param {NetworkType} subnetType The string identify which type of subnet we are showing/generating HTML for.
   * @param {boolean} overview true, if we are in the Overview tab, false otherwise
   * @returns {HTMLElement} The HTML code to add to the partial _networks_create_subnets.html
   */
  const renderCard = (subnetType, overview) => {
    const { title, description } = subnetTypesInfo[subnetType];
    const tableId = (overview ? 'table-overview-' : 'table-') + subnetType;
    return (
      <div class="card m-1">
        <div class="card-header">
          <h5>{title}</h5>
          <p>{description}</p>
        </div>
        <div class="card-body">
          <table
            id={tableId}
            class="table table-hover dataTable row-border nowrap"
            data-subnet-type={subnetType}
            style="width: 100%"
            role="grid"
          />
        </div>
      </div>
    );
  };

  /**
   * Create the jQuery DataTable based on the ID and the data passed to the function
   * @param {string} tableId The string identify the id of the HTML table
   * @param {NetworkType} subnetType the subnet type
   */
  const createSubnetsTable = (tableId, subnetType) => {
    const dataTable = initDataTable(
      tableId,
      'rt',
      [],
      [
        {
          name: 'select_col',
          data: null,
          defaultContent: '',
          orderable: false,
          class: 'details-control',
          createdCell: null,
        },
        {
          name: 'name_col',
          title: 'Name',
          data: 'name',
          createdCell: addCopyButton,
        },
        {
          name: 'cidr_col',
          title: 'CIDR',
          data: 'cidr',
          createdCell: (td, cellData) => {
            if (cellData === 'auto') $(td).text('Automatically assigned');
            else addCopyButton(td);
          },
        },
        {
          name: 'availability_zone_col',
          title: 'AZ',
          data: 'availability-zone',
          createdCell: (td, cellData) => {
            if (cellData === 'auto') $(td).text('Automatically assigned');
            else addCopyButton(td);
          },
        },
        {
          name: 'nat_gateway_col',
          title: 'NAT GW',
          data: 'nat-gateway',
          createdCell: (td, cellData) => {
            if (!cellData) $(td).text('Not Applicable');
            else addCopyButton(td);
          },
        },
        {
          name: 'allocation_id_col',
          title: 'NAT Gateway IP',
          data: 'allocation-id',
          createdCell: (td, cellData, rowData) => {
            if (!cellData) $(td).text('Not Applicable');
            else if (cellData === 'auto') $(td).text('Automatically Assigned');
            else {
              $(td).text(rowData['elastic-ip']);
              addCopyButton(td);
            }
          },
        },
        {
          name: 'actions_col',
          title: 'Actions',
          data: 'edit-mode',
          orderable: false,
          defaultContent: '',
          createdCell: (td, cellData) => createTableButtons(td, subnetType, tableId, cellData),
        },
      ],
      null,
      {
        searching: false,
        columnDefs: [
          {
            targets: '_all',
            visible: true,
            orderable: true,
            render: {
              display: encodeEntities,
            },
            createdCell: addCopyButton,
          },
        ],
      },
    );
    dataTable.rows.add(subnetsOptions[subnetType]).draw();
    $(`#${tableId} tbody`).on('click', 'tr td.details-control', async event => {
      const tr = $(event.target).closest('tr');
      const row = dataTable.row(tr);
      if (row.child.isShown()) {
        isValidSubnetCIDR(tr.next(), subnetsOptions[subnetType][row.index()], subnetType).then(() => {
          tr.removeClass('details');
          dataTable.row(row.index()).data(subnetsOptions[subnetType][row.index()]).draw();
          refreshRow(row);
          row.child.hide();

          let differentTabTable;
          if (tableId.includes('-overview-')) differentTabTable = $('#table-' + tableId.split('-')[2]);
          else differentTabTable = $('#table-overview-' + tableId.split('-')[1]);
          differentTabTable.DataTable().row(row.index()).data(subnetsOptions[subnetType][row.index()]).draw();
          refreshRow(differentTabTable.DataTable().row(row.index()));
        });
      } else {
        tr.addClass('details');
        addRowLoadingAnimation(row);
        const detailsRow = await renderSubnetDetailsRow(
          subnetType,
          subnetsOptions[subnetType][row.index()],
          row.index(),
          tableId,
        );
        row.child(detailsRow).show();
        row.child().addClass('rowDetails');
      }
    });
  };

  const isValidSubnetCIDR = (rowDetails, subnetData, subnetType) => {
    return new Promise((resolve, reject) => {
      const subnetAddress = rowDetails.find('[data-subnet-option="address"]');
      const subnetSize = rowDetails.find('[data-subnet-option="size"]');
      const subnetAddressValue = subnetAddress.val();
      if (!subnetData['edit-mode'])
        switch (true) {
          case subnetAddressValue === 'Automatically assigned':
            resolve();
            break;
          case networkAddressRegex.test(subnetAddressValue):
            isSubnetCIDRContained(subnetAddress, subnetSize, subnetData, subnetType).then(isSubnetCIDRContainedBool => {
              isSubnetCIDRContainedBool ? resolve() : reject();
            });
            break;
          default:
            renderInvalidSubnetCIDR('Please insert a valid Subnet CIDR.', subnetAddress, subnetSize);
            reject();
            break;
        }
      else resolve();
    });
  };

  const collectAllSubnetCIDRs = () => {
    const allSubnetCIDRsArray = [];
    for (const subnets of Object.values(subnetsOptions)) {
      subnets.forEach(subnet => {
        if (subnet.cidr && subnet.cidr !== 'auto') {
          allSubnetCIDRsArray.push(subnet.cidr);
        }
      });
    }
    return allSubnetCIDRsArray;
  };

  const isSubnetCIDRContained = async (subnetAddress, subnetSize, subnetData, subnetType) => {
    addSpinner();

    const subnetCIDR = subnetAddress.val() + '/' + subnetSize.val();
    let toCheckCIDRs;
    // cannot enter this if in case of private VPC for intranet, so is for sure public
    if (subnetType === 'intranet') {
      toCheckCIDRs = [CIDR];
    } else {
      toCheckCIDRs = networkType === 'private' ? secondaryCIDRs : secondaryCIDRs.concat(CIDR);
    }

    try {
      subnetData.cidr = subnetCIDR;

      // Verify that the target subnet CIDR is part of the primary/secondary VPC CIDR
      await vpcAPI.isCIDRContained(subnetCIDR, toCheckCIDRs);

      // Verify that the target subnet CIDR is not overlapping with any other subnet CIDR
      const allSubnetCIDRs = collectAllSubnetCIDRs();
      if (allSubnetCIDRs.length > 0) {
        await vpcAPI.checkOvelappingCIDRs(allSubnetCIDRs);
      }

      renderValidSubnetCIDR(subnetAddress, subnetSize);
      subnetData.cidr = subnetCIDR;
      return true;
    } catch (err) {
      // needed for intranet subnets or update vPCs?
      // subnetData.cidr = 'auto';
      if (err.response && err.response.status === 400)
        renderInvalidSubnetCIDR(err.response.data.message, subnetAddress, subnetSize);
      else {
        await showErrorFromApiOperation('Subnet CIDR is not within VPC CIDR(s)')(err);
      }
      return false;
    } finally {
      removeSpinners();
    }
  };

  const renderValidSubnetCIDR = (subnetAddress, subnetSize) => {
    subnetAddress.removeClass('is-invalid');
    subnetSize.removeClass('is-invalid');
    subnetSize.parent().removeClass('is-invalid');
    subnetAddress
      .attr('aria-describedby')
      .split(' ')
      .map(x => {
        $('#' + x).empty();
        $('#' + x).hide();
      });
    $('.selectpicker').selectpicker('refresh');
  };

  const renderInvalidSubnetCIDR = (message, subnetAddress, subnetSize) => {
    subnetAddress.removeClass('is-valid').addClass('is-invalid');
    subnetSize.removeClass('is-valid').addClass('is-invalid');
    subnetSize.parent().removeClass('is-valid');
    subnetAddress
      .attr('aria-describedby')
      .split(' ')
      .map(x => {
        if (x.includes('-invalid-')) {
          $('#' + x).text(message);
          $('#' + x).show();
        } else if (x.includes('-valid-')) {
          $('#' + x).empty();
          $('#' + x).hide();
        }
      });
    $('.selectpicker').selectpicker('refresh');
  };

  const renderSubnetDetailsRow = async (subnetType, subnetData, rowIndex, tableId) => {
    const tabId = `options-${tableId}-${rowIndex}`;

    const subnetContainer = (
      <div class="subnetContainer">
        <ul class="nav nav-tabs space">
          <li class="nav-item active" role="presentation">
            <a
              class="nav-link active"
              data-bs-target={`#${tabId}`}
              role="tab"
              data-bs-toggle="tab"
              aria-selected="true"
              aria-expanded="true">
              Subnet Options
            </a>
          </li>
        </ul>
        <div class="tab-content detailsTab">
          <div id={`#${tabId}`} class="tab-pane fade container-fluid detailsContent active show" role="tabpanel">
            <NetworksCreateSubnet />
          </div>
        </div>
      </div>
    );

    await renderSubnetOptions(subnetType, subnetData, subnetContainer, tabId);
    return subnetContainer;
  };

  const renderSubnetOptions = async (subnetType, subnetData, container, tabId) => {
    const $container = $(container);

    await Promise.all(
      // Find all form fields inside of the subnet options details (Name, Subnet CIDR, Availability Zone Name, ...)
      $container.find('[data-subnet-option]').map(async (_, element) => {
        // Retrieve the jQuery object and collect the subnet type and field.
        const subnetOptionElement = $(element);
        const subnetField = subnetOptionElement.data('subnet-option');

        // Update the label for the target field
        let optionId = tabId + '-' + subnetField;
        subnetOptionElement.attr({
          id: optionId,
          name: optionId,
        });
        subnetOptionElement.parent().closest('label').attr('for', optionId);

        switch (subnetField) {
          case 'name':
            subnetOptionElement.val(subnetData[subnetField]);
            subnetOptionElement.on('change', () => {
              subnetData[subnetField] = subnetOptionElement.val();
            });
            break;
          case 'address':
          case 'size':
            subnetOptionElement.off('change');
            renderSubnetCIDROption(subnetField, subnetType, subnetData, subnetOptionElement);
            break;
          case 'availability-zone':
            populateAvailabilityZones(subnetOptionElement, subnetData);
            break;
          case 'nat-gateway':
            if (subnetData[subnetField]) {
              subnetOptionElement.val(subnetData[subnetField]);
              let existingSubnet = vpcId
                ? existingVPCData.subnets[subnetType].find(x => x.subnet_id === subnetData.id)
                : null;
              if (
                !subnetData['edit-mode'] ||
                (subnetData['edit-mode'] && subnetData[subnetField] === 'false' && subnetType !== 'private') ||
                (existingSubnet && existingSubnet.nat_gateways && existingSubnet.nat_gateways.length === 0)
              ) {
                subnetOptionElement.find(':disabled').remove();
                subnetOptionElement.on('change', async () => {
                  subnetData[subnetField] = subnetOptionElement.val();
                  const subnetElasticIPsOptions = $container.find('[data-subnet-option="allocation-id"]');
                  if (subnetData[subnetField] === 'true') {
                    await populateElasticIPs(subnetElasticIPsOptions, subnetData, subnetType, true);
                  } else {
                    subnetElasticIPsOptions.empty();
                    subnetElasticIPsOptions.append($('<option value="" disabled selected>- Not Available -</option>'));
                    subnetData['allocation-id'] = null;
                    subnetData['elastic-ip'] = null;
                    subnetElasticIPsOptions.attr('disabled', true);
                    subnetElasticIPsOptions.selectpicker('refresh');
                  }
                });
              } else if (subnetData['edit-mode'] && subnetData[subnetField] === 'true') {
                subnetOptionElement.attr('disabled', true);
              }
            } else {
              subnetOptionElement.attr('disabled', true);
            }
            break;
          case 'allocation-id':
            if (subnetData[subnetField]) {
              await populateElasticIPs(subnetOptionElement, subnetData, subnetType);
              if (subnetType === 'intranet') {
                subnetOptionElement.attr('disabled', true);
              }
            } else {
              subnetOptionElement.attr('disabled', true);
            }
            break;
        }
      }),
    );

    if (subnetData['edit-mode']) {
      $container.find('[data-subnet-option]').map((_, element) => {
        const subnetOptionElement = $(element);
        const subnetField = subnetOptionElement.data('subnet-option');
        switch (subnetField) {
          case 'address':
          case 'name':
            subnetOptionElement.parent().find('[data-action="edit"]').attr('disabled', true);
            subnetOptionElement.parent().find('[data-action="auto"]').attr('disabled', true);
            subnetOptionElement.attr('readonly', true);
            break;
          case 'availability-zone':
          case 'size':
            subnetOptionElement.attr('disabled', true);
            break;
          // only 2 editable options
          // case 'nat-gateway':
          // case 'allocation-id':
        }
      });
    }

    $container.find('.selectpicker').selectpicker('refresh');
  };

  const populateAvailabilityZones = (subnetAvailabilityZonesOptions, subnetData) => {
    // Add all availability zones to the selectpicker dropdown menu
    availabilityZones.forEach(x =>
      subnetAvailabilityZonesOptions.append(
        $('<option value="' + x.id + '">' + x.name + '</option>').attr('data-subtext', x.id),
      ),
    );

    subnetAvailabilityZonesOptions.on('show.bs.select', function () {
      subnetAvailabilityZonesOptions.closest('.subnetContainer').css('height', '350px');
    });
    subnetAvailabilityZonesOptions.on('hide.bs.select', function () {
      subnetAvailabilityZonesOptions.closest('.subnetContainer').css('height', '');
    });

    subnetAvailabilityZonesOptions.selectpicker('refresh');
    subnetAvailabilityZonesOptions.val(subnetData['availability-zone']);
    subnetAvailabilityZonesOptions.change(() => {
      subnetData['availability-zone'] = subnetAvailabilityZonesOptions.val();
    });

    subnetAvailabilityZonesOptions.selectpicker('refresh');
  };

  /**
   * god forgive me for this code, getting better by the second
   * @param {JQuery} subnetElasticIPsOptions
   * @param {*} subnetData
   * @param {NetworkType} subnetType
   * @param {boolean?} fromChangeValue
   */
  const populateElasticIPs = async (subnetElasticIPsOptions, subnetData, subnetType, fromChangeValue) => {
    const notAssociatedElasticIP = !subnetData['allocation-id'] || subnetData['allocation-id'] === 'auto';
    switch (true) {
      case !notAssociatedElasticIP:
        elasticIPs = [{ AllocationId: subnetData['allocation-id'] || 'N/A', PublicIp: subnetData['elastic-ip'] }];
        break;
      case !subnetData['edit-mode'] || fromChangeValue || notAssociatedElasticIP:
        elasticIPs = await vpcAPI.getElasticIPs(accountId, networkRegion);
        break;
      default:
        elasticIPs = [];
    }
    subnetElasticIPsOptions.empty();
    if (elasticIPs.length) {
      if (notAssociatedElasticIP) {
        subnetElasticIPsOptions.append(
          $('<option>')
            .val('auto')
            .text('- Automatically Assigned -')
            .attr('data-subtext', 'Additonal Elastic IP will be created'),
        );
        subnetElasticIPsOptions.val('auto');
        subnetData['allocation-id'] = 'auto';
        subnetData['elastic-ip'] = null;
      }
      elasticIPs.map(elasticIP => {
        const assignedElasticIPs = subnetsOptions[subnetType].map(x => x['elastic-ip']).filter(x => x);
        // it still do not cover every possible case
        if (subnetData['elastic-ip'] === elasticIP.PublicIp || !assignedElasticIPs.includes(elasticIP.PublicIp)) {
          subnetElasticIPsOptions.append(
            $('<option>')
              .val(elasticIP.AllocationId)
              .text(elasticIP.PublicIp)
              .attr('data-subtext', elasticIP.AllocationId),
          );
        }
      });
    } else {
      subnetElasticIPsOptions.append(
        $('<option>')
          .val('auto')
          .text('- Automatically Assigned -')
          .attr(
            'data-subtext',
            subnetType === 'intranet' ? 'Not selectable for Intranet' : 'Not available Elastic IPs in AWS Account',
          ),
      );
      subnetElasticIPsOptions.find(':disabled').remove();
      subnetElasticIPsOptions.val('auto');
      subnetData['allocation-id'] = 'auto';
      subnetData['elastic-ip'] = null;
    }
    if (!notAssociatedElasticIP) {
      subnetElasticIPsOptions.val(subnetData['allocation-id']);
    } else {
      subnetElasticIPsOptions.on('change', () => {
        subnetData['allocation-id'] = subnetElasticIPsOptions.val();
        subnetData['elastic-ip'] = subnetElasticIPsOptions.find(':selected').text();
      });
      subnetElasticIPsOptions.removeAttr('disabled');
    }
    subnetElasticIPsOptions.selectpicker('refresh');
  };

  /**
   *
   * @param {*} subnetField
   * @param {*} subnetType
   * @param {*} subnetData
   * @param {JQuery} subnetOptionElement
   */
  const renderSubnetCIDROption = (subnetField, subnetType, subnetData, subnetOptionElement) => {
    const editCIDRButton = subnetOptionElement.parent().find('[data-action="edit"]');
    const autoCIDRButton = subnetOptionElement.parent().find('[data-action="auto"]');
    const subnetCIDR = subnetData.cidr;
    if (subnetField === 'address') {
      const idDivValidFeedback = 'subnet-address-valid-feedback-' + divFeedbackIndexCounter;
      const idDivInvalidFeedback = 'subnet-address-invalid-feedback-' + divFeedbackIndexCounter;
      subnetOptionElement.attr('aria-describedby', idDivValidFeedback + ' ' + idDivInvalidFeedback);
      subnetOptionElement.parent().find('.valid-feedback').attr('id', idDivValidFeedback);
      subnetOptionElement.parent().find('.invalid-feedback').attr('id', idDivInvalidFeedback);

      divFeedbackIndexCounter++;
    }
    switch (true) {
      case subnetField === 'address' && subnetData['edit-mode']:
        subnetOptionElement.val(subnetCIDR.split('/')[0]);
        subnetOptionElement.attr('disabled', false);
        break;
      case subnetField === 'size' && subnetData['edit-mode']:
        subnetOptionElement.empty();
        populateCIDRSizeDropdown(subnetOptionElement, subnetIps28);
        subnetOptionElement.val(subnetCIDR.split('/')[1]);
        break;
      case subnetField === 'address' && subnetType === 'intranet' && networkType === 'private':
        autoCIDRButton.attr('disabled', true);
        editCIDRButton.attr('disabled', true);
        subnetOptionElement.attr('disabled', true);
        subnetOptionElement.attr('title', 'Intranet subnet use primary CIDR, automatically assigned');
        break;
      case subnetField === 'size' && subnetType === 'intranet' && networkType === 'private':
        subnetOptionElement.val('auto');
        break;
      case subnetField === 'address' && subnetCIDR !== 'auto' && networkType === 'public':
      case subnetField === 'address' && subnetCIDR !== 'auto' && networkType === 'private' && secondaryCIDRs.length > 0:
        subnetOptionElement.val(subnetCIDR.split('/')[0]);
        subnetOptionElement.attr({
          pattern: networkAddressRegex.source,
          title:
            'Please insert a valid Subnet CIDR (contained in any Primary or Secondary CIDR), for example ' +
            networkAddressPlaceholder +
            ' (without network size /##). Regex pattern: ' +
            networkAddressRegex.source,
          placeholder: 'e.g. ' + networkAddressPlaceholder,
        });
        subnetOptionElement.removeAttr('readonly');
        renderSubnetCIDROptionButtons(
          editCIDRButton,
          autoCIDRButton,
          subnetOptionElement,
          subnetData,
          subnetType,
          false,
        );
        break;
      case subnetField === 'address' && networkType === 'private' && secondaryCIDRs.length < 1:
        autoCIDRButton.attr('disabled', true);
        editCIDRButton.attr('disabled', true);
        subnetOptionElement.attr('title', 'Cannot insert Subnet CIDR without any secondary CIDRs');
        break;
      //only these 2 conditions since all the other are catched before
      case subnetField === 'address' && subnetCIDR === 'auto':
        subnetOptionElement.attr('disabled', true);

        renderSubnetCIDROptionButtons(
          editCIDRButton,
          autoCIDRButton,
          subnetOptionElement,
          subnetData,
          subnetType,
          true,
        );
        break;
      case subnetField === 'size' && subnetCIDR !== 'auto':
        subnetOptionElement.removeAttr('disabled').empty();
        populateCIDRSizeDropdown(subnetOptionElement, subnetIps28);
        subnetOptionElement.val(subnetCIDR.split('/')[1]);
        break;
      case subnetField === 'size' && subnetCIDR === 'auto':
        subnetOptionElement.val(subnetCIDR);
        break;
      default:
        console.warn(
          'None of the case for renderSubnetCIDROption are matched for subnetField:' +
            subnetField +
            ' and subnetCIDR:' +
            subnetCIDR,
        );
        break;
    }
  };

  /**
   *
   * @param {*} editCIDRButton
   * @param {*} autoCIDRButton
   * @param {*} subnetOptionElement
   * @param {*} subnetData
   * @param {*} subnetType
   * @param {*} enableEdit
   */
  const renderSubnetCIDROptionButtons = (
    editCIDRButton,
    autoCIDRButton,
    subnetOptionElement,
    subnetData,
    subnetType,
    enableEdit,
  ) => {
    const subnetSizeOptionDropdown = subnetOptionElement.next();

    let subnetAddressTitle =
      'Please insert a valid Subnet CIDR (contained in %CIDRS_OPTIONS%), for example ' +
      networkAddressPlaceholder +
      ' (without network size /##). Regex pattern: ' +
      networkAddressRegex.source;
    subnetAddressTitle = subnetAddressTitle.replace(
      '%CIDRS_OPTIONS%',
      subnetType === 'intranet' ? 'the Primary CIDR' : 'any Primary or Secondary CIDR',
    );

    autoCIDRButton.click(() => {
      subnetData.cidr = 'auto';
      subnetOptionElement.off('change');
      subnetSizeOptionDropdown.off('change');
      subnetOptionElement.val('Automatically assigned').attr('readonly', true).removeAttr('pattern title placeholders');
      subnetSizeOptionDropdown
        .empty()
        .append($('<option value="auto">- Automatically assigned -</option>'))
        .attr('disabled', true)
        .val(subnetData.cidr);
      editCIDRButton.removeAttr('disabled');
      autoCIDRButton.attr('disabled', true);

      subnetOptionElement.removeClass('is-invalid is-valid');
      subnetSizeOptionDropdown.removeClass('is-invalid is-valid');
      subnetSizeOptionDropdown.parent().removeClass('is-invalid is-valid');
      subnetOptionElement
        .attr('aria-describedby')
        .split(' ')
        .map(x => {
          $('#' + x).empty();
          $('#' + x).hide();
        });
      $('.selectpicker').selectpicker('refresh');
    });

    const subnetOptionChangeHandler = async () => {
      if (networkAddressRegex.test(subnetOptionElement.val())) {
        await isSubnetCIDRContained(subnetOptionElement, subnetSizeOptionDropdown, subnetData, subnetType);
      } else {
        renderInvalidSubnetCIDR('Please insert a valid Subnet CIDR.', subnetOptionElement, subnetSizeOptionDropdown);
      }
    };

    editCIDRButton.click(() => {
      subnetOptionElement.removeAttr('disabled');
      subnetOptionElement.removeAttr('readonly');
      subnetOptionElement.attr({
        pattern: networkAddressRegex.source,
        title: subnetAddressTitle,
        placeholder: 'e.g. ' + networkAddressPlaceholder,
      });
      subnetOptionElement.val(subnetData.cidr === 'auto' ? networkAddressPlaceholder : subnetData.cidr.split('/')[0]);
      subnetSizeOptionDropdown.find('option').remove().end();
      populateCIDRSizeDropdown(subnetSizeOptionDropdown, subnetIps28);
      subnetSizeOptionDropdown
        .removeAttr('disabled')
        .val(subnetData.cidr === 'auto' ? networkSize : subnetData.cidr.split('/')[1]);
      autoCIDRButton.removeAttr('disabled');
      editCIDRButton.attr('disabled', true);
      subnetOptionElement.change(subnetOptionChangeHandler);
      subnetSizeOptionDropdown.change(subnetOptionChangeHandler);

      subnetSizeOptionDropdown.trigger('change');
      $('.selectpicker').selectpicker('refresh');
    });

    if (enableEdit) {
      editCIDRButton.removeAttr('disabled');
      autoCIDRButton.attr('disabled', true);
    } else {
      autoCIDRButton.removeAttr('disabled');
      editCIDRButton.attr('disabled', true);
    }
  };

  const createTableButtons = (td, subnetType, tableId, disabled) => {
    const actionsTd = $(td);
    actionsTd.empty().append(
      $('<div class="table-action-button-group">').append(
        $('<button class="btn btn-light btn-xs" data-bs-toggle="tooltip" title="Delete Subnet">')
          .on('click', () => deleteSubnetOptions(subnetType, tableId, actionsTd.closest('tr')))
          .append('<i class="fas fa-trash-alt icon-color">'),
      ),
    );
    if (disabled || (subnetType !== 'private' && subnetsOptions[subnetType].length < 2)) {
      let tdActionButtonGroup = actionsTd.find('.table-action-button-group');
      tdActionButtonGroup
        .css('cursor', 'not-allowed')
        .attr('title', disabled ? 'Cannot delete existing subnets' : 'At least one subnet per type must exist');
      tdActionButtonGroup.find('button').attr('disabled', true);
    }
  };

  const deleteSubnetOptions = (subnetType, tableId, tr) => {
    const table = $('#' + tableId);
    const dataTable = table.DataTable();
    const row = dataTable.row(tr);
    subnetsOptions[subnetType].splice(row.index(), 1);

    let differentTabTable;
    if (tableId.includes('-overview-')) differentTabTable = $('#table-' + tableId.split('-')[2]);
    else differentTabTable = $('#table-overview-' + tableId.split('-')[1]);

    differentTabTable.DataTable().row(row.index()).remove().draw();
    row.remove();
    dataTable.draw();
    if (subnetType !== 'private' && dataTable.data().count() < 2) {
      [table, differentTabTable].forEach(x => {
        const actionButtonGroup = x.find('.table-action-button-group');
        actionButtonGroup.css('cursor', 'not-allowed').attr('title', 'At least one subnet per type must exist');
        actionButtonGroup.find('button').attr('disabled', true);
      });
    }
    subnetsDropdowns[subnetType].val(subnetsOptions[subnetType].length);
    subnetsNumbers[subnetType] = subnetsOptions[subnetType].length;
    subnetsDropdowns[subnetType].selectpicker('refresh');
  };
}
