import { CONF } from './environment';
import {
  addCopyButton,
  addLinkOrderIdButton,
  addLoadAccountDetailsButton,
  addLoadOrderDetailsButton,
  addRowLoadingAnimation,
  configureTableColumns,
  displayErrorPopup,
  encodeEntities,
} from './main';
import { addSpinner, addTableSpinner, removeSpinners } from './sidebar';
import { getSearchParamsUrl, overwriteSearchParamsUrl } from './search_params';
import OrderTabs from '../../jsx/components/forms/OrderTabs';
import { initDataTable } from './datatable';
import { baseApiAxios } from './api';

const linkRegexAccount = /(\d{12})/;
const linkRegexVPC = /(vpc-\w+)/;
const linkRegexHostedZone = /[hH]osted\s[zZ]one:?\s{1,2}([\w.-]+)/;
const linkRegex4Wheels = /4[wW]heels\s[pP]roject\s{1,3}([\w-]+)/;

export const orderTableColumns = [
  { id: 'select_col' },
  { id: 'id_col', name: 'Order Id' },
  { id: 'creation_date_col', name: 'Order Date' },
  { id: 'action_col', name: 'Action' },
  { id: 'description_col', name: 'Order Description' },
  { id: 'account_id_col', name: 'Account Id' },
  { id: 'proposer_col', name: 'Proposer' },
  { id: 'approved_col', name: 'Approved' },
  { id: 'approval_date_col', name: 'Approval Date' },
  { id: 'approver_col', name: 'Approver' },
  { id: 'approval_comment_col', name: 'Approval Comment' },
  { id: 'executed_col', name: 'Executed' },
  { id: 'execution_date_col', name: 'Execution Date' },
  { id: 'execution_response_col', name: 'Execution Response' },
  { id: 'email_sent_col', name: 'Email Sent' },
];

const detailRowsOrders = [];

const footerDropdownSearchValues = {
  Approved: [
    { value: 'NEW', innerText: 'New' },
    { value: 'APPROVED', innerText: 'Approved' },
    { value: 'DECLINED', innerText: 'Declined' },
    { value: 'WAITING_FOR_APPROVAL', innerText: 'Waiting for Approval' },
    { value: 'TIMEOUT', innerText: 'Timeout' },
  ],
  Executed: [
    { value: 'NEW', innerText: 'New' },
    { value: 'IN_PROGRESS', innerText: 'In Progress' },
    { value: 'SUCCESS', innerText: 'Success' },
    { value: 'SUCCESS_NO_ACTIONS', innerText: 'Success - No Actions' },
    { value: 'FAILED', innerText: 'Failed' },
    { value: 'NOT_COMPLETED', innerText: 'Not Completed' },
  ],
  'Email Sent': [
    { value: 'YES', innerText: 'Yes' },
    { value: 'NO', innerText: 'No' },
  ],
};

const orderDropdownColumns = ['Approved', 'Executed', 'Email Sent'];
const orderSearchColumns = [
  'Id',
  'Action',
  'Order Description',
  'Account Id',
  'Proposer',
  'Approver',
  'Approval Comment',
  'Execution Response',
];

function loadIncrementsOrders(data, callback, table) {
  let payload = {
    action: 'list-orders',
    table_data: data,
  };

  let table_info = localStorage[table] ? JSON.parse(localStorage[table]) : undefined;

  if (table_info) {
    if (parseInt(data.start) !== 0) {
      let row_data;
      if (parseInt(table_info.LastStart) < parseInt(data.start)) {
        row_data = table_info.LastItem;
      } else if (parseInt(table_info.LastStart) === parseInt(data.start)) {
        row_data = table_info.RefreshItem;
        if (table_info.RefreshScanForward === true) {
          payload['scan_forward'] = table_info.RefreshScanForward;
        }
      } else {
        row_data = table_info.FirstItem;
        payload['scan_forward'] = true;
      }

      if (row_data) {
        payload['exclusive_start_key'] = row_data;
      }
    }
  }

  addSpinner();
  addTableSpinner();

  //use opensearch according to dynamodb setings in session
  let order_search_enable = sessionStorage.order_search_enable === 'true';
  console.debug(`using opensearch is ${order_search_enable}`);

  // inner function to process list orders response
  function process_order(result) {
    console.debug('result: ', result);
    const hasOrders = order_search_enable ? !!result.items.length : !!result.orders.length;

    let ordered_data = [];
    if (hasOrders) {
      // Handle the correct order of elements in DataTables
      ordered_data = order_search_enable ? Array.from(result.items, item => item.source) : result.orders;
      ordered_data.sort(function () {
        if (payload['scan_forward']) {
          // Workaround to fix pagination with DynamoDB:
          // The DynamoDB ScanIndexForward options will return elements in
          // the oposite order. In the portal the order (based on the
          // creation_date). This will re-order the items for one of the
          // directions.
          return -1;
        }
      });

      // Handle the table information in localStorage to establish a reload
      // of the table information without jumping back and forward in the
      // data itself. RefreshItem is the starting point for the query in
      // DynamoDB, RefreshScanForward defines if the query should happen
      // forwards or backwards.
      let RefreshItem, RefreshScanForward;
      if (table_info) {
        if (parseInt(table_info.LastStart) !== parseInt(data.start)) {
          if (parseInt(table_info.LastStart) < parseInt(data.start)) {
            RefreshItem = table_info.LastItem;
            RefreshScanForward = false;
          } else {
            RefreshItem = table_info.FirstItem;
            RefreshScanForward = true;
          }
        } else {
          RefreshItem = table_info.RefreshItem;
          RefreshScanForward = table_info.RefreshScanForward;
        }
      }

      localStorage[table] = JSON.stringify({
        RefreshItem: RefreshItem,
        RefreshScanForward: RefreshScanForward,
        FirstItem: ordered_data[0],
        LastItem: ordered_data[ordered_data.length - 1],
        LastStart: data.start,
      });
    }

    let orders_total = order_search_enable ? result.total.value : result.orders_total;

    callback({
      draw: parseInt(data.draw),
      data: ordered_data,
      recordsTotal: parseInt(orders_total),
      recordsFiltered: parseInt(orders_total),
    });
  }

  // search orders with opersearch
  function searchOrdersOpenSearch(payload, openSearchParams) {
    baseApiAxios
      .getOrdersOpenSearch(payload, {}, openSearchParams)
      .then(process_order)
      .catch((error, text) => {
        if (order_search_enable && error.response.status === 500) {
          console.debug('opensearch return 500, retry search with dynamodb');
          console.debug('set order_search_enable to false');
          sessionStorage.setItem('order_search_enable', false);
          order_search_enable = false;

          addSpinner();
          addTableSpinner();
          searchOrderDynamoDB(payload);
        } else {
          displayErrorPopup(error, text);
        }
      })
      .finally(() => {
        removeSpinners();
      });
  }

  // search orders with dynamodb
  function searchOrderDynamoDB(payload) {
    baseApiAxios
      .getOrders(payload)
      .then(process_order)
      .catch(displayErrorPopup)
      .finally(() => {
        removeSpinners();
      });
  }

  const openSearchParamsFilters = data.columns
    .filter(col => col.data && col.search.value)
    .reduce((obj, col) => {
      // specially care for account_id and descriptions
      if (col.data === 'payload') {
        const objectKey = `payload.${col.name.replace(/_col$/, '')}`;
        obj[objectKey] = col.search.value;
      } else if (col.data === 'email_sent') {
        const emailSent = col.search.value === 'YES';
        obj[col.data] = emailSent;
      } else {
        obj[col.data] = col.search.value;
      }
      return obj;
    }, {});

  let openSearchParams = {
    size: data.length,
    from: data.start,
    filters: JSON.stringify(openSearchParamsFilters),
    sort: JSON.stringify({ creation_date: 'desc' }),
  };

  order_search_enable ? searchOrdersOpenSearch(payload, openSearchParams) : searchOrderDynamoDB(payload);
}

export function addOrderData(tableId, presetApproved, presetExecuted, presetEmailSent) {
  configureTableColumns(tableId, orderTableColumns);

  const dt = initDataTable(
    tableId,
    'lCrtpB',
    [
      {
        extend: 'excelHtml5',
        text: 'Export Excel',
        exportOptions: {
          columns: ':visible',
        },
        titleAttr: 'Export the visible columns as Excel file',
      },
      {
        extend: 'csvHtml5',
        text: 'Export CSV',
        exportOptions: {
          columns: ':visible',
        },
        titleAttr: 'Export the visible columns as CSV file',
      },
      {
        extend: 'copyHtml5',
        text: 'Copy',
        exportOptions: {
          columns: ':visible',
        },
        titleAttr: 'Copy the visible columns into your clipboard',
      },
      {
        extend: 'resetTable',
        ajaxReload: true,
        titleAttr: 'Reset all filters in the table footer',
      },
      {
        extend: 'reloadTable',
        text: 'Reload Orders',
        ajaxReload: true,
        titleAttr: 'Reload orders (no-cache)',
      },
    ],
    [
      {
        // Column 0
        visible: true,
        defaultContent: '',
        orderable: false,
        searchable: false,
        data: null,
        name: 'select_col',
        class: 'details-control',
      },
      {
        // Column 1
        visible: true,
        defaultContent: '',
        orderable: false,
        searchable: true,
        data: 'id',
        name: 'id_col',
        title: 'Id',
        createdCell: function (td) {
          addCopyButton(td);
          addLoadOrderDetailsButton(td);
          addLinkOrderIdButton(td);
        },
      },
      {
        // Column 2
        visible: true,
        defaultContent: '',
        orderable: false,
        searchable: true,
        data: 'creation_date',
        name: 'creation_date_col',
        title: 'Order Date',
        createdCell: addCopyButton,
      },
      {
        // Column 3
        visible: false,
        defaultContent: '',
        orderable: false,
        searchable: true,
        data: 'action',
        name: 'action_col',
        title: 'Action',
        createdCell: addCopyButton,
      },
      {
        // Column 4
        visible: true,
        defaultContent: '',
        orderable: false,
        searchable: true,
        data: 'payload',
        name: 'description_col',
        title: 'Order Description',
        createdCell: addCopyButton,
        className: 'td-limit',
        render: function (data) {
          if (data) {
            let payload_json;
            if (typeof data === 'string') {
              payload_json = JSON.parse(data.replace(/'/g, '"'));
            } else {
              payload_json = data;
            }

            let description_text = payload_json.description || payload_json.action;
            description_text = description_text
              .replace(linkRegexAccount, '<a href="?account_id=$1#accountdetails">$1</a>')
              .replace(linkRegexVPC, '<a href="?vpc_id=$1#networkdetails">$1</a>')
              .replace(linkRegexHostedZone, '<a href="?domain=$1#hostedzonedetails">$1</a>')
              .replace(linkRegex4Wheels, '<a href="?cluster_id=$1#4wheelsdetails">$1</a>');

            return description_text;
          } else {
            return 'no job description';
          }
        },
      },
      {
        // Column 5
        visible: true,
        defaultContent: '',
        orderable: false,
        searchable: true,
        data: 'payload',
        name: 'account_id_col',
        title: 'Account Id',
        createdCell: function (td) {
          addCopyButton(td);
          addLoadAccountDetailsButton(td);
        },
        render: function (data) {
          if (data) {
            let payload_json;
            if (typeof data === 'string') {
              payload_json = JSON.parse(data.replace(/'/g, '"'));
            } else {
              payload_json = data;
            }
            return payload_json.account_id || '-';
          } else {
            return '-';
          }
        },
      },
      {
        // Column 6
        visible: true,
        defaultContent: '',
        orderable: false,
        searchable: true,
        data: 'proposer',
        name: 'proposer_col',
        title: 'Proposer',
        createdCell: addCopyButton,
        // render: function (data, type, row) {
        //   var return_value = '<div class="td-limit-proposer">' + data + '</div>';
        //   return return_value;
        // },
      },
      {
        // Column 7
        visible: true,
        defaultContent: '',
        orderable: false,
        searchable: true,
        data: 'approved',
        name: 'approved_col',
        title: 'Approved',
        render: function (data) {
          if (data === '1' || data === 'APPROVED') {
            return 'APPROVED';
          } else if (data === '-1' || data === 'DECLINED') {
            return 'DECLINED';
          } else if (data === '0' || data === 'NEW') {
            return 'NEW';
          } else if (data === 'WAITING_FOR_APPROVAL') {
            return 'WAITING_FOR_APPROVAL';
          } else if (data === 'TIMEOUT') {
            return 'TIMEOUT';
          }
        },
        createdCell: function (td, cellData) {
          addCopyButton(td);

          if (cellData === '1' || cellData === 'APPROVED') {
            $(td).addClass('portal-success');
          } else if (cellData === '-1' || cellData === 'DECLINED') {
            $(td).addClass('portal-danger');
          } else if (cellData === '0' || cellData === 'NEW') {
            $(td).addClass('portal-neutral');
          } else if (cellData === 'WAITING_FOR_APPROVAL') {
            $(td).addClass('portal-neutral');
          } else if (cellData === 'TIMEOUT') {
            $(td).addClass('portal-danger');
          }
        },
      },
      {
        // Column 8
        visible: false,
        defaultContent: '',
        orderable: false,
        searchable: false,
        data: 'approval_date',
        name: 'approval_date_col',
        title: 'Approval Date',
        createdCell: addCopyButton,
      },
      {
        // Column 9
        visible: false,
        defaultContent: '',
        orderable: false,
        searchable: false,
        data: 'approver',
        name: 'approver_col',
        title: 'Approver',
        createdCell: addCopyButton,
      },
      {
        // Column 10
        visible: false,
        defaultContent: '',
        orderable: false,
        searchable: false,
        data: 'approval_comment',
        name: 'approval_comment_col',
        title: 'Approval Comment',
        createdCell: addCopyButton,
      },
      {
        // Column 11
        visible: true,
        defaultContent: '',
        orderable: false,
        searchable: true,
        data: 'executed',
        name: 'executed_col',
        title: 'Executed',
        render: function (data) {
          if (data === '1' || data === 'SUCCESS') {
            return 'SUCCESS';
          } else if (data === '2' || data === 'SUCCESS_NO_ACTIONS') {
            return 'SUCCESS_NO_ACTIONS';
          } else if (data === '-1' || data === 'FAILED') {
            return 'FAILED';
          } else if (data === '0' || data === 'NEW') {
            return 'NEW';
          } else if (data === '10' || data === 'IN PROGRESS' || data === 'in_progress' || data === 'IN_PROGRESS') {
            return 'IN PROGRESS';
          } else if (data === 'NOT_COMPLETED') {
            return 'NOT COMPLETED';
          } else {
            return encodeEntities(data);
          }
        },
        createdCell: function (td, cellData) {
          addCopyButton(td);

          if (cellData === '1' || cellData === '2' || cellData === 'SUCCESS_NO_ACTIONS' || cellData === 'SUCCESS') {
            $(td).addClass('portal-success');
          } else if (cellData === '-1' || cellData === 'FAILED' || cellData === 'NOT_COMPLETED') {
            $(td).addClass('portal-danger');
          } else if (cellData === '0' || cellData === 'NEW') {
            $(td).addClass('portal-neutral');
          } else if (
            cellData === '10' ||
            cellData === 'IN PROGRESS' ||
            cellData === 'in_progress' ||
            cellData === 'IN_PROGRESS'
          ) {
            $(td).addClass('portal-warning');
          } else {
            $(td).addClass('portal-neutral');
          }
        },
      },
      {
        // Column 12
        visible: false,
        defaultContent: '',
        orderable: false,
        searchable: false,
        data: 'execution_date',
        name: 'execution_date_col',
        title: 'Execution Date',
        createdCell: addCopyButton,
      },
      {
        // Column 13
        visible: false,
        defaultContent: '',
        orderable: false,
        searchable: false,
        data: 'execution_response',
        name: 'execution_response_col',
        title: 'Execution Response',
        createdCell: addCopyButton,
      },
      {
        // Column 14
        visible: false,
        defaultContent: '',
        orderable: false,
        searchable: true,
        data: 'email_sent',
        name: 'email_sent_col',
        title: 'Email Sent',
        render(data) {
          return data ? 'YES' : 'NO';
        },
        createdCell: function (td, cellData) {
          addCopyButton(td);

          if (cellData) {
            $(td).addClass('portal-success');
          } else {
            $(td).addClass('portal-neutral');
          }
        },
      },
    ],
    null,
    {
      serverSide: true,
      search: {
        regex: false,
      },
      sPaginationType: 'three_button',
      lengthMenu: [
        [10, 25, 50, 100],
        [10, 25, 50, 100],
      ],
      ajax: (data, callback) => loadIncrementsOrders(data, callback, tableId),
      initComplete: function () {
        const columnsState = this.api().state().columns;

        this.api().columns().search('').draw();

        this.api()
          .columns()
          .every(function (col) {
            const that = this;
            const column = this;
            const title = dt.column(col).header().innerHTML;

            if (orderDropdownColumns.indexOf(title) > -1) {
              const elementId = title.toLowerCase().replace(' ', '_');
              const select = $('<select id="' + elementId + '"><option value=""></option></select>')
                .appendTo($(column.footer()).empty())
                .on('change', function () {
                  column.search($(this).val()).draw();
                  if (getSearchParamsUrl(this.id) !== this.value)
                    overwriteSearchParamsUrl({ [this.id]: this.value.toLowerCase() });
                });

              if (footerDropdownSearchValues[title]) {
                footerDropdownSearchValues[title].forEach(function (data) {
                  let opt = document.createElement('option');
                  opt.innerText = data.innerText;
                  opt.value = data.value;
                  select.append(opt);
                });
              }
            }

            if (orderSearchColumns.indexOf(title) > -1) {
              $(dt.column(col).footer()).html('<input type="text" placeholder="Search ' + title + '" />');

              if (columnsState[col].search && columnsState[col].search.search) {
                dt.column(col).footer().lastChild.value = columnsState[col].search.search;
              }

              $('input', this.footer()).on('keyup change', function (event) {
                if (event.keyCode === 13) {
                  if (that.search() !== this.value) {
                    that.search(this.value).draw();
                  }
                }
              });
            }
          });

        if (presetApproved) {
          const approvedAllowedValues = footerDropdownSearchValues['Approved'].map(obj => obj.value);
          const searchText = presetApproved.toUpperCase();
          if (approvedAllowedValues.indexOf(searchText) > -1) {
            const column = this.api().columns(7);
            column.search(searchText).draw();
            const dropdown = $(column.footer()).find('select');
            dropdown.val(searchText);
          }
        }

        if (presetExecuted) {
          const executedAllowedValues = footerDropdownSearchValues['Executed'].map(obj => obj.value);
          const searchText = presetExecuted.toUpperCase();
          if (executedAllowedValues.indexOf(searchText) > -1) {
            const column = this.api().columns(11);
            column.search(searchText).draw();
            const dropdown = $(column.footer()).find('select');
            dropdown.val(searchText);
          }
        }

        if (presetEmailSent) {
          const emailSentAllowedValues = footerDropdownSearchValues['Email Sent'].map(obj => obj.value);
          const searchText = presetEmailSent.toUpperCase();
          if (emailSentAllowedValues.indexOf(searchText) > -1) {
            const column = this.api().columns(14);
            column.search(searchText).draw();
            const dropdown = $(column.footer()).find('select');
            dropdown.val(searchText);
          }
        }

        $(window).trigger('resize');
      },
    },
  );

  // Array to track the ids of the details displayed rows
  // var detailRows = [];

  $('#' + tableId + ' tbody').on('click', 'tr td.details-control', function () {
    const tr = $(this).closest('tr');
    const row = dt.row(tr);
    const idx = $.inArray(tr.attr('id'), detailRowsOrders);

    if (row.child.isShown()) {
      tr.removeClass('details');
      row.child.hide();

      // Remove from the 'open' array
      detailRowsOrders.splice(idx, 1);
    } else {
      tr.addClass('details');
      addRowLoadingAnimation(row);
      row.child(<OrderTabs data={row.data()} />).show();
      row.child()[0].setAttribute('class', 'rowDetails');
      // Add to the 'open' array
      if (idx === -1) {
        detailRowsOrders.push(tr.attr('id'));
      }
    }
  });
}
