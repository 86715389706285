import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Datatable } from './Datatable';
import { baseApiAxios } from './js/portal/api';
import { initNetworkStatisticsTable, showCidrChartModal, showNetworkChartModal } from './js/portal/networks';
import { addSpinner, removeSpinners } from './js/portal/sidebar';

const colorScheme = ['#2c3e50', '#198754', '#2980b9', '#c0392b', '#6c757d', '#9b59b6', '#ffc107'];

Chart.register(ChartDataLabels);

const tableId = 'table-vpc-statistics';

export default function NetworksUsagePage() {
  const page = (
    <>
      <div class="row mb-3">
        <div class="col">
          <div class="dashboard-title d-inline-block">
            <h3 class="overview" style="line-height:32px;margin-bottom:0;">
              Network Usage
            </h3>
          </div>
          <div id="network-usage-dashboard-status" class="d-inline-block"></div>
        </div>
        <div class="col-auto text-right">
          <span class="d-inline-block loading-form-inline">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
          </span>
          <span class="d-inline-block portal-dropdown mx-3">
            <select class="form-control form-select" id="chart-timeframe-select">
              <option disabled selected>
                No Data available
              </option>
            </select>
          </span>
          <span class="d-inline-block">
            <ul class="nav nav-pills chart-timeframe-switch">
              <li class="nav-item">
                <input type="radio" id="category-monthly" name="category" value="monthly" />
                <label class="nav-link" for="category-monthly">
                  Monthly
                </label>
              </li>
              <li class="nav-item">
                <input type="radio" id="category-daily" name="category" value="daily" />
                <label class="nav-link" for="category-daily">
                  Daily
                </label>
              </li>
            </ul>
          </span>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-4 mb-4">
          <div id="order-card" class="card dashboard-card">
            <div class="card-body dashboard-chart">
              <div class="position-relative">
                <h4 class="card-title mb-4">Number of VPCs per Region</h4>
                <i id="network-chart-modal-icon" class="fas fa-wrench chart-icon d-none link-icon" />
              </div>
              <canvas id="number-of-vpcs"></canvas>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-4 mb-4">
          <div id="order-card" class="card dashboard-card">
            <div class="card-body dashboard-chart d-flex flex-column">
              <div class="position-relative">
                <h4 class="card-title mb-4">Used IP Adresses per Region</h4>
                <i class="fas fa-info-circle chart-icon chart-info" />
                <div class="chart-icon chart-info-box alert alert-info" role="alert">
                  <p class="p-0 m-0">
                    This graph shows the percentage of assigned IP addresses in comparison with the number of available
                    IP addresses in each region.
                  </p>
                </div>
              </div>
              <div style="flex:1">
                <canvas id="used-ip-addresses"></canvas>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-4 mb-4">
          <div id="order-card" class="card dashboard-card">
            <div class="card-body dashboard-chart d-flex flex-column">
              <h4 class="card-title mb-4">VPC Size Statistics</h4>
              <div class="position-relative">
                <canvas id="vpc-size-statistics"></canvas>
                <span id="vpc-size-statistics-label" class="chart-donut-label">
                  VPCs by Size
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-4 mb-4">
          <div id="order-card" class="card dashboard-card">
            <div class="card-body dashboard-chart d-flex flex-column">
              <h4 class="card-title mb-4">Network Type Statistics</h4>
              <div class="position-relative" style="flex:1">
                <canvas id="network-type-statistics"></canvas>
                <span id="network-type-statistics-label" class="chart-donut-label">
                  Networks by Type
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-4 mb-4">
          <div id="order-card" class="card dashboard-card">
            <div class="card-body dashboard-chart d-flex flex-column">
              <div class="position-relative">
                <h4 class="card-title mb-4">VPCs by Type and Region</h4>
                <i class="fas fa-info-circle chart-icon chart-info" />
                <div class="chart-icon chart-info-box alert alert-info" role="alert">
                  <p class="p-0 m-0">
                    This graph shows the number of VPCs per region by network type. Each level of the multi donut chart
                    represents a network type. Hover over the arcs to get more information.
                  </p>
                </div>
              </div>
              <div class="position-relative" style="flex:1">
                <canvas id="regions-by-type"></canvas>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-4 mb-4">
          <div id="order-card" class="card dashboard-card">
            <div class="card-body dashboard-chart">
              <div class="position-relative">
                <h4 class="card-title mb-4">Free CIDRs by Region</h4>
                <i id="cidr-chart-modal-icon" class="fas fa-wrench chart-icon d-none link-icon" />
              </div>
              <canvas id="free-cidr-statistics"></canvas>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-4">
          <div id="order-card" class="card dashboard-card">
            <div class="card-body">
              <h4 class="card-title mb-5">VPC Information</h4>
              <div class="px-3 mb-4">
                <Datatable tableId={tableId} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  function getColors(data) {
    return colorScheme.slice(0, data.length);
  }

  function sortLoadedData(apiResponse) {
    let defaultYear = 0;
    let defaultMonth = 0;
    let defaultTimeframe;
    let monthlyObject = {};
    let dailyObject = {};
    apiResponse.stats.forEach(data => {
      if (data.timeframe === 'monthly') {
        monthlyObject[data.sk] = data.data;
        if (data.year > defaultYear && data.month > defaultMonth) {
          defaultYear = data.year;
          defaultMonth = data.month;
          defaultTimeframe = data.sk;
        }
      } else if (data.timeframe === 'daily') {
        dailyObject[data.sk] = data.data;
      }
    });
    return [monthlyObject, dailyObject, defaultTimeframe];
  }

  function chartTicksFormatter(value) {
    var ranges = [
      { divider: 1e6, suffix: 'M' },
      { divider: 1e3, suffix: 'K' },
    ];
    function formatNumber(n) {
      if ([0, 1, 100].indexOf(n) !== -1) return n;
      for (var i = 0; i < ranges.length; i++) {
        if (n >= ranges[i].divider) {
          const value = n / ranges[i].divider;
          if (value === 1 || value % 10 === 0) return value.toString() + ranges[i].suffix;
        }
      }
    }
    return formatNumber(value);
  }

  function onPageReady() {
    const timeframeSelect = document.getElementById('chart-timeframe-select');

    let savedUsageApiResponse = sessionStorage.getItem('network-usage-api-response')
      ? JSON.parse(sessionStorage.getItem('network-usage-api-response'))
      : {};
    let savedTypeApiResponse = sessionStorage.getItem('network-type-api-response')
      ? JSON.parse(sessionStorage.getItem('network-type-api-response'))
      : {};
    let savedCidrApiResponse = sessionStorage.getItem('network-cidr-api-response')
      ? JSON.parse(sessionStorage.getItem('network-cidr-api-response'))
      : {};
    let savedDropdownSelection = sessionStorage.getItem('network-usage-dropdown')
      ? JSON.parse(sessionStorage.getItem('network-usage-dropdown'))
      : {};

    // TIMEFRAME CONTROLS

    const setTimeframeSelectOptions = optionsObject => {
      let optionsHtml = '';
      Object.entries(optionsObject).forEach(([option, type], index) => {
        optionsHtml += `<option value="${option}" ${index == 0 ? ' selected' : ''}>${option}${
          type !== 'default' ? ` (${type})` : ''
        }</option>`;
      });
      if (!optionsHtml) optionsHtml = '<option value="no-data-available" disabled selected>No Data available</option>';
      timeframeSelect.innerHTML = optionsHtml;
    };

    const selectTimeframeCategory = selection => {
      document.querySelector(`.chart-timeframe-switch input[value="${selection}"]`).checked = true;
      selectSavedTimeframes(selection);
    };

    const selectSavedTimeframes = category => {
      const usageApiKeys = Object.keys(savedUsageApiResponse[category] || {});
      const typeApiKeys = Object.keys(savedTypeApiResponse[category] || {});
      const cidrApiKeys = Object.keys(savedCidrApiResponse[category] || {});
      const uniqueKeys = [...new Set([...usageApiKeys, ...typeApiKeys, ...cidrApiKeys])];
      let dropdownOptions = {};
      uniqueKeys.forEach(key => {
        dropdownOptions[key] =
          usageApiKeys.length !== 0 &&
          usageApiKeys.indexOf(key) !== -1 &&
          typeApiKeys.length !== 0 &&
          typeApiKeys.indexOf(key) !== -1 &&
          cidrApiKeys.length !== 0 &&
          cidrApiKeys.indexOf(key) !== -1
            ? 'default'
            : 'partial';
      });
      setTimeframeSelectOptions(dropdownOptions);
    };

    const timeframeCategories = document.querySelectorAll('input[name="category"]');
    timeframeCategories.forEach(element => {
      element.addEventListener('change', evt => {
        selectSavedTimeframes(evt.target.value);
        savedDropdownSelection = JSON.parse(sessionStorage.getItem('network-usage-dropdown') || '{}');
        if (evt.target.value === savedDropdownSelection.category) {
          timeframeSelect.value = savedDropdownSelection.timeframe;
        } else {
          if (timeframeSelect.value !== 'no-data-available') timeframeSelect.dispatchEvent(new Event('change'));
        }
      });
    });

    const getDropdownSelection = () => {
      const selectedCategory = document.querySelector(`.chart-timeframe-switch input[name="category"]:checked`)?.value;
      const selectedTimeframe = timeframeSelect.value;
      return [selectedCategory, selectedTimeframe];
    };

    timeframeSelect.addEventListener('change', () => {
      const [selectedCategory, selectedTimeframe] = getDropdownSelection();
      savedDropdownSelection = {
        category: selectedCategory,
        timeframe: selectedTimeframe,
      };
      sessionStorage.setItem('network-usage-dropdown', JSON.stringify(savedDropdownSelection));
      loadUsageData();
      loadTypeData();
      loadCidrData();
    });

    if (savedDropdownSelection['category']) {
      selectTimeframeCategory(savedDropdownSelection['category']);
      selectSavedTimeframes(savedDropdownSelection['category']);
      timeframeSelect.value = savedDropdownSelection['timeframe'];
    }

    // CHARTS

    let numberOfVpcsData = [0];
    let numberOfVpcsLabels = [''];

    let usedIpAddressesData = [0];
    let usedIpAddressesLabels = [''];

    let vpcSizeStatisticsData = [10];
    let vpcSizeStatisticsLabels = ['loading'];

    let networkTypeStatisticsData = [10];
    let networkTypeStatisticsLabels = ['loading'];

    let regionsByTypeData = [[10], [10], [10], [10]];
    let regionsByTypeLabels = ['loading'];
    let regionsByTypeDatasetLabels = ['loading', 'loading', 'loading', 'loading'];

    let freeCidrStatisticsData = [[0], [0], [0], [0]];
    let freeCidrStatisticsLabels = [''];
    let freeCidrStatisticsDatasetLabels = ['', '', '', ''];

    let numberOfVpcsThreshold = 1000;
    const numberOfVpcs = new Chart(document.getElementById('number-of-vpcs'), {
      type: 'bar',
      data: {
        labels: numberOfVpcsLabels,
        datasets: [
          {
            minBarLength: 3,
            data: numberOfVpcsData,
            backgroundColor: getColors(numberOfVpcsData),
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        onResize: (chart, _size) => {
          chart.resize();
        },
        plugins: {
          datalabels: {
            align: 'end',
            anchor: 'end',
            color: function (context) {
              return context.dataset.backgroundColor;
            },
            font: {
              size: 10,
              weight: 'bold',
            },
            formatter: function (_value, context) {
              return context.chart.data.labels[context.dataIndex];
            },
            rotation: 270,
          },
          legend: {
            display: false,
          },
        },
        scales: {
          y: {
            type: 'logarithmic',
            suggestedMax: numberOfVpcsThreshold,
            beginAtZero: true,
            ticks: {
              callback: chartTicksFormatter,
            },
          },
          x: { display: false },
        },
      },
    });

    let usedIpAddressesThreshold = 10;
    const usedIpAddresses = new Chart(document.getElementById('used-ip-addresses'), {
      type: 'bar',
      data: {
        labels: usedIpAddressesLabels,
        datasets: [
          {
            minBarLength: 3,
            data: usedIpAddressesData,
            backgroundColor: getColors(usedIpAddressesData),
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        onResize: (chart, _size) => {
          chart.resize();
        },
        indexAxis: 'y',
        plugins: {
          datalabels: {
            align: 'end',
            anchor: 'end',
            color: function (context) {
              return context.dataset.backgroundColor;
            },
            font: {
              size: 10,
              weight: 'bold',
            },
            formatter: function (_value, context) {
              return context.chart.data.labels[context.dataIndex];
            },
          },
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                return context.parsed.x.toFixed(2) + '%';
              },
            },
          },
        },
        scales: {
          y: {
            display: false,
            beginAtZero: true,
            grid: { display: false },
          },
          x: {
            suggestedMax: usedIpAddressesThreshold,
            grid: { display: true },
            ticks: {
              callback: function (value) {
                return value + '%';
              },
            },
          },
        },
      },
    });

    let vpcSizeStatisticsThreshold = 1;
    const vpcSizeStatisticsTitle = document.getElementById('vpc-size-statistics-label');
    const vpcSizeStatistics = new Chart(document.getElementById('vpc-size-statistics'), {
      type: 'doughnut',
      data: {
        labels: vpcSizeStatisticsLabels,
        datasets: [
          {
            data: vpcSizeStatisticsData,
            backgroundColor: getColors(vpcSizeStatisticsData),
          },
        ],
      },
      options: {
        cutout: '60%',
        onHover: (evt, activeElements) => {
          if (activeElements.length > 0) {
            let index = activeElements[0].index;
            let label = evt.chart.data.labels[index];
            let value = evt.chart.data.datasets[0].data[index];
            vpcSizeStatisticsTitle.innerHTML = `VPC Size /${label}<br/>${value}`;
          } else {
            vpcSizeStatisticsTitle.innerHTML = `VPCs by Size`;
          }
        },
        plugins: {
          datalabels: {
            color: 'white',
            font: {
              size: 10,
              weight: 'bold',
            },
            padding: 6,
            display: function (context) {
              return context.dataset.data[context.dataIndex] > vpcSizeStatisticsThreshold;
            },
            formatter: function (_value, context) {
              return `/${context.chart.data.labels[context.dataIndex]}`;
            },
          },
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
        },
      },
    });

    let networkTypeStatisticsThreshold = 1;
    const networkTypeStatisticsTitle = document.getElementById('network-type-statistics-label');
    const networkTypeStatistics = new Chart(document.getElementById('network-type-statistics'), {
      type: 'doughnut',
      data: {
        labels: networkTypeStatisticsLabels,
        datasets: [
          {
            data: networkTypeStatisticsData,
            backgroundColor: getColors(networkTypeStatisticsData),
          },
        ],
      },
      options: {
        cutout: '60%',
        onHover: (evt, activeElements) => {
          if (activeElements.length > 0) {
            let index = activeElements[0].index;
            let label = evt.chart.data.labels[index];
            let value = evt.chart.data.datasets[0].data[index];
            let color = evt.chart.data.datasets[0].backgroundColor[index];
            networkTypeStatisticsTitle.innerHTML = `Type <span style='color:${color}'>${label}</span><br/>${value}`;
          } else {
            networkTypeStatisticsTitle.innerHTML = `Networks by Type`;
          }
        },
        plugins: {
          datalabels: {
            color: 'white',
            font: {
              size: 10,
              weight: 'bold',
            },
            padding: 6,
            display: function (context) {
              return context.dataset.data[context.dataIndex] > networkTypeStatisticsThreshold;
            },
            formatter: function (_value, context) {
              return context.chart.data.labels[context.dataIndex];
            },
          },
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
        },
      },
    });

    let regionsByTypeThreshold = [[1], [1], [1], [1]];
    const regionsByType = new Chart(document.getElementById('regions-by-type'), {
      type: 'doughnut',
      data: {
        labels: regionsByTypeLabels,
        datasets: regionsByTypeData.map((_value, index) => {
          return {
            label: regionsByTypeDatasetLabels[index],
            data: regionsByTypeData[index],
            backgroundColor: getColors(regionsByTypeData[index]),
          };
        }),
      },
      options: {
        cutout: '10%',
        plugins: {
          datalabels: {
            color: 'white',
            font: {
              size: 10,
              weight: 'bold',
            },
            padding: 6,
            display: function (context) {
              return context.dataset.data[context.dataIndex] > regionsByTypeThreshold[context.datasetIndex];
            },
          },
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                return [
                  context.dataset.label,
                  context.chart.data.labels[context.dataIndex],
                  context.dataset.data[context.dataIndex],
                ];
              },
            },
            bodySpacing: 4,
          },
        },
      },
    });

    let freeCidrStatisticsThreshold = [[10], [10], [10], [10]];
    const freeCidrStatistics = new Chart(document.getElementById('free-cidr-statistics'), {
      type: 'bar',
      data: {
        labels: freeCidrStatisticsLabels,
        datasets: freeCidrStatisticsData.map((_value, index) => {
          return {
            minBarLength: 2,
            label: freeCidrStatisticsDatasetLabels[index],
            data: freeCidrStatisticsData[index],
            backgroundColor: Array(freeCidrStatisticsData.length).fill(colorScheme[0]),
          };
        }),
      },
      options: {
        maintainAspectRatio: false,
        onResize: (chart, _size) => {
          chart.resize();
        },
        plugins: {
          datalabels: {
            align: 'end',
            anchor: 'end',
            font: {
              size: 10,
              weight: 'bold',
            },
            formatter: function (_value, context) {
              return context.chart.data.datasets.length - 1 === context.datasetIndex
                ? context.chart.data.labels[context.dataIndex]
                : '';
            },
            rotation: 270,
          },
          legend: {
            display: false,
          },
        },
        scales: {
          x: {
            display: false,
            stacked: true,
          },
          y: {
            stacked: true,
            suggestedMax: freeCidrStatisticsThreshold,
          },
        },
      },
    });

    // LOAD AND DISPLAY DATA

    const loadUsageData = () => {
      const [selectedCategory, selectedTimeframe] = getDropdownSelection();

      if (savedUsageApiResponse && Object.keys(savedUsageApiResponse).length !== 0) {
        displaySelectedUsageData(savedUsageApiResponse[selectedCategory][selectedTimeframe]);
        displayTableData(savedUsageApiResponse[selectedCategory][selectedTimeframe]);
        return;
      }

      addSpinner();
      document.querySelector('.loading-form-inline')?.classList.add('loading-animation');

      baseApiAxios.get('/stats/categories/vpc/statistics/usage', null, {}).then(result => {
        const [monthlyObject, dailyObject, defaultTimeframe] = sortLoadedData(result);
        const selectedData = monthlyObject[defaultTimeframe];

        savedUsageApiResponse = {
          monthly: monthlyObject,
          daily: dailyObject,
        };
        sessionStorage.setItem('network-usage-api-response', JSON.stringify(savedUsageApiResponse));

        selectTimeframeCategory('monthly');
        selectSavedTimeframes('monthly');
        timeframeSelect.value = defaultTimeframe;

        displaySelectedUsageData(selectedData);
        displayTableData(selectedData);
      });
    };

    const displaySelectedUsageData = selectedData => {
      if (!selectedData || Object.keys(selectedData).length === 0) {
        vpcSizeStatisticsTitle.style.display = 'none';
        numberOfVpcsData = [0];
        numberOfVpcsLabels = [''];
        vpcSizeStatisticsThreshold = 1;

        usedIpAddressesData = [0];
        usedIpAddressesLabels = [''];
        numberOfVpcsThreshold = 1;

        vpcSizeStatisticsData = [0];
        vpcSizeStatisticsLabels = [''];
        usedIpAddressesThreshold = 1;
      } else {
        vpcSizeStatisticsTitle.style.display = '';
        let dataByRegion = {};
        vpcSizeStatisticsData = [];
        vpcSizeStatisticsLabels = [];
        Object.entries(selectedData).forEach(([label, value]) => {
          if (label === 'count') {
            vpcSizeStatisticsThreshold = value * 0.03;
            return;
          }
          vpcSizeStatisticsData.push(value.count);
          vpcSizeStatisticsLabels.push(label);
          Object.keys(value).forEach(region => {
            if (region !== 'count') {
              if (!(region in dataByRegion)) {
                dataByRegion[region] = { count: 0, used: 0, available: 0 };
              }
              dataByRegion[region]['count'] += value[region]['count'];
              dataByRegion[region]['used'] += value[region]['ips_used'];
              dataByRegion[region]['available'] += value[region]['ips_number'];
            }
          });
        });

        numberOfVpcsData = [];
        usedIpAddressesData = [];
        numberOfVpcsLabels = Object.keys(dataByRegion).map(region => {
          numberOfVpcsData.push(dataByRegion[region]['count']);
          usedIpAddressesData.push((dataByRegion[region]['used'] / dataByRegion[region]['available']) * 100);
          return region;
        });

        numberOfVpcsThreshold = Math.max(...numberOfVpcsData) * 7;

        usedIpAddressesLabels = numberOfVpcsLabels;

        usedIpAddressesThreshold = Math.max(...usedIpAddressesData) * 1.1;
      }

      vpcSizeStatistics.data.labels = vpcSizeStatisticsLabels;
      vpcSizeStatistics.data.datasets = [
        {
          data: vpcSizeStatisticsData,
          backgroundColor: getColors(vpcSizeStatisticsData),
        },
      ];
      vpcSizeStatistics.options.plugins.datalabels.display = function (context) {
        return context.dataset.data[context.dataIndex] > vpcSizeStatisticsThreshold;
      };
      vpcSizeStatistics.update();

      numberOfVpcs.data.labels = numberOfVpcsLabels;
      numberOfVpcs.data.datasets = [
        {
          data: numberOfVpcsData,
          backgroundColor: getColors(numberOfVpcsData),
        },
      ];
      numberOfVpcs.options.scales.x = { display: false };
      numberOfVpcs.options.scales.y = {
        type: 'logarithmic',
        suggestedMax: numberOfVpcsThreshold,
        beginAtZero: true,
        ticks: {
          callback: chartTicksFormatter,
        },
      };
      numberOfVpcs.update();

      usedIpAddresses.data.labels = usedIpAddressesLabels;
      usedIpAddresses.data.datasets = [
        {
          data: usedIpAddressesData,
          backgroundColor: getColors(usedIpAddressesData),
        },
      ];
      usedIpAddresses.options.scales.x.suggestedMax = usedIpAddressesThreshold;
      usedIpAddresses.update();

      removeSpinners();
    };

    const loadTypeData = () => {
      const [selectedCategory, selectedTimeframe] = getDropdownSelection();

      if (savedTypeApiResponse && Object.keys(savedTypeApiResponse).length !== 0) {
        displaySelectedTypeData(savedTypeApiResponse[selectedCategory][selectedTimeframe]);
        return;
      }

      addSpinner();
      document.querySelector('.loading-form-inline')?.classList.add('loading-animation');

      baseApiAxios.get('/stats/categories/vpc/statistics/network_type', null, {}).then(result => {
        const [monthlyObject, dailyObject, defaultTimeframe] = sortLoadedData(result);
        const selectedData = monthlyObject[defaultTimeframe];

        savedTypeApiResponse = {
          monthly: monthlyObject,
          daily: dailyObject,
        };
        sessionStorage.setItem('network-type-api-response', JSON.stringify(savedTypeApiResponse));

        savedDropdownSelection = {
          category: 'monthly',
          timeframe: defaultTimeframe,
        };
        sessionStorage.setItem('network-usage-dropdown', JSON.stringify(savedDropdownSelection));

        selectTimeframeCategory('monthly');
        selectSavedTimeframes('monthly');
        timeframeSelect.value = defaultTimeframe;

        displaySelectedTypeData(selectedData);
      });
    };

    const displaySelectedTypeData = selectedData => {
      if (!selectedData || Object.keys(selectedData).length === 0) {
        networkTypeStatisticsTitle.style.display = 'none';
        networkTypeStatisticsData = [0];
        networkTypeStatisticsLabels = [''];
        vpcSizeStatisticsThreshold = 1;

        regionsByTypeData = [[0]];
        regionsByTypeLabels = [''];
        regionsByTypeDatasetLabels = [''];
        regionsByTypeThreshold = [[0]];
      } else {
        networkTypeStatisticsTitle.style.display = '';
        let networkTypeObject = {};
        let regionsObject = {};
        networkTypeStatisticsData = [];
        networkTypeStatisticsLabels = [];
        Object.entries(selectedData).forEach(([label, value]) => {
          if (label === 'count') {
            networkTypeStatisticsThreshold = value * 0.03;
            return;
          }
          networkTypeStatisticsData.push(value.count);
          networkTypeStatisticsLabels.push(label);
          networkTypeObject[label] = value;

          Object.keys(value).forEach(key => {
            if (key === 'count' || key in regionsObject) return;
            regionsObject[key] = true;
          });
        });

        regionsByTypeData = [];
        regionsByTypeLabels = Object.keys(regionsObject);

        const regionLabelPositions = regionsByTypeLabels.reduce(function (result, item, index, _array) {
          result[item] = index;
          return result;
        }, {});

        regionsByTypeDatasetLabels = [];

        Object.entries(networkTypeObject).forEach(([type, data]) => {
          regionsByTypeDatasetLabels.push(type);
          let valuesArray = Array(regionsByTypeLabels.length).fill(0);
          Object.entries(data).forEach(([region, dataset]) => {
            valuesArray[regionLabelPositions[region]] = dataset.count;
          });
          regionsByTypeData.push(valuesArray);
        });
      }

      networkTypeStatistics.data.labels = networkTypeStatisticsLabels;
      networkTypeStatistics.data.datasets = [
        {
          data: networkTypeStatisticsData,
          backgroundColor: getColors(networkTypeStatisticsData),
        },
      ];
      networkTypeStatistics.options.plugins.datalabels.display = function (context) {
        return context.dataset.data[context.dataIndex] > vpcSizeStatisticsThreshold;
      };
      networkTypeStatistics.update();

      regionsByTypeThreshold = regionsByTypeData.map(values => values.reduce((a, b) => a + b, 0) * 0.03);
      regionsByType.data.labels = regionsByTypeLabels;
      regionsByType.data.datasets = regionsByTypeData.map((typeData, index) => {
        return {
          label: regionsByTypeDatasetLabels[index],
          data: typeData,
          backgroundColor: getColors(typeData),
        };
      });
      regionsByType.options.plugins.datalabels.display = function (context) {
        return context.dataset.data[context.dataIndex] > regionsByTypeThreshold[context.datasetIndex];
      };
      regionsByType.update();

      removeSpinners();
    };

    const displayTableData = selectedData => {
      let sizes = [];
      let tableData = [];
      if (selectedData && Object.keys(selectedData).length !== 0) {
        sizes = Object.keys(selectedData).filter(key => key !== 'count');

        let ipsByRegion = {};

        const addToRegion = (region, size, count, usedIps, availableIps) => {
          if (region in ipsByRegion) {
            ipsByRegion[region]['count'] += count;
            if (size in ipsByRegion[region]) {
              ipsByRegion[region][`size_${size}`] += count;
            } else {
              ipsByRegion[region][`size_${size}`] = count;
            }
          } else {
            ipsByRegion[region] = {
              count,
              used_ips: 0,
              available_ips: 0,
            };
          }
          ipsByRegion[region]['used_ips'] += usedIps;
          ipsByRegion[region]['available_ips'] += availableIps;
        };

        sizes.forEach(size => {
          Object.entries(selectedData[size]).forEach(([key, values]) => {
            addToRegion(key, size, values.count, values.ips_used, values.ips_number);
          });
        });

        tableData = Object.entries(ipsByRegion)
          .map(([key, dataset]) => {
            if (key === 'count') return null;
            return {
              region: key,
              range_usage: (dataset.used_ips / dataset.available_ips) * 100,
              ...dataset,
            };
          })
          .filter(elem => elem !== null);
      }

      initNetworkStatisticsTable(tableId, sizes, tableData);
    };

    const loadCidrData = () => {
      const [selectedCategory, selectedTimeframe] = getDropdownSelection();

      if (savedCidrApiResponse && Object.keys(savedCidrApiResponse).length !== 0) {
        displaySelectedCidrData(savedCidrApiResponse[selectedCategory][selectedTimeframe]);
        return;
      }

      addSpinner();
      document.querySelector('.loading-form-inline')?.classList.add('loading-animation');

      baseApiAxios.get('/stats/categories/cidr/statistics/free_cidrs', null, {}).then(result => {
        const [monthlyObject, dailyObject, defaultTimeframe] = sortLoadedData(result);
        const selectedData = monthlyObject[defaultTimeframe];

        savedCidrApiResponse = {
          monthly: monthlyObject,
          daily: dailyObject,
        };
        sessionStorage.setItem('network-cidr-api-response', JSON.stringify(savedCidrApiResponse));

        selectTimeframeCategory('monthly');
        selectSavedTimeframes('monthly');
        timeframeSelect.value = defaultTimeframe;

        displaySelectedCidrData(selectedData);
      });
    };

    const displaySelectedCidrData = selectedData => {
      if (!selectedData || Object.keys(selectedData).length === 0) {
        freeCidrStatisticsData = [[0], [0], [0], [0]];
        freeCidrStatisticsLabels = [''];
        freeCidrStatisticsDatasetLabels = ['', '', '', ''];
        freeCidrStatisticsThreshold = 1;
      } else {
        freeCidrStatisticsData = [];
        freeCidrStatisticsLabels = [];
        freeCidrStatisticsDatasetLabels = [];

        let valuesObject = {};
        let sumByRegion = {};

        Object.entries(selectedData).forEach(([region, values]) => {
          freeCidrStatisticsLabels.push(region);
          if (!(region in sumByRegion)) sumByRegion[region] = 0;

          Object.entries(values).forEach(([size, count]) => {
            if (!(size in valuesObject)) valuesObject[size] = {};
            valuesObject[size][region] = count;
            sumByRegion[region] += count;
          });
        });

        freeCidrStatisticsDatasetLabels = Object.keys(valuesObject);

        const regionLabelPositions = freeCidrStatisticsLabels.reduce(function (result, item, index, _array) {
          result[item] = index;
          return result;
        }, {});

        Object.entries(valuesObject).forEach(([_size, data]) => {
          let valuesArray = Array(freeCidrStatisticsLabels.length).fill(0);
          Object.entries(data).forEach(([region, count]) => {
            valuesArray[regionLabelPositions[region]] = count;
          });
          freeCidrStatisticsData.push(valuesArray);
        });

        freeCidrStatisticsThreshold = Math.max.apply(null, Object.values(sumByRegion)) * 1.15;
      }

      freeCidrStatistics.data.labels = freeCidrStatisticsLabels;
      freeCidrStatistics.data.datasets = freeCidrStatisticsData.map((cidrData, index) => {
        return {
          minBarLength: 2,
          label: freeCidrStatisticsDatasetLabels[index],
          data: cidrData,
          backgroundColor: Array(cidrData.length).fill(colorScheme[index]),
        };
      });
      freeCidrStatistics.options.scales.y.suggestedMax = freeCidrStatisticsThreshold;
      freeCidrStatistics.update();

      removeSpinners();
    };

    // LOAD INITIAL DATA

    loadUsageData();
    loadTypeData();
    loadCidrData();

    // SETTINGS MODALS

    const resetSumOfTypesChart = savedUsageData => {
      const yScaleMax = 0;
      numberOfVpcs.options.plugins.datalabels.formatter = function (_value, context) {
        return context.chart.data.labels[context.dataIndex];
      };
      numberOfVpcs.options.plugins.datalabels.color = function (context) {
        return context.dataset.backgroundColor;
      };
      numberOfVpcs.options.scales.x = { display: false };
      numberOfVpcs.options.scales.y = {
        type: 'logarithmic',
        suggestedMax: yScaleMax,
        beginAtZero: true,
        ticks: {
          callback: chartTicksFormatter,
        },
      };
      numberOfVpcs.data.labels = [];
      numberOfVpcs.data.datasets = [
        {
          data: [],
        },
      ];
      numberOfVpcs.update();
      displaySelectedUsageData(savedUsageData);
    };

    const resetSelectedTypesChart = (savedTypeData, selectedDatasetLabels) => {
      let regionLabelsObject = {};
      let sumOfVpcs = {};
      Object.entries(savedTypeData).forEach(([type, data]) => {
        Object.entries(data).forEach(([key, values]) => {
          if (key !== 'count') {
            if (selectedDatasetLabels.indexOf(type) !== -1) {
              regionLabelsObject[key] = true;
              sumOfVpcs[key] = key in sumOfVpcs ? sumOfVpcs[key] + values.count : values.count;
            }
          }
        });
      });

      const selectedRegionLabels = Object.keys(regionLabelsObject);
      let datasets = [];
      const regionLabelPositions = selectedRegionLabels.reduce(function (result, item, index, _array) {
        result[item] = index;
        return result;
      }, {});

      selectedDatasetLabels.forEach(type => {
        let valuesArray = Array(selectedRegionLabels.length).fill(0);
        Object.entries(savedTypeData[type]).forEach(([key, values]) => {
          if (key !== 'count') valuesArray[regionLabelPositions[key]] = values.count;
        });
        datasets.push(valuesArray);
      });

      const yScaleMax = Math.max(...Object.values(sumOfVpcs)) * 1.15;

      numberOfVpcs.options.plugins.datalabels.formatter = function (_value, context) {
        return context.chart.data.datasets.length - 1 === context.datasetIndex
          ? context.chart.data.labels[context.dataIndex]
          : '';
      };
      numberOfVpcs.options.plugins.datalabels.color = function (_context) {
        return colorScheme[0];
      };
      numberOfVpcs.options.scales.x = {
        stacked: true,
        display: false,
      };
      numberOfVpcs.options.scales.y = {
        stacked: true,
        suggestedMax: yScaleMax,
      };
      numberOfVpcs.data.labels = selectedRegionLabels;
      numberOfVpcs.data.datasets = datasets.map((values, index) => {
        return {
          minBarLength: 2,
          label: selectedDatasetLabels[index],
          data: values,
          backgroundColor: Array(datasets.length).fill(colorScheme[index]),
        };
      });

      numberOfVpcs.update();
    };

    const resetFreeCidrsChart = (savedData, selectedRegionLabels, selectedSizeLabels) => {
      let updatedData = {};
      Object.entries(savedData).forEach(([region, values]) => {
        if (selectedRegionLabels.indexOf(region) !== -1) {
          updatedData[region] = {};
          Object.entries(values).forEach(([size, freeCidrCount]) => {
            if (selectedSizeLabels.indexOf(size) !== -1) updatedData[region][size] = freeCidrCount;
          });
        }
      });
      displaySelectedCidrData(updatedData);
    };

    const networkChartDataIcon = document.getElementById('network-chart-modal-icon');
    networkChartDataIcon.classList.remove('d-none');
    networkChartDataIcon.addEventListener('click', () => {
      const [selectedCategory, selectedTimeframe] = getDropdownSelection();
      showNetworkChartModal(
        resetSumOfTypesChart,
        savedUsageApiResponse[selectedCategory][selectedTimeframe],
        resetSelectedTypesChart,
        savedTypeApiResponse[selectedCategory][selectedTimeframe],
      );
    });

    const cidrChartDataIcon = document.getElementById('cidr-chart-modal-icon');
    cidrChartDataIcon.classList.remove('d-none');
    cidrChartDataIcon.addEventListener('click', () => {
      const [selectedCategory, selectedTimeframe] = getDropdownSelection();
      const savedData = savedCidrApiResponse[selectedCategory][selectedTimeframe];
      showCidrChartModal(resetFreeCidrsChart, savedData);
    });
  }

  return [page, onPageReady];
}
