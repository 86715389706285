import { baseApiAxios, showError } from '../../../js/portal/api';
import { getSearchParamsUrl, setSearchParamsUrl } from '../../../js/portal/search_params';

/**
 * Creates a new selectpicker dropdown including a label and a refresh button.
 *
 * @param {string} label - name label on the left side of the dropdown menu
 * @param {string} id - the id of the selectpicker
 * @param {function} onchange - a function that will be triggered once the selectpicker value changes
 * @param {function} onrefresh - function that is triggered when the refresh button is clicked
 * @param {type[]} selectedValues - a list of Clusters that should be selected if available
 * @param {boolean} deselect - defines if the deselect Clusters button is added to the end of the selectpicker
 * @param {boolean} multiple - defines if you can select one or multiple items in the CLuster dropdown
 * @param {boolean} listAll - display all resources that the user has permission to see/edit
 * @param {boolean} disableUrlParams - disable setting and reading URL parameters
 *
 * @returns div including a label, the dropdown and a refresh button
 */
export default function ClusterDropdown({
  label,
  id,
  onchange,
  onrefresh,
  selectedValues,
  deselect,
  multiple,
  listAll,
  disableUrlParams,
}) {
  const labelText = label ? label : 'Cluster Id';
  const selectId = id ? id : 'cluster-id';
  const headers = { 'Cache-Control': 'max-age=0, must-revalidate' };
  const params = {};
  const multiSelect = multiple ? true : false;
  const listAllResources = listAll ? listAll : false;
  const urlParameters = !disableUrlParams ? true : false;

  // selectpicker options: https://developer.snapappointments.com/bootstrap-select/options/
  const select = (
    <select
      required
      class="form-control form-select selectpicker"
      data-live-search="true"
      data-show-subtext="true"
      id={selectId}
      name={selectId}
      data-size="10"
      data-max-options="5"
      data-none-selected-text="- Select one or more Clusters -"
      data-selected-text-format="count > 1"
      data-count-selected-text="{0} Clusters selected"
      data-dropdown-align-right="auto"
      multiple={multiSelect}>
      <option class="defaultDisabled" value="" disabled selected={!multiSelect}>
        - Select a Cluster -
      </option>
    </select>
  );

  // Refresh button to reload the Cluster data
  const refreshIcon = <i class="fas fa-sync" />;
  const refreshButton = (
    <button
      type="button"
      class="btn btn-light-grey input-group-btn portal-selectpicker-end"
      id="refresh-hosted-zones"
      title="Reload Clusters">
      {refreshIcon}
    </button>
  );

  // De-select button
  const deselectButton = (
    <button
      type="button"
      class="btn btn-light-grey input-group-btn portal-selectpicker-mid"
      id="deselect-clusters"
      title="De-select all Clusters">
      <i class="fa fa-user-alt-slash" />
    </button>
  );

  // Represents the whole row including the label, the selectpicker and the refresh button
  const clusterDropdown = (
    <div class="form-group row portal-dropdown">
      {labelText != 'none' && (
        <label for="cluster-id" class="col-form-label">
          {labelText}
        </label>
      )}
      <div class="col input-group">
        {select}
        {deselect ? deselectButton : undefined}
        {refreshButton}
      </div>
    </div>
  );

  // Refresh the selectpicker and add an on change event
  $(select).selectpicker('refresh');
  $(select).on('changed.bs.select', () => {
    const selectedOption = $(select).find(':selected');
    const selectedClusterId = selectedOption.val();

    if (!multiSelect && urlParameters) {
      const previousUrlParam = getSearchParamsUrl('cluster_id');
      if (selectedClusterId && selectedClusterId !== previousUrlParam) {
        setSearchParamsUrl({ cluster_id: selectedClusterId });
      }
    }

    if (onchange && selectedClusterId) {
      onchange('cluster_id', '4wheels', selectedOption, selectedClusterId);
    }
  });

  $(select).on('reload-dropdown-options', event => {
    const dropdownSettings = Object.assign(
      {
        selectedValues,
        multiSelect,
        urlParameters,
      },
      event.detail,
    );

    loadClusters(
      select,
      refreshIcon,
      dropdownSettings.selectedValues,
      dropdownSettings.multiSelect,
      dropdownSettings.urlParameters,
      dropdownSettings.allResources,
      headers,
      params,
    );
  });

  // Reload the Cluster records from the REST API (with cache-control headers)
  $(refreshButton).on('click', event => {
    if (onrefresh) {
      onrefresh(event, select);
    } else {
      loadClusters(select, refreshIcon, selectedValues, multiSelect, urlParameters, listAllResources, headers, params);
    }
  });

  // De-select all Clusters from the selectpicker
  $(deselectButton).on('click', () => {
    $(select).selectpicker('deselectAll');
    $(select).selectpicker('refresh');
  });

  // Load the Cluster records from the REST API (without cache-control headers)
  loadClusters(select, refreshIcon, selectedValues, multiSelect, urlParameters, listAllResources, {}, params);
  return clusterDropdown;
}

/**
 * Populates the selectpicker dropdown with the values from the REST API endpoint. Deletes all active dropdown items
 * before adding the new items.
 *
 * @param {JSX.IntrinsicElements.select} select
 * @param {JSX.Element} refreshIcon
 * @param {type[]} selectedValues
 * @param {boolean} multiSelect
 * @param {boolean} urlParameters
 * @param {boolean} allResources
 * @param {object} headers
 * @param {object} params
 */
async function loadClusters(
  select,
  refreshIcon,
  selectedValues,
  multiSelect,
  urlParameters,
  allResources,
  headers,
  params,
) {
  $(refreshIcon).addClass('fa-spin');
  select.querySelectorAll('option:not(.defaultDisabled)').forEach(el => el.remove());
  $(select).attr('disabled', true).selectpicker('refresh');

  try {
    const clusters_response = await baseApiAxios.getClusters(
      ['project_name', 'account_id', 'status', 'cluster_size', 'order_proposer'],
      allResources,
      headers,
      params,
    );

    // Retrieve a cluster_id from the URL search parameters
    const selected_cluster = !multiSelect && urlParameters && getSearchParamsUrl('cluster_id');

    // Add all account items as options to the selectpicker
    const options = clusters_response.cluster_items.map(cluster => {
      const cluster_id = cluster.project_name.replaceAll(' ', '_');
      let opt = document.createElement('option');
      opt.value = cluster_id;
      opt.dataset.content = `<span class="bs-dropdown-badge ${cluster.status?.toLowerCase()}">${
        cluster.project_name
      }</span><span class="bs-dropdown-item-text">${
        cluster.order_proposer
      }</span><span class="bs-dropdown-badge">size: ${
        cluster.cluster_size
      }</span> <span class="bs-dropdown-badge new">${cluster.account_id}</span>`;
      opt.selected =
        (selected_cluster && selected_cluster == cluster_id) || (selectedValues && selectedValues.includes(cluster_id))
          ? true
          : false;
      return opt;
    });

    // Sort the selectpicker options in asc order by the option text
    options.sort(function (a, b) {
      return a.value.localeCompare(b.value);
    });

    // Add all options to the selectpicker
    options.forEach(option => {
      select.appendChild(option);
    });
    const event = new CustomEvent('dropdown-options-loaded', { detail: { number_projects: options.length } });
    select.dispatchEvent(event);
    $(select).trigger('changed.bs.select');
  } catch (err) {
    showError(err);
  }

  // Refresh the selectpicker to show the new options
  $(refreshIcon).removeClass('fa-spin');
  $(select).attr('disabled', false);
  $(select).selectpicker('refresh');
}
