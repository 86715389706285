import ChartHorizontalBar from '../../jsx/components/tiles/ChartHorizontalBar';
import ChartHorizontalStacked from '../../jsx/components/tiles/ChartHorizontalStacked';
import ChartLegend from '../../jsx/components/tiles/ChartLegend';
import TileDonutChart from '../../jsx/components/tiles/TileDonutChart';
import TileSeries from '../../jsx/components/tiles/TileSeries';
import TileText from '../../jsx/components/tiles/TileText';
import TileValueInRange from '../../jsx/components/tiles/TileValueInRange';
import { baseApiAxios } from './api';

export const defaultColors = [
  '#006D9C',
  '#AAABAE',
  '#DC4E41',
  '#4FA484',
  '#EC9960',
  '#AF575A',
  '#B6C75A',
  '#62B3B2',
  '#294E70',
  '#738795',
  '#EDD051',
  '#BD9872',
  '#5A4575',
  '#7EA77B',
  '#708794',
  '#D7C6BB',
  '#339BB2',
  '#55672D',
  '#E6E1AA',
  '#96907F',
  '#87BC65',
  '#CF7E60',
];

export const tileTitles = {
  'account-close': 'Close Account',
  'assign-ad-user': 'Assign AD User',
  'associate-hostedzone': 'Associate Hosted Zone',
  'associate-r53r-rules': 'Associate Route53 Rules',
  'attach-transitgateway': 'Attach TransitGateway',
  'create-4wheels-cluster': 'Create 4Wheels Cluster',
  'create-account': 'Create Account',
  'create-api-key': 'Lock Root User',
  'create-hostedzone': 'Create Hosted Zone',
  'create-peering': 'Create VPC Peering',
  'create-service-user': 'Create Service User',
  'create-vpc': 'Create VPC',
  'delete-4wheels-cluster': 'Delete 4Wheels Cluster',
  'delete-api-key': 'Delete API Key',
  'delete-service-user': 'Delete Service User',
  'destroy-hostedzone': 'Delete Hosted Zone',
  'destroy-peering': 'Delete VPC Peering',
  'destroy-vpc': 'Delete VPC',
  'enable-ad-user': 'Enable AD User',
  'grant-purchase-role': 'Grant Marketplace Role',
  'grant-temporary-access': 'Grant Temporary Access',
  'onboard-hostedzone': 'Onboard Hosted Zone',
  'ou-move': 'Org Unit Move',
  'remove-ad-user': 'Remove AD User',
  'remove-ad-users-account': 'Remove Account of AD User',
  'unlock-root-user': 'Unlock Root User',
  'update-ad-user': 'Update AD User',
  'update-vpc': 'Update VPC',
};

function daysInMonth(month, year) {
  return new Date(year, month, 0).getDate();
}

export function setTimeframeSelectOptions(timeframeSelect, options, disabledOptions) {
  let disabledOptionsParam = disabledOptions || [];
  let optionsHtml = '';
  let selectedIndex = null;
  options.forEach((option, index) => {
    const disabled = disabledOptionsParam.indexOf(option) !== -1;
    if (selectedIndex === null)
      selectedIndex = disabledOptions ? (!disabled ? Math.min(index + 10, options.length - 1) : null) : index;
    optionsHtml += `<option value="${option}"${disabled ? ' disabled' : ''}${
      index == selectedIndex && !disabled ? ' selected' : ''
    }>${option}</option>`;
  });
  if (!optionsHtml) optionsHtml = '<option value="no-data-available" disabled selected>No Data available</option>';
  timeframeSelect.innerHTML = optionsHtml;
}

export function selectTimeframeCategory(selection) {
  document.querySelector(`.chart-timeframe-switch input[value="${selection}"]`).checked = true;
}

export function getDropdownSelection(timeframeSelect) {
  const selectedCategory = document.querySelector(`.chart-timeframe-switch input[name="category"]:checked`)?.value;
  const selectedTimeframe = timeframeSelect.value;
  return [selectedCategory, selectedTimeframe];
}

export function loadOrderStatistics(tileId, category, statistic, timeframe, sk) {
  baseApiAxios
    .get(`/stats/categories/${category}/statistics/${statistic}?time_frame=${timeframe}`, null, {})
    .then(result => {
      const latestDataset = result.stats?.find(dataset => dataset.sk === sk);
      let previousDataset;
      const { year: selectedYear, month: selectedMonth, day: selectedDay } = latestDataset || {};

      if (timeframe === 'monthly') {
        previousDataset = result.stats?.find(
          dataset => dataset.year === selectedYear && dataset.month === (11 + selectedMonth) % 12,
        );
      } else if (timeframe === 'daily') {
        const days = daysInMonth(selectedMonth - 1);
        previousDataset = result.stats?.find(
          dataset =>
            dataset.year === selectedYear &&
            dataset.month === selectedMonth &&
            dataset.day === (days - 1 + selectedDay) % days,
        );
      }
      const tile = document.getElementById(tileId);
      if (!tile) return;
      const title = tile.querySelector('.tile-title');
      try {
        $(tile).replaceWith(
          <TileText
            id={tileId}
            title={title.innerText}
            latestValue={latestDataset?.data?.count}
            previousValue={previousDataset?.data?.count}></TileText>,
        );
      } catch (err) {
        console.log(err);
      }
    })
    .catch(console.error);
}

export function loadTextTile(tileId, latestValue, previousValue, hideDifference, title, highlightColor, infoText) {
  const tile = document.getElementById(tileId);
  if (!tile) return;
  const titleElement = tile.querySelector('.tile-title');
  try {
    $(tile).replaceWith(
      <TileText
        id={tileId}
        title={title || titleElement.innerText}
        infoText={infoText}
        infoColor={infoText && 'var(--bs-gray-500)'}
        latestValue={latestValue}
        previousValue={previousValue}
        highlightColor={highlightColor}
        hideDifference={hideDifference}></TileText>,
    );
  } catch (err) {
    console.log(err);
  }
}

export function loadValueInRangeTile(tileId, latestValue, range, rangeLabel) {
  const tile = document.getElementById(tileId);
  if (!tile) return;
  const title = tile.querySelector('.tile-title');
  try {
    $(tile).replaceWith(
      <TileValueInRange
        id={tileId}
        title={title.innerText}
        latestValue={latestValue}
        range={range}
        rangeLabel={rangeLabel}></TileValueInRange>,
    );
  } catch (err) {
    console.log(err);
  }
}

export function loadBarChart(id, title, headline, chartData, colorsParam) {
  const labels = Object.keys(chartData);
  const values = labels.map(label => chartData[label]);
  const maxValue = Math.floor(Math.max(...values) * 1.1);
  const colors = colorsParam || defaultColors;
  try {
    $(`#${id}`).replaceWith(
      <TileText id={id} title={title} headline={headline} collapse={true}>
        {labels.map((label, index) => (
          <ChartHorizontalBar
            valueText={values[index]}
            value={Math.floor((values[index] * 100) / maxValue)}
            title={label}
            color={colors[index % colors.length]}></ChartHorizontalBar>
        ))}
      </TileText>,
    );
  } catch (err) {
    console.log(err);
  }
}

export function loadStackedBarChart(id, title, headline, chartData, labels, hideLegend, colors) {
  try {
    $(`#${id}`).replaceWith(
      <TileText id={id} title={title} headline={headline} collapse={true}>
        {Object.entries(chartData).map(([subtitle, values]) => {
          const filteredLabels = labels.filter(label => values[label]?.count);
          const orderedValues = filteredLabels.map(label => values[label]?.count);
          return (
            <ChartHorizontalStacked
              title={subtitle}
              hideLegend={hideLegend}
              labels={filteredLabels}
              values={orderedValues}
              colors={colors || defaultColors}></ChartHorizontalStacked>
          );
        })}
        {hideLegend && (
          <div class="mt-4">
            <ChartLegend labels={labels || []} colors={colors || defaultColors}></ChartLegend>
          </div>
        )}
      </TileText>,
    );
  } catch (err) {
    console.log(err);
  }
}

export function loadSelectFieldBarChart(id, title, headline, chartData, selectDefault, collapse, colors) {
  try {
    const chartColors = colors || defaultColors;
    let selectableChartData = {};
    let selectableObj = {};

    Object.entries(chartData).forEach(([key, values]) => {
      Object.entries(values).forEach(([selectable, value]) => {
        if (!selectableChartData[selectable]) selectableChartData[selectable] = {};
        selectableChartData[selectable][key] = value;
        selectableObj[selectable] = true;
      });
    });

    const selectableLabels = Object.keys(selectableObj);

    const renderChart = (container, selectedValue) => {
      const entries = Object.entries(selectableChartData[selectedValue]);
      const sum = entries.map(item => item[1]).reduce((a, b) => a + b);
      $(container).html(
        entries
          .sort((a, b) => b[1] - a[1])
          .map(([subtitle, value], index) => {
            return (
              <ChartHorizontalBar
                title={subtitle}
                value={Math.round((value * 100) / sum)}
                valueText={value || '0'}
                color={chartColors[index % chartColors.length]}></ChartHorizontalBar>
            );
          }),
      );
    };

    $(`#${id}`).replaceWith(
      <TileText
        id={id}
        title={title}
        headline={headline}
        selectOptions={selectableLabels}
        selectChange={evt => {
          const cardBody = evt.target.closest('.card-body');
          const container = cardBody.querySelector('.selectable-chart-container');
          if (container) {
            renderChart(container, evt.target.value);
          }
        }}
        selectDefault={selectDefault}
        collapse={collapse}>
        <div class="selectable-chart-container"></div>
      </TileText>,
    );

    renderChart(
      document.getElementById(id)?.querySelector('.selectable-chart-container'),
      selectDefault || selectableLabels[0],
    );
  } catch (err) {
    console.log(err);
  }
}

export function loadStackedAbsoluteValuesChart(
  id,
  title,
  headline,
  chartData,
  labels,
  reference,
  hideLegend,
  collapse,
  colors,
) {
  const allColors = colors || defaultColors;
  try {
    $(`#${id}`).replaceWith(
      <TileText id={id} title={title} headline={headline} collapse={collapse}>
        {Object.entries(chartData)
          .sort((a, b) => b[1].count - a[1].count)
          .map(([subtitle, values]) => {
            let orderedColors = [];
            const filteredLabels = labels.filter((label, index) =>
              values[label]?.count ? orderedColors.push(allColors[index % allColors.length]) : false,
            );
            const orderedValues = filteredLabels.map(label => values[label]?.count);
            return (
              <ChartHorizontalStacked
                title={subtitle}
                hideLegend={hideLegend}
                labels={filteredLabels}
                values={orderedValues}
                colors={orderedColors}
                absolute={values.count}
                reference={reference}></ChartHorizontalStacked>
            );
          })}
        {hideLegend && (
          <div class="mt-4">
            <ChartLegend labels={labels || []} colors={colors || defaultColors}></ChartLegend>
          </div>
        )}
      </TileText>,
    );
  } catch (err) {
    console.log(err);
  }
}

export function loadDonutChart(id, title, chartData) {
  const labels = Object.keys(chartData);
  try {
    $(`#${id}`).replaceWith(
      <TileDonutChart
        id={id}
        title={title}
        values={labels?.length ? labels.map(label => chartData[label]) : null}
        colors={defaultColors}
        labels={labels}
        partial={26 / 30}></TileDonutChart>,
    );
  } catch (err) {
    console.log(err);
  }
}

export function loadLegendTile(id, title, headline, labels, colors) {
  try {
    $(`#${id}`).replaceWith(
      <TileText id={id} title={title} headline={headline}>
        <ChartLegend
          labels={labels}
          colors={colors || defaultColors}
          minHeight={'120px'}
          largeText={true}></ChartLegend>
      </TileText>,
    );
  } catch (err) {
    console.log(err);
  }
}

export function loadSeriesTile(id, title, headline, values, color, sort, hideLabels, tooltip) {
  try {
    $(`#${id}`).replaceWith(
      <TileSeries
        id={id}
        title={title}
        headline={headline}
        values={values}
        color={color}
        sort={sort}
        hideLabels={hideLabels}
        tooltip={tooltip}></TileSeries>,
    );
  } catch (err) {
    console.log(err);
  }
}
