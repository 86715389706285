import { toggleOverview } from './js/portal/main';
import { initDXGWTable } from './js/portal/directconnect';
import { Datatable } from './Datatable';

const tableId = 'table-dx-gws';

export default function NetworksListDxGwPage() {
  const page = (
    <>
      {/* TABLE AND CONFIGURE TABLE BUTTON */}
      <Datatable tableId={tableId} />
    </>
  );

  function onPageReady() {
    toggleOverview('hidden_dxgateway_overview');
    initDXGWTable(tableId);
  }

  return [page, onPageReady];
}
