// I actually just move the existing code from accounts.js to this file
// as attempt to reduce the size of accounts.js and make Sonar compliant with it
import {
  accountInfoFields,
  accountOrgInfoFields,
  addCopyButton,
  addLoadAccountDetailsButton,
  addLoadOrderDetailsButton,
  checkValueAgainstList,
  checkIfAnyInList,
  configureTableColumns,
  createAndAddInfoTable,
  encodeEntities,
  retrieveVisibleFields,
  rowDetailsClickHandler,
  showErrorFromApiOperation,
  displaySuccessPopup,
} from './main';
import { accountIdPattern, updateAccountSummary } from './accounts';
import { addSpinner, addTableSpinner, removeSpinners } from './sidebar';
import { apiAxios, baseApiAxios, showError } from './api';
import { COGNITO } from './auth';
import { checkResponsibility } from './dashboard';
import { CONF } from './environment';
import AccountTabs from '../../jsx/components/forms/AccountTabs';
import { initDataTable } from './datatable';

const accountsDataTableButtonAction = (action, text, titleAttribute) => {
  let endpoint = 'invalid';
  switch (action) {
    case 'organizations-update':
      endpoint = '/organizations/trigger-update';
      break;
    case 'accounts-update':
      endpoint = '/accounts/trigger-update';
      break;
  }
  return {
    text: text,
    action: () => {
      addSpinner();
      baseApiAxios
        .post(endpoint)
        .then(response => {
          displaySuccessPopup(response.message, 'Your request was successful.');
        })
        .catch(showError)
        .finally(removeSpinners);
    },
    titleAttr: titleAttribute,
  };
};

const canViewToggles = () => {
  return (
    checkValueAgainstList('manage_accounts', localStorage.permissions) ||
    checkValueAgainstList('view_all_accounts', localStorage.permissions) ||
    localStorage.account_areas
  );
};

const canViewCosts = () => {
  return checkIfAnyInList(['manage_accounts', 'manage_organizations'], localStorage.permissions?.split(',') || []);
};

const sumColumnValues = (a, b) => {
  const removeFormatting = value => {
    const typeOfValue = typeof value;
    return typeOfValue === 'string' ? value.replace(/[$,]|[CN¥,]/g, '') * 1 : typeOfValue === 'number' ? value : 0;
  };

  const sanitizedA = a === '-' ? 0 : a;
  const sanitizedB = b === '-' ? 0 : b;

  return removeFormatting(sanitizedA) + removeFormatting(sanitizedB);
};

// list accounts table

let accountsTableColumns = [
  { id: 'select' },
  { id: 'region_category', name: 'Region Category' },
  { id: 'master_account', name: 'Master Account ID' },
  { id: 'account_id', name: 'Account ID' },
  { id: 'account_root_email', name: 'Root Account' },
  { id: 'creation_date', name: 'Creation Date' },
  { id: 'fpc_status', name: 'FPC Status' },
  { id: 'account_friendly_name', name: 'Account Friendly Name' },
  { id: 'description', name: 'Description' },
  { id: 'account_area', name: 'Account Area' },
  { id: 'account_type', name: 'Account Type' },
  { id: 'account_stage', name: 'Account Stage' },
  { id: 'primary_responsible', name: 'Primary Responsible' },
  { id: 'sec_responsible', name: 'Secondary Responsible' },
  { id: 'it_responsible', name: 'IT Responsible' },
  { id: 'support_plan', name: 'AWS Support Plan' },
  { id: 'app_id', name: 'APP-ID' },
  { id: 'appd_id', name: 'APPD-ID' },
  { id: 'cost_center', name: 'Cost Center' },
  { id: 'psp', name: 'PSO/PSP' },
  { id: 'federated_user_roles', name: 'AD accessible roles (central)' },
  { id: 'cdh_user_roles', name: 'CDH accessible roles (decentral)' },
  { id: 'idc_user_roles', name: 'IDC accessible roles (central)' },
  { id: 'org_id', name: 'Org Id' },
  { id: 'org_name', name: 'Org Name' },
  { id: 'org_ou_id', name: 'Org Unit Id' },
  { id: 'org_ou_name', name: 'Org Unit Name' },
  { id: 'org_status', name: 'Org Status' },
  { id: 'org_status_update', name: 'Last Org Update (UTC)' },
  { id: 'access_status', name: 'Access Check' },
  { id: 'access_update', name: 'Last Access Update (UTC)' },
  { id: 'approval_expires', name: 'User Confirmation Expiration' },
  { id: 'app_criticality', name: 'App Criticality' },
  { id: 'itsm_service', name: 'ITSM Service' },
];

const accountsTableCostsColumns = [
  { id: 'month_2', name: 'Costs two months ago' },
  { id: 'month_1', name: 'Costs last month' },
  { id: 'month_0', name: 'Costs current month' },
];

if (canViewCosts()) accountsTableColumns = accountsTableColumns.concat(accountsTableCostsColumns);

let actionsColumn = [{ id: 'actions', name: 'Actions' }];

accountsTableColumns = accountsTableColumns.concat(actionsColumn);

const dropdownColumns = [
  'region_category',
  'master_account',
  'fpc_status',
  'account_area',
  'account_type',
  'account_stage',
  'org_id',
  'org_name',
  'org_ou_id',
  'org_ou_name',
  'org_status',
  'access_status',
  'support_plan',
  'app_criticality',
  'itsm_service',
];

const searchColumns = [
  'account_id',
  'account_root_email',
  'creation_date',
  'account_friendly_name',
  'description',
  'primary_responsible',
  'sec_responsible',
  'it_responsible',
  'app_id',
  'appd_id',
  'cost_center',
  'psp',
  'org_status_update',
  'access_update',
  'approval_expires',
];

const accountsDataTableColumns = () => {
  let accountsColumns = [
    // Column 0
    {
      visible: true,
      defaultContent: '',
      orderable: false,
      searchable: false,
      name: 'select',
      class: 'details-control',
      width: '20px',
      createdCell: null,
    },
    // Column 1
    {
      data: 'region_category',
      title: 'Region Category',
      createdCell: function (td, cellData) {
        addCopyButton(td);
        if (cellData === 'China') {
          $(td).addClass('portal-warning');
        }
      },
    },
    // Column 2
    {
      data: 'master_account',
      title: 'Master Account Id',
      createdCell: function (td, cellData) {
        addCopyButton(td);
        addLoadAccountDetailsButton(td);

        if (!accountIdPattern.test(cellData)) {
          $(td).addClass('portal-danger');
        }
      },
    },
    // Column 3
    {
      visible: true,
      data: 'account_id',
      title: 'Account Id',
      createdCell: function (td, cellData) {
        addCopyButton(td);
        addLoadAccountDetailsButton(td);

        if (!accountIdPattern.test(cellData)) {
          $(td).addClass('portal-danger');
        }
      },
    },
    // Column 4
    {
      data: 'account_root_email',
      title: 'Root Account',
    },
    // Column 5
    {
      data: 'creation_date',
      title: 'Creation Date',
    },
    // Column 6
    {
      data: 'fpc_status',
      title: 'FPC Status',
    },
    // Column 7
    {
      visible: true,
      data: 'account_friendly_name',
      title: 'Account Friendly Name',
    },
    // Column 8
    {
      data: 'description',
      title: 'Description',
    },
    // Column 9
    {
      visible: true,
      data: 'account_area',
      title: 'Account Area',
    },
    // Column 10
    {
      visible: true,
      data: 'account_type',
      title: 'Account Type',
    },
    // Column 11
    {
      visible: true,
      data: 'account_stage',
      title: 'Account Stage',
      createdCell: function (td, cellData) {
        addCopyButton(td);

        if (cellData === 'basic') {
          $(td).addClass('portal-danger');
        }
      },
    },
    // Column 12
    {
      data: 'primary_responsible',
      title: 'Primary Responsible',
    },
    // Column 13
    {
      data: 'sec_responsible',
      title: 'Secondary Responsible',
    },
    // Column 14
    {
      data: 'it_responsible',
      title: 'IT Responsible',
    },
    // Column 15
    {
      searchable: false,
      data: 'support_plan',
      title: 'AWS Support Plan',
      createdCell: addCopyButton,
    },
    // Column 16
    {
      data: 'app_id',
      title: 'APP ID',
      createdCell: function (td, cellData) {
        // check for empty values
        if (!cellData) {
          $(td).text('unknown');
          $(td).addClass('portal-danger');
        }

        addCopyButton(td);
        // regex for patterns like this
        // APP-01234
        // APP-0123456
        const appid_pattern = /^APP-\d{4,7}$/;
        if (!appid_pattern.test(cellData)) {
          $(td).addClass('portal-danger');
        }
      },
    },
    // Column 17
    {
      data: 'appd_id',
      title: 'APPD ID',
      createdCell: function (td, cellData) {
        // check for empty values
        if (!cellData) {
          $(td).text('unknown');
          $(td).addClass('portal-danger');
        }

        addCopyButton(td);
        const appdid_pattern = /^APPD-\d{5,6}$/;
        if (!appdid_pattern.test(cellData)) {
          $(td).addClass('portal-danger');
        }
      },
    },
    // Column 18
    {
      data: 'cost_center',
      title: 'Cost Center',
      createdCell: function (td, cellData) {
        // check for empty values
        if (!cellData) $(td).text('unknown');

        addCopyButton(td);
      },
    },
    // Column 19
    {
      data: 'psp',
      title: 'PSO',
      createdCell: function (td, cellData) {
        // check for empty values
        if (!cellData) $(td).text('unknown');

        addCopyButton(td);
      },
    },
    // Column 20
    {
      defaultContent: 0,
      name: 'federated_user_roles',
      title: 'AD accessible roles (central)',
      render: function (data) {
        if ('federated_user_roles' in data) {
          return data['federated_user_roles'].length;
        }
      },
    },
    // Column 21
    {
      defaultContent: 0,
      name: 'cdh_user_roles',
      title: 'CDH accessible roles (decentral)',
      render: function (data) {
        if ('cdh_user_roles' in data) {
          return data['cdh_user_roles'].length;
        }
      },
    },
    // Column 22
    {
      defaultContent: 0,
      name: 'idc_user_roles',
      title: 'IDC accessible roles (central)',
      render: function (data) {
        if ('idc_user_roles' in data) {
          return data['idc_user_roles'].length;
        }
      },
    },
    // Column 23
    {
      defaultContent: '-',
      data: 'org_id',
      title: 'Org Id',
    },
    // Column 24
    {
      defaultContent: '-',
      data: 'org_name',
      title: 'Org Name',
    },
    // Column 25
    {
      defaultContent: '-',
      data: 'org_ou_id',
      title: 'Org Unit Id',
    },
    // Column 26
    {
      defaultContent: '-',
      data: 'org_ou_name',
      title: 'Org Unit Name',
    },
    // Column 27
    {
      defaultContent: '-',
      data: 'org_status',
      title: 'Org Status',
      createdCell: function (td, cellData) {
        addCopyButton(td);

        if (cellData === 'ACTIVE') {
          $(td).addClass('portal-success');
        } else {
          $(td).addClass('portal-neutral');
        }
      },
    },
    // Column 28
    {
      defaultContent: '-',
      data: 'org_status_update',
      title: 'Last Org Update (UTC)',
    },
    // Column 29
    {
      defaultContent: '-',
      data: 'access_status',
      title: 'Access Check',
      createdCell: function (td, cellData) {
        addCopyButton(td);

        if (cellData === 'Success') {
          $(td).addClass('portal-success');
        } else {
          $(td).addClass('portal-danger');
        }
      },
    },
    // Column 30
    {
      defaultContent: '-',
      data: 'access_update',
      title: 'Last Access Update (UTC)',
    },
    // Column 31
    {
      // TODO - check what happen if the account is under IDC
      defaultContent: '-',
      data: 'approval_expires',
      title: 'User Confirmation Expiration',
      createdCell: addCopyButton,
      render: function (data, type, row) {
        if (type === 'display' && checkResponsibility(row, COGNITO.user.email) && row.fpc_status !== 'deleted') {
          try {
            const expirationDate = new Date(data);
            const daysDifference = Math.ceil((expirationDate.getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24));
            let daysClass = 'portal-success';
            if (daysDifference <= 28) {
              daysClass = 'portal-danger';
            } else if (daysDifference < 50) {
              daysClass = 'portal-warning';
            }
            return (
              '<a href="?account_id=' +
              row.account_id +
              '#confirmusers" target="_blank" title="Go to User Confirmation (only for Account Responsibles)">' +
              expirationDate.toISOString().substring(0, 10) +
              ' (<strong class="color: ' +
              daysClass +
              '">' +
              daysDifference +
              '</strong> days left) ' +
              '<i class="fas fa-external-link-alt" style="margin-left: 0.5em, font-size: smaller"></i></a>'
            );
          } catch {
            // return data;
            return (
              '<a href="?account_id=' +
              row.account_id +
              '#confirmusers" target="_blank" title="Go to User Confirmation (only for Account Responsibles)">Confirm Users ' +
              '<i class="fas fa-external-link-alt" style="margin-left: 0.5em, font-size: smaller"></i></a>'
            );
          }
        } else if (
          type === 'sort' &&
          !data &&
          checkResponsibility(row, COGNITO.user.email) &&
          row.fpc_status !== 'deleted'
        ) {
          return '_';
        } else {
          return data;
        }
      },
    },
    // Column 32
    {
      defaultContent: '-',
      data: 'app_criticality',
      title: 'App Criticality',
    },
    // Column 33
    {
      defaultContent: '-',
      data: 'itsm_service',
      title: 'ITSM Service',
    },
  ];
  if (canViewCosts()) {
    accountsColumns = accountsColumns.concat([
      // Column 34
      {
        defaultContent: '-',
        data: 'month_2',
        title: 'Costs two months ago',
      },
      // Column 35
      {
        defaultContent: '-',
        data: 'month_1',
        title: 'Costs last month',
      },
      // Column 36
      {
        defaultContent: '-',
        data: 'month_0',
        title: 'Costs current month',
      },
    ]);
  }
  accountsColumns = accountsColumns.concat([
    // Column 37
    {
      visible: true,
      defaultContent: '',
      orderable: false,
      searchable: false,
      name: 'actions',
      title: 'Actions',
      class: 'details-edit',
      width: '50px',
      createdCell: createActionsButtons,
    },
  ]);

  const createColumn = overrides => {
    const defaultColumn = {
      visible: false,
      defaultContent: 'unknown',
      orderable: true,
      searchable: true,
      data: null,
      createdCell: addCopyButton,
    };
    const column = { ...defaultColumn, ...overrides };
    if (!column.name) {
      column.name = column.data;
    }
    return column;
  };

  return accountsColumns.map(createColumn);
};

let showAllAccounts = false;
let showDeletedAccounts = false;
/*For the list view we always want to show quarantined accounts (which will have an different colored font).
And we don't have a user-story/request to have a switch toggle to show/hide quarantined accounts, so the default will be always-show.
If we have a user-story in the future to implement a toggle for "show/hide quarantined accounts,...then we simply attach an event handler that will update this variable just like we've done for deleted accounts*/
let showQuarantinedAccounts = true;

function loadAccountsData(tableId, headers, forceReload) {
  // addSpinner must be called within the context of jQuery. Otherwise,
  // the spinner won't be visible on the page.
  $(() => addSpinner());
  $(() => addTableSpinner());

  if (forceReload) {
    headers = { ...headers, 'Cache-Control': 'max-age=0, must-revalidate' };
  }

  const attributes = retrieveVisibleFields('#' + tableId, accountsTableColumns);

  if (attributes.indexOf('fpc_status') === -1) attributes.push('fpc_status');
  if (attributes.indexOf('account_id') === -1) attributes.push('account_id');
  /* Even if the user does not want account_area to be a visible column in the UI, we still have to fetch it from the backend API.
   This is because now that we have an account area "quarantine" with a different visual indicator , we need this field in order to be able to update the visual indicator (color) in the UI */
  if (attributes.indexOf('account_area') === -1) attributes.push('account_area');

  const params = {
    attribute_names: attributes.join(),
    all: showAllAccounts,
    deleted: showDeletedAccounts,
    quarantined: showQuarantinedAccounts,
  };

  apiAxios
    .get('/accounts', { headers, params })
    .then(response => {
      const { account_data: accounts } = response.data;
      reloadAccountTable(accounts);
      updateAccountSummary(accounts);
    })
    .catch(showErrorFromApiOperation('Error fetching list of accounts'))
    .finally(() => {
      removeSpinners();
    });
}

const reloadAccountTable = dataArray => {
  const table = $('#table-accounts');
  const dataTable = table.DataTable().clear().draw();
  const dataTableApi = table.dataTable();

  if (!dataArray || !Array.isArray(dataArray)) {
    hideTableAndShowAlert();
    removeSpinners();
    return;
  }

  dataTable.rows.add(dataArray).draw();

  const columnsState = dataTableApi.api().state().columns;
  dataTable.columns().every(function (columnIndex) {
    const column = this;
    const dataSource = column.dataSrc();
    const that = this;
    const title = column.header().innerHTML;

    if (dropdownColumns.indexOf(dataSource) > -1) {
      const select = $('<select><option value=""></option></select>')
        .appendTo($(column.footer()).empty())
        .on('change', function () {
          const val = $.fn.dataTable.util.escapeRegex($(this).val());
          column.search(val ? '^' + val + '$' : '', true, false).draw();
        });

      column
        .data()
        .unique()
        .sort()
        .each(function (data) {
          let opt = document.createElement('option');
          opt.innerText = data;
          opt.value = data;
          select.append(opt);

          if (
            data &&
            columnsState[columnIndex].search &&
            columnsState[columnIndex].search.search &&
            data.match(columnsState[columnIndex].search.search)
          ) {
            opt.selected = true;
          }
        });
    }

    // if (searchColumns.includes(dataSource)) {
    //   createSearchInput(column, columnsState[columnIndex]);
    // }
    if (searchColumns.indexOf(dataSource) > -1) {
      $(column.footer()).html('<input type="text" placeholder="Search ' + title + '" />');

      if (columnsState[columnIndex].search && columnsState[columnIndex].search.search) {
        column.footer().lastChild.value = columnsState[columnIndex].search.search;
      }

      $('input', that.footer()).on('keyup change', function () {
        if (that.search() !== this.value) {
          that.search(this.value).draw();
        }
      });
    }
  });

  $(window).trigger('resize');
  removeSpinners();
};

const hideTableAndShowAlert = () => {
  $('#table-accounts').hide();
  $('#table-dropdown-button').hide();
  $('#noaccountalert').show();
};

const accountsDataTableToggles = tableId => {
  $('#show-all-accounts-div').toggleClass('d-none', !canViewToggles());

  $('#show-all-accounts-switch')
    .attr('checked', showAllAccounts)
    .on('change', function () {
      if (showAllAccounts !== this.checked) {
        showAllAccounts = this.checked;
        loadAccountsData(tableId);
      }
    });

  $('#show-deleted-accounts-switch')
    .attr('checked', showDeletedAccounts)
    .on('change', function () {
      if (showDeletedAccounts !== this.checked) {
        showDeletedAccounts = this.checked;
        loadAccountsData(tableId);
      }
    });
};

const accountsDataTableFooterButtons = () => {
  const exportOptions = {
    exportOptions: {
      columns: ':visible',
    },
  };
  return [
    {
      ...exportOptions,
      ...{
        extend: 'excelHtml5',
        text: 'Export Excel',
        columns: ':visible',
        titleAttr: 'Export the visible columns as Excel file',
      },
    },
    {
      ...exportOptions,
      ...{
        extend: 'csvHtml5',
        text: 'Export CSV',
        titleAttr: 'Export the visible columns as CSV file',
      },
    },
    {
      ...exportOptions,
      ...{
        extend: 'copyHtml5',
        text: 'Copy',
        titleAttr: 'Copy the visible columns into your clipboard',
      },
    },
    {
      extend: 'resetTable',
      ajaxReload: false,
      titleAttr: 'Reset all filters in the table footer',
    },
    {
      extend: 'reloadTable',
      text: 'Reload Accounts',
      ajaxReload: false,
      methodReload: loadAccountsData,
      titleAttr: 'Reload AWS accounts (no-cache)',
    },
  ];
};

export async function initAccountTable(tableId) {
  configureTableColumns(tableId, accountsTableColumns);

  const formatter = new Intl.NumberFormat(CONF.language, {
    style: 'currency',
    currency: CONF.currency,
    maximumFractionDigits: 2,
  });

  initDataTable(
    tableId,
    'lCfrtpBi',
    accountsDataTableFooterButtons(),
    accountsDataTableColumns(),
    function (row, data) {
      if (data.fpc_status === 'deleted') {
        $(row).addClass('row-deleted');
      } else if (data.fpc_status === 'halt') {
        $(row).addClass('row-inactive');
      }
      if (data.account_area && data.account_area.toLowerCase() === 'quarantine') {
        // The check for account_area existence is just an extra guard. I know all accounts should have an account_area, but please don't remove this.
        $(row).addClass('row-quarantined');
      }
    },
    {
      columnDefs: [
        {
          targets: canViewCosts() ? [34, 35, 36] : [],
          render: function (data) {
            if (!data || data === true) {
              return '-';
            } else {
              return formatter.format(parseFloat(data));
            }
          },
        },
        {
          targets: '_all',
          render: {
            display: encodeEntities,
          },
        },
      ],
      order: [[3, 'asc']],
      footerCallback: function (_row, _data, _start, _end, _display) {
        const api = this.api();

        if (canViewCosts()) {
          // Total over all pages
          const total = api.column('month_0:name').data().reduce(sumColumnValues, 0);
          const total_1 = api.column('month_1:name').data().reduce(sumColumnValues, 0);
          const total_2 = api.column('month_2:name').data().reduce(sumColumnValues, 0);
          // Total over this page
          const pageTotal = api.column('month_0:name', { page: 'current' }).data().reduce(sumColumnValues, 0);
          const pageTotal_1 = api.column('month_1:name', { page: 'current' }).data().reduce(sumColumnValues, 0);
          const pageTotal_2 = api.column('month_2:name', { page: 'current' }).data().reduce(sumColumnValues, 0);

          // Update footer
          $(api.column('month_0:name').footer()).html(
            formatter.format(pageTotal) + ' (' + formatter.format(total) + ' total)',
          );
          $(api.column('month_1:name').footer()).html(
            formatter.format(pageTotal_1) + ' (' + formatter.format(total_1) + ' total)',
          );
          $(api.column('month_2:name').footer()).html(
            formatter.format(pageTotal_2) + ' (' + formatter.format(total_2) + ' total)',
          );
        }
      },
    },
  );

  accountsDataTableToggles(tableId);
  addTableButtons();

  $(`select[name="${tableId}_fields"]`).on('hide.bs.select', function () {
    loadAccountsData(tableId, null, true);
  });

  rowDetailsClickHandler({ tableId: tableId, rowDetailCallback: formatAccountRow });
  loadAccountsData(tableId);
}

const addTableButtons = () => {
  const permissions = localStorage.permissions ? localStorage.permissions : false;
  const dt = $('#table-accounts').DataTable({ retrieve: true });
  let buttons = 0; // Add buttons before the already existing buttons

  if (checkValueAgainstList('manage_accounts', permissions)) {
    dt.button().add(
      buttons,
      accountsDataTableButtonAction(
        'accounts-update',
        'Trigger Account Updates',
        'Collect account information like the alternate contacts or the support level.',
      ),
    );
    buttons++;
  }

  if (checkValueAgainstList('manage_organizations', permissions)) {
    dt.button().add(
      buttons,
      accountsDataTableButtonAction(
        'organizations-update',
        'Trigger Organizational Updates',
        'Collect all AWS accounts from AWS Organizations.',
      ),
    );
  }
};

const createActionsButtons = (td, _cellData, rowData, _row, _col) => {
  const buttons = $('<div class="table-action-button-group">').appendTo(td);

  buttons.append(
    $('<button class="btn-datatable" data-bs-toggle="tooltip" title="Account Details">')
      .append('<i class="fas fa-external-link-alt">')
      .on('click', () => {
        window.open('?account_id=' + rowData.account_id + '#accountdetails', '_blank');
      }),
  );

  buttons.append(
    $('<button class="btn-datatable" data-bs-toggle="tooltip" title="Manage and Confirm Users">')
      .append('<i class="fas fa-users">')
      .on('click', () => {
        window.open('?account_id=' + rowData.account_id + '#confirmusers', '_blank');
      }),
  );
};

export async function formatAccountRow(row) {
  const data = row.data();
  Promise.all([baseApiAxios.getAccount(data.account_id), baseApiAxios.getAccountPermissions(data.account_id)])
    .then(response => {
      const account_details = { ...response[0], ...response[1].permanent_roles };
      const temp_access_roles = response[1].temporary_roles || [];

      row
        .child(<AccountTabs data={account_details} tempAccessRoles={temp_access_roles} isAccountDetailsPage={false} />)
        .show();
      row.child()[0].setAttribute('class', 'rowDetails');
    })
    .catch(err => {
      showErrorFromApiOperation('Failed to fetch account details')(err);
    })
    .finally(() => {
      removeSpinners();
    });
}

export function formatChildRowListAccountsHeader(data, infoContainer) {
  // Warning if account is marked for deletion
  listAccountsChildRowAddWarning(infoContainer, data);

  // Orders related to account actions, like 'account-close' or 'ou-move'
  listAccountsChildRowAddOrders(infoContainer, data);
}

export function formatChildRowListAccountsLeftSide(data, container) {
  // LEFT - ACCOUNT DETAILS
  const accountContainer = document.createElement('div');
  accountContainer.setAttribute('class', 'detailsContent');
  container.appendChild(accountContainer);

  const header = document.createElement('h4');
  header.innerText = 'AWS Account Details';
  accountContainer.appendChild(header);

  const accountContainerDetails = document.createElement('div');
  accountContainer.appendChild(accountContainerDetails);

  let fields = [];
  fields = fields.concat(accountInfoFields);
  fields = fields.concat(accountOrgInfoFields);

  createAndAddInfoTable(data, fields, accountContainerDetails);
}

function listAccountsChildRowAddWarning(container, data) {
  if (data.marked_for_deletion && data.fpc_status !== 'deleted') {
    const info = document.createElement('div');
    info.setAttribute('class', 'detailsContent');
    container.appendChild(info);

    const msg = document.createElement('div');
    msg.setAttribute('style', 'color: black;');
    msg.innerHTML = '<h3><strong>Warning:</strong> AWS Account is marked for deletion!</h3>';
    info.appendChild(msg);
  }
}

function listAccountsChildRowAddOrders(container, data) {
  if (data.orders && Object.getOwnPropertyNames(data.orders).length > 0) {
    const ordersContainer = document.createElement('div');
    ordersContainer.setAttribute('class', 'detailsContent');
    container.appendChild(ordersContainer);

    const ordersTable = document.createElement('table');
    ordersTable.setAttribute('class', 'table table-hover dataTable row-border nowrap compressed');
    ordersContainer.appendChild(ordersTable);

    const orderColumns = [
      { Name: 'Order Id', Value: 'id' },
      { Name: 'Action', Value: 'action' },
      { Name: 'Proposer', Value: 'proposer' },
      { Name: 'Creation Date', Value: 'creation_date' },
    ];

    const ordersTableHead = document.createElement('thead');
    ordersTable.appendChild(ordersTableHead);

    orderColumns.forEach(function (item) {
      const th = document.createElement('th');
      th.setAttribute('class', 'compressed');
      th.innerText = item['Name'];
      ordersTableHead.appendChild(th);
    });

    const ordersTableBody = document.createElement('tbody');
    ordersTable.appendChild(ordersTableBody);

    for (let key in data.orders) {
      const tr = document.createElement('tr');
      tr.setAttribute('role', 'row');
      tr.setAttribute('class', 'compressed');

      orderColumns.forEach(function (item) {
        const td = document.createElement('td');

        if (item['Value'] === 'id') {
          td.innerText = key;
          addCopyButton(td);
          addLoadOrderDetailsButton(td);
        } else {
          td.innerText = data.orders[key][item['Value']];
          addCopyButton(td);
        }

        tr.appendChild(td);
      });

      ordersTableBody.appendChild(tr);
    }
  }
}
