import { baseApiAxios } from './js/portal/api';
import TileDonutChart from './jsx/components/tiles/TileDonutChart';
import TileText from './jsx/components/tiles/TileText';
import ChartHorizontalStacked from './jsx/components/tiles/ChartHorizontalStacked';
import ChartLegend from './jsx/components/tiles/ChartLegend';
import { addSpinner, removeSpinners } from './js/portal/sidebar';
import {
  defaultColors,
  loadDonutChart,
  loadLegendTile,
  loadOrderStatistics,
  loadSelectFieldBarChart,
  loadStackedAbsoluteValuesChart,
  loadTextTile,
  setTimeframeSelectOptions,
} from './js/portal/statistics';
import { addCopyButton } from './js/portal/main';
import TileTable from './jsx/components/tiles/TileTable';
import { initDataTable } from './js/portal/datatable';

export default function StatisticsNetworkPage() {
  let timeframeSelect;
  let dropdownSelection, radioSelection;
  let usageData = {},
    typeData = {},
    cidrData = {},
    cidrsRequiredData = {};

  const page = (
    <div id="dashboard_overview_network">
      <div class="row mb-3">
        <div class="col">
          <div class="dashboard-title d-inline-block">
            <h3 class="overview" style="line-height:32px;margin-bottom:0;">
              Network Statistics
            </h3>
          </div>
          <div id="network-usage-dashboard-status" class="d-inline-block"></div>
        </div>
        <div class="col-auto text-right">
          <span class="d-inline-block loading-form-inline">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
          </span>
          <span class="d-inline-block portal-dropdown mx-3">
            <select class="form-control form-select" id="chart-timeframe-select" onchange={dropdownChangeListener}>
              <option disabled selected>
                No Data available
              </option>
            </select>
          </span>
          <span class="d-inline-block">
            <ul class="nav nav-pills chart-timeframe-switch">
              <li class="nav-item">
                <input
                  type="radio"
                  id="category-monthly"
                  name="category"
                  value="monthly"
                  onchange={radioChangeListener}
                  checked
                />
                <label class="nav-link" for="category-monthly">
                  Monthly
                </label>
              </li>
              <li class="nav-item">
                <input type="radio" id="category-daily" name="category" value="daily" onchange={radioChangeListener} />
                <label class="nav-link" for="category-daily">
                  Daily
                </label>
              </li>
            </ul>
          </span>
        </div>
      </div>
      <div class="row">
        <TileText id="number-vpcs" title="VPCs Total"></TileText>
        <TileText id="number-private-vpcs" title="Private VPCs"></TileText>
        <TileText id="number-public-vpcs" title="Public VPCs"></TileText>
        <TileDonutChart
          id="vpcs-by-type"
          title="VPCs by Type"
          colors={defaultColors}
          partial={26 / 30}></TileDonutChart>
        <TileText id="vpcs-by-region-and-type" title="Number of VPCs" headline="by Region and Type">
          <ChartHorizontalStacked
            title=""
            hideLegend={true}
            values={[]}
            colors={defaultColors}></ChartHorizontalStacked>
          <ChartLegend labels={[]} colors={defaultColors}></ChartLegend>
        </TileText>
        <TileText id="regions-need-cidrs" title="CIDRs needed" headline="Regions running out of CIDRs">
          <ChartLegend labels={[]} colors={defaultColors} minHeight={'120px'} largeText={true}></ChartLegend>
        </TileText>
        <TileText id="vpcs-by-region-and-size" title="Number of VPCs" headline="by Region and Size" collapse={true}>
          <ChartHorizontalStacked
            title=""
            hideLegend={true}
            values={[]}
            colors={defaultColors}></ChartHorizontalStacked>
          <ChartLegend labels={[]} colors={defaultColors}></ChartLegend>
        </TileText>
        <TileDonutChart
          id="vpcs-by-size"
          title="VPCs by Size"
          colors={defaultColors}
          partial={26 / 30}></TileDonutChart>
        <TileText id="free-cidrs-by-region-size" title="Free CIDRs" headline="by Region and Size">
          <ChartHorizontalStacked title="" hideLegend={true} values={[]} colors={[]}></ChartHorizontalStacked>
          <ChartLegend labels={[]} colors={[]}></ChartLegend>
        </TileText>
        <TileText id="create-vpc" title="Create VPC"></TileText>
        <TileText id="delete-vpc" title="Delete VPC"></TileText>
        <TileText id="create-4wheels" title="Create 4Wheels"></TileText>
        <TileTable id="vpc-data"></TileTable>
      </div>
    </div>
  );

  async function onPageReady() {
    timeframeSelect = document.getElementById('chart-timeframe-select');
    radioChangeListener();
  }

  function dropdownChangeListener(evt) {
    const value = evt.target.value;
    if (value) {
      dropdownSelection = value;
      loadDashboardData(radioSelection, dropdownSelection);
    }
  }

  async function radioChangeListener() {
    const value = document.querySelector(`.chart-timeframe-switch input[name="category"]:checked`)?.value;
    if (value) {
      radioSelection = value;
      dropdownSelection = null;
      const [initialOptions, initiallySelected] = await loadInitialData();
      setTimeframeSelectOptions(timeframeSelect, usageData.stats ? initialOptions : []);
      dropdownSelection = initiallySelected;

      if (!dropdownSelection) {
        loadEmptyTiles();
      } else {
        loadDashboardData(radioSelection, dropdownSelection);
      }
    }
  }

  // LOAD AND DISPLAY DATA

  async function loadInitialData() {
    await loadUsageData(radioSelection, dropdownSelection);
    if (!usageData?.stats?.length) return [['No Data available'], null];
    const year = Math.max(...usageData.stats.map(stat => stat.year));
    const month = Math.max(...usageData.stats.map(stat => stat.year === year && stat.month));
    const options = usageData.stats.map(stat => stat.sk);
    if (radioSelection === 'daily') {
      const day = Math.max(...usageData.stats.map(stat => stat.year === year && stat.month === month && stat.day));
      return [options, `${year}-${('0' + month).slice(-2)}-${('0' + day).slice(-2)}`];
    }
    return [options, `${year}-${('0' + month).slice(-2)}`];
  }

  function loadEmptyTiles() {
    loadTextTile('number-vpcs', '-', null, true);
    loadTextTile('number-private-vpcs', '-', null, true);
    loadTextTile('number-public-vpcs', '-', null, true);
    loadTextTile('create-vpc', '-', null, true);
    loadTextTile('delete-vpc', '-', null, true);
    loadTextTile('create-4wheels', '-', null, true);
    loadSelectFieldBarChart('free-cidrs-by-region-size', 'Free CIDRs', 'by Region and Size', {}, '-', false);
    loadStackedAbsoluteValuesChart(
      'vpcs-by-region-and-size',
      'Number of VPCs',
      'by Region and Size',
      {},
      [],
      10,
      true,
      true,
    );
    loadDonutChart('vpcs-by-size', 'VPCs by Size', {});
    loadStackedAbsoluteValuesChart(
      'vpcs-by-region-and-type',
      'Number of VPCs',
      'by Region and Type',
      {},
      [],
      10,
      true,
      true,
    );
    loadDonutChart('vpcs-by-type', 'VPCs by Type', {});
    loadLegendTile('regions-need-cidrs', 'CIDRs needed', 'Regions running out of CIDRs', []);
    $('#table-vpc-data').DataTable().clear().draw();
  }

  function loadDashboardData(timeframe, sk) {
    loadOrderStatistics('create-vpc', 'orders', 'create-vpc', 'monthly', sk);
    loadOrderStatistics('delete-vpc', 'orders', 'destroy-vpc', 'monthly', sk);
    loadOrderStatistics('create-4wheels', 'orders', 'create-4wheels-cluster', 'monthly', sk);

    loadUsageData(timeframe, sk);
    loadTypeData(timeframe, sk);
    loadCidrData(timeframe, sk);
    loadCidrsRequiredData(timeframe, sk);
  }

  async function loadUsageData(timeframe, sk) {
    addSpinner();
    document.querySelector('.loading-form-inline')?.classList.add('loading-animation');

    try {
      usageData = await baseApiAxios.get(
        `/stats/categories/vpc/statistics/usage?time_frame=${timeframe}${sk ? '&sort_key=' + sk : ''}`,
        null,
        {},
      );

      if (usageData?.stats?.length) {
        let dataObj = usageData.stats[0].data;
        delete dataObj['count'];
        let chartData = {};
        let countRegions = {};
        let sizeObject = {};

        Object.entries(dataObj).forEach(([size, data]) => {
          chartData[`/${size}`] = data;
          let regionData = data;
          sizeObject[`/${size}`] = data.count;
          Object.entries(regionData)
            .filter(item => item[0] !== 'count')
            .forEach(([region, values]) => {
              countRegions[region] = (countRegions[region] || 0) + values.count;
            });
        });

        loadStackedAbsoluteValuesChart(
          'vpcs-by-region-and-size',
          'Number of VPCs',
          'by Region and Size',
          chartData,
          Object.keys(countRegions),
          Math.max(...Object.values(sizeObject)),
          true,
          true,
        );
        loadDonutChart('vpcs-by-size', 'VPCs by Size', sizeObject);
      }
    } catch (err) {
      console.log(err);
    }

    removeSpinners();
  }

  async function loadTypeData(timeframe, sk) {
    addSpinner();
    document.querySelector('.loading-form-inline')?.classList.add('loading-animation');

    try {
      typeData = await baseApiAxios.get(
        `/stats/categories/vpc/statistics/network_type?time_frame=${timeframe}${sk ? '&sort_key=' + sk : ''}`,
        null,
        {},
      );

      if (typeData?.stats?.length) {
        let chartData = typeData.stats[0].data;
        loadTextTile('number-vpcs', chartData.count, null, true);
        loadTextTile('number-private-vpcs', chartData.private?.count, null, true);
        loadTextTile('number-public-vpcs', chartData.public?.count, null, true);

        delete chartData['count'];
        let vpcRegions = [];
        let nrVpcsByType = {};

        Object.entries(chartData).forEach(([type, regionData]) => {
          vpcRegions = vpcRegions.concat(Object.keys(regionData));
          nrVpcsByType[type] = regionData.count;
        });

        loadStackedAbsoluteValuesChart(
          'vpcs-by-region-and-type',
          'Number of VPCs',
          'by Region and Type',
          chartData,
          vpcRegions.filter((value, index, self) => self.indexOf(value) === index),
          Math.max(...Object.values(nrVpcsByType)),
          true,
          true,
        );
        loadDonutChart('vpcs-by-type', 'VPCs by Type', nrVpcsByType);

        loadVpcTable();
      }
    } catch (err) {
      console.log(err);
    }

    removeSpinners();
  }

  async function loadCidrData(timeframe, sk) {
    addSpinner();
    document.querySelector('.loading-form-inline')?.classList.add('loading-animation');

    try {
      cidrData = await baseApiAxios.get(
        `/stats/categories/cidr/statistics/free_cidrs?time_frame=${timeframe}${sk ? '&sort_key=' + sk : ''}`,
        null,
        {},
      );

      if (cidrData?.stats?.length) {
        loadSelectFieldBarChart(
          'free-cidrs-by-region-size',
          'Free CIDRs',
          'by Region and Size',
          cidrData.stats[0].data,
          '25',
          false,
        );
      }
    } catch (err) {
      console.log(err);
    }

    removeSpinners();
  }

  async function loadCidrsRequiredData(timeframe, sk) {
    addSpinner();
    document.querySelector('.loading-form-inline')?.classList.add('loading-animation');

    try {
      cidrsRequiredData = await baseApiAxios.get(
        `/stats/categories/cidr/statistics/regions_need_cidr?time_frame=${timeframe}${sk ? '&sort_key=' + sk : ''}`,
        null,
        {},
      );

      let regions = [];
      if (cidrsRequiredData?.stats?.length) {
        Object.entries(cidrsRequiredData.stats[0].data).forEach(([region, value]) => {
          if (value) regions.push(region);
        });

        loadLegendTile('regions-need-cidrs', 'CIDRs needed', 'Regions running out of CIDRs', regions);
      }
    } catch (err) {
      console.log(err);
    }

    removeSpinners();
  }

  async function loadVpcTable() {
    addSpinner();
    document.querySelector('.loading-form-inline')?.classList.add('loading-animation');

    let chartDataObj = typeData.stats[0].data;
    let tableDataObj = {};

    Object.entries(chartDataObj).forEach(([type, regionData]) => {
      Object.entries(regionData).forEach(([region, values]) => {
        if (region === 'count') return;
        if (!tableDataObj[region]) tableDataObj[region] = {};
        tableDataObj[region][type] = values.count;
        tableDataObj[region]['count'] = (tableDataObj[region]['count'] || 0) + values.count;
        Object.entries(values).forEach(([size, nr]) => {
          if (size === 'count') return;
          tableDataObj[region][size] = (tableDataObj[region][size] || 0) + nr;
        });
      });
    });

    const tableData = Object.entries(tableDataObj).map(([key, dataset]) => Object.assign({ region: key }, dataset));

    initDataTable(
      'table-vpc-data',
      'lCfrtpBi',
      [
        {
          extend: 'excelHtml5',
          text: 'Export Excel',
          exportOptions: {
            columns: ':visible',
          },
          titleAttr: 'Export the visible columns as Excel file',
        },
        {
          extend: 'csvHtml5',
          text: 'Export CSV',
          exportOptions: {
            columns: ':visible',
          },
          titleAttr: 'Export the visible columns as CSV file',
        },
      ],
      [
        {
          visible: true,
          defaultContent: '-',
          orderable: true,
          searchable: true,
          data: 'region',
          name: 'region_col',
          title: 'Region',
          createdCell: addCopyButton,
        },
        {
          visible: true,
          defaultContent: '-',
          orderable: true,
          searchable: true,
          data: 'count',
          name: 'count_col',
          title: 'Count',
          createdCell: addCopyButton,
        },
        {
          visible: true,
          defaultContent: '-',
          orderable: true,
          searchable: true,
          data: 'special',
          name: 'special_col',
          title: 'Special',
          createdCell: addCopyButton,
        },
        {
          visible: true,
          defaultContent: '-',
          orderable: true,
          searchable: true,
          data: 'external',
          name: 'external_col',
          title: 'External',
          createdCell: addCopyButton,
        },
        {
          visible: true,
          defaultContent: '-',
          orderable: true,
          searchable: true,
          data: 'private',
          name: 'private_col',
          title: 'Private',
          createdCell: addCopyButton,
        },
        {
          visible: true,
          defaultContent: '-',
          orderable: true,
          searchable: true,
          data: 'public',
          name: 'public_col',
          title: 'Public',
          createdCell: addCopyButton,
        },
        {
          visible: true,
          defaultContent: '-',
          orderable: true,
          searchable: true,
          data: 'unknown',
          name: 'unknown_col',
          title: 'Unknown',
          createdCell: addCopyButton,
        },
        ...[...Array(9).keys()].map(key => {
          const size = key + 20;
          return {
            visible: false,
            defaultContent: '-',
            orderable: true,
            searchable: true,
            data: `${size}`,
            name: `${size}_col`,
            title: `Size ${size}`,
            createdCell: addCopyButton,
          };
        }),
      ],
      null,
      null,
    );

    const dt = $('#table-vpc-data').DataTable();
    dt.rows().remove();
    dt.rows.add(tableData).draw(false);

    removeSpinners();
  }

  return [page, onPageReady];
}
