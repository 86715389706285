import {
  addToast,
  addCopyButton,
  addContentButtonWithTooltip,
  addLoadAccountDetailsButton,
  addLoadTrainingDetailsButton,
  rowDetailsClickHandler,
  configureTableColumns,
  InfoTable,
  RowDetails,
} from './main';
import { getData } from './databases';
import { apiAxios, showError, baseApiAxios } from './api';
import swal from 'sweetalert';
import { accountIdPattern } from './accounts';
import { addSpinner, addTableSpinner } from './sidebar';
import { initDataTable } from './datatable';

// tableId that can be used by other functions to access the active datatable
let tableIdTrainingAccounts;

// Column configuration for the datatable
export const trainingAccountsTableColumns = [
  { id: 'select_col' },
  { id: 'aws_account_id_col', name: 'Account ID', attribute_name: 'account_id' },
  { id: 'account_root_email_col', name: 'Root Account', attribute_name: 'account_root_email' },
  { id: 'creation_date_col', name: 'Creation Date', attribute_name: 'creation_date' },
  { id: 'fpc_status_col', name: 'FPC Status', attribute_name: 'fpc_status' },
  { id: 'account_friendly_name_col', name: 'Account Friendly Name', attribute_name: 'account_friendly_name' },
  { id: 'description_col', name: 'Description', attribute_name: 'description' },
  { id: 'account_area_col', name: 'Account Area', attribute_name: 'account_area' },
  { id: 'account_type_col', name: 'Account Type', attribute_name: 'account_type' },
  { id: 'account_stage_col', name: 'Account Stage', attribute_name: 'account_stage' },
  { id: 'org_id_col', name: 'Org Id', attribute_name: 'org_id' },
  { id: 'org_ou_id_col', name: 'Org Unit Id', attribute_name: 'org_ou_id' },
  { id: 'org_ou_name_col', name: 'Org Unit Name', attribute_name: 'org_ou_name' },
  { id: 'org_status_col', name: 'Org Status', attribute_name: 'org_status' },
  { id: 'org_status_update_col', name: 'Last Org Update (UTC)', attribute_name: 'org_status_update' },
  { id: 'training_status_col', name: 'Training Status', attribute_name: 'training_data.status' },
  { id: 'training_id_col', name: 'Training Id', attribute_name: 'training_data.training_id' },
  { id: 'training_status_update_col', name: 'Last Status Update (UTC)', attribute_name: 'training_data.status' },
  { id: 'actions_col', name: 'Actions' },
];

/**
 * Initialize the Training Accounts datatable
 * @param {string} tableId
 */
export function initTrainingsAccountTable(tableId) {
  tableIdTrainingAccounts = tableId;
  configureTableColumns(tableId, trainingAccountsTableColumns);

  initDataTable(
    tableId,
    'lCfrtpBi',
    [
      {
        extend: 'excelHtml5',
        text: 'Export Excel',
        exportOptions: {
          columns: ':visible',
        },
        titleAttr: 'Export the visible columns as Excel file',
      },
      {
        extend: 'csvHtml5',
        text: 'Export CSV',
        exportOptions: {
          columns: ':visible',
        },
        titleAttr: 'Export the visible columns as CSV file',
      },
      {
        extend: 'copyHtml5',
        text: 'Copy',
        exportOptions: {
          columns: ':visible',
        },
        titleAttr: 'Copy the visible columns into your clipboard',
      },
      {
        extend: 'resetTable',
        ajaxReload: false,
        titleAttr: 'Reset all filters in the table footer',
      },
      {
        extend: 'reloadTable',
        text: 'Reload Accounts',
        ajaxReload: false,
        methodReload: loadTrainingAccountsData,
        titleAttr: 'Reload AWS accounts (no-cache)',
      },
    ],
    [
      {
        visible: true,
        defaultContent: '',
        orderable: false,
        searchable: false,
        data: null,
        name: 'select_col',
        class: 'details-control',
        width: '20px',
      },
      {
        visible: true,
        defaultContent: 'unknown',
        orderable: true,
        searchable: true,
        data: 'account_id',
        name: 'account_id_col',
        title: 'Account Id',
        createdCell: function (td, cellData) {
          addCopyButton(td);
          addLoadAccountDetailsButton(td);

          if (!accountIdPattern.test(cellData)) {
            $(td).addClass('portal-danger');
          }
        },
      },
      {
        visible: false,
        defaultContent: 'unknown',
        orderable: true,
        searchable: true,
        data: 'account_root_email',
        name: 'account_root_email_col',
        title: 'Root Account',
        createdCell: addCopyButton,
      },
      {
        visible: false,
        defaultContent: 'unknown',
        orderable: true,
        searchable: true,
        data: 'creation_date',
        name: 'creation_date_col',
        title: 'Creation Date',
        createdCell: addCopyButton,
      },
      {
        visible: false,
        defaultContent: 'unknown',
        orderable: true,
        searchable: true,
        data: 'fpc_status',
        name: 'fpc_status_col',
        title: 'FPC Status',
        createdCell: addCopyButton,
      },
      {
        visible: true,
        defaultContent: 'unknown',
        orderable: true,
        searchable: true,
        data: 'account_friendly_name',
        name: 'account_friendly_name_col',
        title: 'Account Friendly Name',
        createdCell: addCopyButton,
      },
      {
        visible: false,
        defaultContent: '',
        orderable: true,
        searchable: true,
        data: 'description',
        name: 'description_col',
        title: 'Description',
        createdCell: addCopyButton,
      },
      {
        visible: false,
        defaultContent: 'unknown',
        orderable: true,
        searchable: true,
        data: 'account_area',
        name: 'account_area_col',
        title: 'Account Area',
        createdCell: addCopyButton,
      },
      {
        visible: false,
        defaultContent: 'unknown',
        orderable: true,
        searchable: true,
        data: 'account_type',
        name: 'account_type_col',
        title: 'Account Type',
        createdCell: addCopyButton,
      },
      {
        visible: false,
        defaultContent: 'unknown',
        orderable: true,
        searchable: true,
        data: 'account_stage',
        name: 'account_stage_col',
        title: 'Account Stage',
        createdCell: function (td, cellData) {
          addCopyButton(td);

          if (cellData === 'basic') {
            $(td).addClass('portal-danger');
          }
        },
      },
      {
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'org_id',
        name: 'org_id_col',
        title: 'Org Id',
        createdCell: addCopyButton,
      },
      {
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'org_ou_id',
        name: 'org_ou_id_col',
        title: 'Org Unit Id',
        createdCell: addCopyButton,
      },
      {
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'org_ou_name',
        name: 'org_ou_name_col',
        title: 'Org Unit Name',
        createdCell: addCopyButton,
      },
      {
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'org_status',
        name: 'org_status_col',
        title: 'Org Status',
        createdCell: function (td, cellData) {
          addCopyButton(td);

          if (cellData === 'ACTIVE') {
            $(td).addClass('portal-success');
          } else {
            $(td).addClass('portal-neutral');
          }
        },
      },
      {
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'org_status_update',
        name: 'org_status_update_col',
        title: 'Last Org Update (UTC)',
        createdCell: addCopyButton,
      },
      {
        visible: true,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'training_data.status',
        name: 'training_status_col',
        title: 'Training Status',
        createdCell: function (td, cellData) {
          addCopyButton(td);

          if (['AVAILABLE', 'ASSIGNED'].includes(cellData)) {
            $(td).addClass('portal-success');
          } else if (cellData === 'MAINTENANCE') {
            $(td).addClass('portal-danger');
          } else if (cellData === 'CLEANUP') {
            $(td).addClass('portal-warning');
          }
        },
      },
      {
        visible: true,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'training_data.training_id',
        name: 'training_id_col',
        title: 'Training Id',
        createdCell: function (td) {
          addCopyButton(td);
          addLoadTrainingDetailsButton(td);
        },
      },
      {
        visible: true,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'training_data.status_update',
        name: 'training_status_update_col',
        title: 'Last Status Update (UTC)',
        createdCell: addCopyButton,
      },
      {
        visible: true,
        defaultContent: '',
        orderable: false,
        data: null,
        name: 'actions_col',
        title: 'Actions',
        createdCell: addTrainingAccountTableActions,
        class: 'details-edit',
        width: '50px',
      },
    ],
    function (row, data) {
      if (data.fpc_status === 'deleted') {
        $(row).addClass('row-deleted');
      } else if (data.fpc_status === 'halt') {
        $(row).addClass('row-inactive');
      }
    },
    {
      order: [[1, 'asc']],
    },
  );

  rowDetailsClickHandler({ tableId: tableId, rowDetailCallback: loadAndFormatTrainingAccountsRowDetails });
  loadTrainingAccountsData(tableId);
}

/**
 *
 * @param {*} tableId
 * @param {*} headers
 * @param {*} forceReload
 */
function loadTrainingAccountsData(tableId, headers, forceReload) {
  $(() => addSpinner());
  $(() => addTableSpinner());

  const dropdownColumns = [
    'fpc_status',
    'account_area',
    'account_type',
    'account_stage',
    'org_id',
    'org_ou_id',
    'org_ou_name',
    'org_status',
    'training_data.status',
  ];
  const searchColumns = [
    'account_id',
    'account_root_email',
    'creation_date',
    'account_friendly_name',
    'description',
    'org_status_update',
    'training_data.training_id',
    'training_data.status_update',
  ];

  getData({
    apiPath: '/trainings/accounts',
    dataAttribute: 'account_data',
    tableId: tableId,
    tableColumns: trainingAccountsTableColumns,
    dropdownColumns: dropdownColumns,
    searchColumns: searchColumns,
    headers: headers,
    forceReload: forceReload,
  });
}

/**
 *
 * @param {*} row
 */
function loadAndFormatTrainingAccountsRowDetails(row) {
  const navigationTabs = [
    {
      Value: 'details',
      Name: 'Account Details',
      active: true,
      renderDetails: formatTrainingAccountsTab,
    },
  ];

  // Load the complete training data before the child row will be formatted
  apiAxios
    .get('/trainings/accounts/' + row.data().account_id)
    .then(response => {
      const rowDetails = (
        <RowDetails tabDefinition={navigationTabs} rowData={response.data} tabIdAttribute={'account_id'} />
      );
      row.child(rowDetails);
      row.child()[0].setAttribute('class', 'rowDetails');
    })
    .finally();
}

/**
 *
 * @param {*} param0
 * @returns
 */
function formatTrainingAccountsTab({ rowData: { account_data } }) {
  return (
    <>
      <div class="col-lg-6 col-md-12" name="training-account-details">
        <ChildRowAccountDetails accountData={account_data} />
      </div>
      <div class="col-lg-6 col-md-12" name="training-account-details">
        <ChildRowTrainingAccountDetails accountData={account_data} />
      </div>
      <div class="col-lg-12 col-md-12" name="training-account-details-button">
        <ChildRowTrainingAccountDetailsButtons accountData={account_data} />
      </div>
    </>
  );
}

/**
 *
 * @param {*} param0
 * @returns
 */
function ChildRowAccountDetails({ accountData }) {
  const trainingAccountInfoFields = [
    { Name: 'Account Id', Value: 'account_id' },
    { Name: 'Friendly Name', Value: 'account_friendly_name' },
    { Name: 'Description', Value: 'description' },
    { Name: 'FPC Status', Value: 'fpc_status' },
    { Name: 'Account Area', Value: 'account_area' },
    { Name: 'Account Type', Value: 'account_type' },
    { Name: 'Account Stage', Value: 'account_stage' },
    { Name: 'AWS Support Plan', Value: 'support_plan' },
    { Name: 'IT Responsible', Value: 'it_responsible' },
    { Name: 'Primary Responsible', Value: 'primary_responsible' },
    { Name: 'Secondary Responsible', Value: 'sec_responsible' },
    { Name: 'Creation Date', Value: 'creation_date' },
    { Name: 'Organizations Master Account', Value: 'master_account' },
    { Name: 'Organizations Id', Value: 'org_id' },
    { Name: 'Organizations Name', Value: 'org_name' },
    { Name: 'Organizations Unit Id', Value: 'org_ou_id' },
    { Name: 'Organizations Unit Name', Value: 'org_ou_name' },
    { Name: 'Organizations Account Status', Value: 'org_status' },
    { Name: 'Last Org Update (UTC)', Value: 'org_status_update' },
  ];

  return (
    <>
      <div class="detailsContent">
        <h4>Account Details</h4>
        <div>
          <InfoTable data={accountData} rowInfos={trainingAccountInfoFields} />
        </div>
      </div>
    </>
  );
}

/**
 *
 * @param {*} param0
 * @returns
 */
function ChildRowTrainingAccountDetails({ accountData }) {
  const trainingInfoFields = [
    { Name: 'Training Id', Value: 'training_data.training_id' },
    { Name: 'Training Type', Value: 'training_data.training_type' },
    { Name: 'Status', Value: 'training_data.status' },
    { Name: 'Status Update (UTC)', Value: 'training_data.status_update' },
    { Name: 'Status Updated By', Value: 'training_data.updated_from' },
    { Name: 'Maintenance Reason', Value: 'training_data.maintenance_message' },
  ];

  return (
    <>
      <div class="detailsContent">
        <h4>Training Details</h4>
        <div>
          <InfoTable data={accountData} rowInfos={trainingInfoFields} />
        </div>
      </div>
    </>
  );
}

function configureButtonVisibility({ accountData }) {
  const showMaintenanceButton = accountData.training_data && !['ASSIGNED'].includes(accountData.training_data.status);
  const showNukeButton =
    accountData.training_data && ['ASSIGNED', 'AVAILABLE'].includes(accountData.training_data.status);

  return {
    showMaintenanceButton: showMaintenanceButton,
    showNukeButton: showNukeButton,
  };
}

/**
 *
 * @param {object} accountData
 * @returns
 */
function ChildRowTrainingAccountDetailsButtons({ accountData }) {
  // Configure the visibility of the buttons
  const { showMaintenanceButton, showNukeButton } = configureButtonVisibility({ accountData: accountData });

  // Define the set maintenance button and add the onclick handler
  const maintenanceButton = (
    <button type="button" class="btn-sm space" id="maintenanceModeButton" title="Enable/Disable Maintenance Mode">
      <i class="fas fa-tools"></i> Maintenance Mode
    </button>
  );
  $(maintenanceButton).on('click', () => setMaintenanceMode(accountData));

  // Define the trigger cleanup button and add the onclick handler
  const triggerCleanupButton = (
    <button type="button" class="btn-sm space" title="Nuke Account">
      <i class="fas fa-bomb"></i> Nuke Account
    </button>
  );
  $(triggerCleanupButton).on('click', () => createNukeAccountOrder(accountData));

  if (showMaintenanceButton || showNukeButton) {
    // Return all buttons
    return (
      <div class="detailsContent">
        <div class="btn-group detail-actions-btn-group">
          {showMaintenanceButton ? maintenanceButton : undefined}
          {showNukeButton ? triggerCleanupButton : undefined}
        </div>
      </div>
    );
  }
}

/**
 *
 * @param {*} td
 * @param {*} cellData
 * @param {*} rowData
 * @param {*} row
 * @param {*} col
 */
function addTrainingAccountTableActions(td, _cellData, rowData, _row, _col) {
  // Configure the visibility of the buttons
  const { showMaintenanceButton, showNukeButton } = configureButtonVisibility({ accountData: rowData });

  // Define a div which contains all the action buttons
  const btnGroup = <div class="table-action-button-group" />;
  td.appendChild(btnGroup);

  if (showMaintenanceButton) {
    addContentButtonWithTooltip(
      btnGroup,
      'Enable/Disable Maintenance Mode',
      'fas fa-tools',
      setMaintenanceMode,
      rowData,
    );
  }

  if (showNukeButton) {
    addContentButtonWithTooltip(btnGroup, 'Request Nuke Account', 'fas fa-bomb', createNukeAccountOrder, rowData);
  }
}

/**
 *
 * @param {*} account_data
 */
function setMaintenanceMode(accountData) {
  const trainingData = accountData.training_data;
  const targetStatus = trainingData.status === 'MAINTENANCE' ? 'AVAILABLE' : 'MAINTENANCE';

  const statusMessage = (
    <p>
      Update training status from <strong>{trainingData.status}</strong> to <strong>{targetStatus}</strong>?
    </p>
  );

  const maintenanceMessageInput = (
    <textarea
      class="form-control"
      id="maintenanceReason"
      rows="3"
      placeholder="Maintenance reason"
      aria-label="maintenance-reason"
    />
  );

  const swalContent = (
    <div>
      {statusMessage}
      {trainingData.status !== 'MAINTENANCE' ? maintenanceMessageInput : undefined}
    </div>
  );

  swal({
    title: 'Update Training Status?',
    content: swalContent,
    buttons: {
      cancel: {
        text: 'No',
        value: null,
        visible: true,
      },
      okay: {
        text: 'Yes',
        value: true,
        visible: true,
      },
    },
  }).then(swalResponse => {
    if (swalResponse) {
      const payload = {
        account_data: accountData,
        training_data: {
          status: targetStatus,
          maintenance_message: $('#maintenanceReason').val(),
          status_update: new Date().toISOString().substring(0, 19),
        },
      };

      apiAxios
        .patch('/trainings/accounts/' + accountData.account_id, payload, {
          headers: { 'Content-Type': 'application/json' },
        })
        .then(patchResponse => {
          addToast('Update Maintenance Mode', patchResponse.data.message, 6000);
          loadTrainingAccountsData(tableIdTrainingAccounts, undefined, true);
        })
        .catch(patchError => {
          showError(patchError);
        });
    }
  });
}

function createNukeAccountOrder(accountData) {
  const orderPayload = {
    action: 'nuke-account',
    account_id: accountData.account_id,
    description: 'Nuke AWS account ' + accountData.account_id,
  };
  const swalContent = (
    <div>
      <p>
        Do you really want to delete all resources in AWS account <strong>{accountData.account_id}</strong>?
      </p>
      <p>
        <strong class="portal-danger">This action cannot be undone.</strong>
      </p>
    </div>
  );

  swal({
    title: 'Nuke AWS Account?',
    content: swalContent,
    icon: 'warning',
    dangerMode: true,
    buttons: {
      cancel: {
        text: 'Cancel',
        value: null,
        visible: true,
      },
      nuke: {
        text: 'Nuke',
        value: true,
        visible: true,
        className: 'swal-button swal-button--confirm swal-button--danger',
      },
    },
  }).then(swalResponse => {
    if (swalResponse) {
      baseApiAxios
        .createOrder(orderPayload)
        .then(orderResponse => {
          addToast('Create Order', orderResponse.message, 6000);
        })
        .catch(orderError => {
          showError(orderError);
        });
    }
  });
}
