import { baseApiAxios } from './js/portal/api';
import { showErrorFromApiOperation } from './js/portal/main';
import { getSearchParamsUrl, removeAllSearchParamsUrlExcept } from './js/portal/search_params';
import { addSpinner, removeSpinners } from './js/portal/sidebar';
import HostedZoneDropdown from './jsx/components/forms/HostedZoneDropdown';
import HostedZoneTabs from './jsx/components/forms/HostedZoneTabs';

function loadHostedZoneData(_eventTypeId, _eventCategory, _option, domain) {
  if (!domain) return;
  setTimeout(async () => {
    try {
      addSpinner();
      const hosted_zone_result = await baseApiAxios.getHostedZone(domain, {}, {});
      removeSpinners();
      $('#hosted-zone-details-main').html(<HostedZoneTabs data={hosted_zone_result} />);
    } catch (err) {
      showErrorFromApiOperation('Fetching Hosted Zone information failed.')(err);
    }
  }, 0);
}

export default function HostedZonesDetailsPage() {
  const page = (
    <>
      <div class="row">
        <div class="col-lg-12">
          <div class="my-2">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#listhostedzone">Hosted Zones</a>
                </li>
                <li class="breadcrumb-item"></li>
                <li id="resource-hosted-zone">
                  <HostedZoneDropdown label="none" onchange={loadHostedZoneData} listAll={true} urlParams />
                </li>
                <li class="breadcrumb-item edit-account-show" style="display:none;"></li>
              </ol>
            </nav>
          </div>
          <div id="hosted-zone-details-main" class="edit-account-hide my-5">
            <HostedZoneTabs data={{}} />
          </div>
        </div>
      </div>
    </>
  );

  function onPageReady() {
    removeAllSearchParamsUrlExcept(['domain']);
    if (!getSearchParamsUrl('domain')) {
      $('#hosted-zone-id').val(1);
      $('#hosted-zone-id').selectpicker('refresh');
    } else {
      $('#resource-hosted-zone').html(
        <HostedZoneDropdown label="none" onchange={loadHostedZoneData} listAll={true} urlParams />,
      );
    }
  }

  return [page, onPageReady];
}
