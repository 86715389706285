import { baseApiAxios, showError } from '../../../js/portal/api';
import { getSearchParamsUrl, setSearchParamsUrl } from '../../../js/portal/search_params';

/**
 * Creates a new selectpicker dropdown including a label and a refresh button.
 *
 * @param {string} label - name label on the left side of the dropdown menu
 * @param {string} id - the id of the selectpicker
 * @param {function} onchange - a function that will be triggered once the selectpicker value changes
 * @param {function} onrefresh - function that is triggered when the refresh button is clicked
 * @param {type[]} selectedValues - a list of Orders that should be selected if available
 * @param {boolean} deselect - defines if the deselect Orders button is added to the end of the selectpicker
 * @param {boolean} multiple - defines if you can select one or multiple items in the Order dropdown
 * @param {boolean} listAll - display all resources that the user has permission to see/edit
 * @param {boolean} disableUrlParams - disable setting and reading URL parameters
 * @param {number} daysThreshold - display orders from the past x days
 *
 * @returns div including a label, the dropdown and a refresh button
 */
export default function OrderDropdown({
  label,
  id,
  onchange,
  onrefresh,
  selectedValues,
  deselect,
  multiple,
  listAll,
  disableUrlParams,
  daysThreshold,
}) {
  const labelText = label ? label : 'Order';
  const selectId = id ? id : 'order-id';
  const headers = { 'Cache-Control': 'max-age=0, must-revalidate' };
  const params = {
    short: !listAll,
  };
  const multiSelect = multiple ? true : false;
  const urlParameters = !disableUrlParams ? true : false;

  // selectpicker options: https://developer.snapappointments.com/bootstrap-select/options/
  const select = (
    <select
      required
      class="form-control form-select selectpicker"
      data-live-search="true"
      data-show-subtext="true"
      id={selectId}
      name={selectId}
      data-size="10"
      data-max-options="5"
      data-none-selected-text="- Select one or more Orders -"
      data-selected-text-format="count > 1"
      data-count-selected-text="{0} Orders selected"
      data-dropdown-align-right="auto"
      multiple={multiSelect}>
      <option class="defaultDisabled" value="" disabled selected={!multiSelect}>
        - Select an Order -
      </option>
    </select>
  );

  // Refresh button to reload the orders
  const refreshIcon = <i class="fas fa-sync" />;
  const refreshButton = (
    <button
      type="button"
      class="btn btn-light-grey input-group-btn portal-selectpicker-end"
      id="refresh-orders"
      title="Reload Orders">
      {refreshIcon}
    </button>
  );

  // De-select button
  const deselectButton = (
    <button
      type="button"
      class="btn btn-light-grey input-group-btn portal-selectpicker-mid"
      id="deselect-order"
      title="De-select all Orders">
      <i class="fa fa-user-alt-slash" />
    </button>
  );

  // Represents the whole row including the label, the selectpicker and the refresh button
  const orderDropdown = (
    <div class="form-group row portal-dropdown">
      {labelText != 'none' && (
        <label for="order-id" class="col-form-label">
          {labelText}
        </label>
      )}
      <div class="col input-group">
        {select}
        {deselect ? deselectButton : undefined}
        {refreshButton}
      </div>
    </div>
  );

  // Refresh the selectpicker and add an on change event
  $(select).selectpicker('refresh');
  $(select).on('changed.bs.select', () => {
    const selectedOption = $(select).find(':selected');
    const selectedOrderId = selectedOption.val();

    if (!multiSelect && urlParameters) {
      const previousUrlParam = getSearchParamsUrl('order_id');
      if (selectedOrderId && selectedOrderId !== previousUrlParam) {
        setSearchParamsUrl({ order_id: selectedOrderId });
      }
    }

    if (onchange && selectedOrderId) {
      onchange('order_id', 'order', selectedOption, selectedOrderId);
    }
  });

  $(select).on('reload-dropdown-options', event => {
    const dropdownSettings = Object.assign(
      {
        selectedValues,
        multiSelect,
        urlParameters,
      },
      event.detail,
    );

    loadOrders(
      select,
      refreshIcon,
      dropdownSettings.selectedValues,
      dropdownSettings.multiSelect,
      dropdownSettings.urlParameters,
      daysThreshold,
      headers,
      params,
    );
  });

  // Reload the Order records from the REST API (with cache-control headers)
  $(refreshButton).on('click', event => {
    if (onrefresh) {
      onrefresh(event, select);
    } else {
      loadOrders(select, refreshIcon, selectedValues, multiSelect, urlParameters, daysThreshold, headers, params);
    }
  });

  // De-select all Orders from the selectpicker
  $(deselectButton).on('click', () => {
    $(select).selectpicker('deselectAll');
    $(select).selectpicker('refresh');
  });

  // Load the Order records from the REST API (without cache-control headers)
  loadOrders(select, refreshIcon, selectedValues, multiSelect, urlParameters, daysThreshold, {}, params);
  return orderDropdown;
}

/**
 * Populates the selectpicker dropdown with the values from the REST API endpoint. Deletes all active dropdown items
 * before adding the new items.
 *
 * @param {JSX.IntrinsicElements.select} select
 * @param {JSX.Element} refreshIcon
 * @param {type[]} selectedValues
 * @param {boolean} multiSelect
 * @param {boolean} urlParameters
 * @param {number} daysThreshold
 * @param {object} headers
 * @param {object} params
 */
async function loadOrders(
  select,
  refreshIcon,
  selectedValues,
  multiSelect,
  urlParameters,
  daysThreshold,
  headers,
  params,
) {
  $(refreshIcon).addClass('fa-spin');
  select.querySelectorAll('option:not(.defaultDisabled)').forEach(el => el.remove());
  $(select).attr('disabled', true).selectpicker('refresh');

  try {
    let payload = Object.assign(
      {
        attributes: ['action', 'proposer', 'creation_date', 'approved', 'executed', 'id', 'account_id'],
        table_data: {
          start: 0,
          length: 0, // set to 500 for sequential requests of 500 items
        },
      },
      params,
    );

    if (daysThreshold && !isNaN(daysThreshold)) {
      const start_date = new Date(new Date().getTime() - daysThreshold * 24 * 60 * 60 * 1000)
        .toISOString()
        .replace('T', ' ');
      payload = Object.assign(payload, {
        start_date: start_date.substring(0, start_date.indexOf('.')),
        all_new_orders: true,
      });
    }

    let exclusive_start_key = {};
    let page = 0;
    let orders_length = -1;
    let options = [];

    while (orders_length === 500 || page === 0) {
      if (exclusive_start_key && Object.keys(exclusive_start_key).length > 0) {
        payload['exclusive_start_key'] = exclusive_start_key;
      }

      const orders_response = await baseApiAxios.getOrders(payload, headers, params);

      // Retrieve a order id from the URL search parameters
      const selected_order = !multiSelect && urlParameters && getSearchParamsUrl('order_id');

      let selected_order_found = false;
      let number_orders = { NEW: 0, FAILED: 0, SUM: 0 };

      // Add all account items as options to the selectpicker
      options = options.concat(
        orders_response.orders.map(order => {
          let opt = createDropdownOption(order);
          opt.selected =
            (selected_order && selected_order == order.id) || (selectedValues && selectedValues.includes(order.id))
              ? true
              : false;

          number_orders['SUM'] += 1;
          if (order.approved === 'NEW') number_orders[order.approved] += 1;
          if (order.executed === 'FAILED') number_orders[order.executed] += 1;
          if (opt.selected) selected_order_found = true;
          return opt;
        }),
      );

      // if order_id not in results of the past <daysThreshold> days,
      // try to fetch directly and append the option to the select field
      if (daysThreshold && selected_order && !selected_order_found) {
        const order_response = await baseApiAxios.getOrder(selected_order, headers, params, payload);
        if (order_response && order_response.order) {
          const opt = createDropdownOption(order_response.order);
          opt.selected = true;
          options = options.concat([opt]);
          number_orders['SUM'] += 1;
          if (order_response.order.approved === 'NEW') number_orders[order_response.order.approved] += 1;
          if (order_response.order.executed === 'FAILED') number_orders[order_response.order.executed] += 1;
        }
      }

      // Sort the selectpicker options in asc order by the option text
      options.sort(function (a, b) {
        return a.value.localeCompare(b.value);
      });

      // Add all options to the selectpicker
      options.forEach(option => {
        select.appendChild(option);
      });
      const event = new CustomEvent('dropdown-options-loaded', {
        detail: {
          number_new: number_orders.NEW,
          number_failed: number_orders.FAILED,
          number_sum: number_orders.SUM,
          page,
        },
      });
      select.dispatchEvent(event);
      $(select).trigger('changed.bs.select');

      const json = orders_response?.orders;
      orders_length = json?.length;

      if (orders_length) {
        exclusive_start_key = json[json.length - 1];
      }
      page += 1;
    }
  } catch (err) {
    showError(err);
  }

  // Refresh the selectpicker to show the new options
  $(refreshIcon).removeClass('fa-spin');
  $(select).attr('disabled', false);
  $(select).selectpicker('refresh');
}

function createDropdownOption(order) {
  let opt = document.createElement('option');
  opt.value = order.id;
  opt.dataset.content = `<span class="bs-dropdown-badge bs-dropdown-status ${order.approved.toLowerCase()}">${
    order.action
  }</span><span class="bs-dropdown-item-text">${order.proposer}</span><div class="bs-dropdown-badge text-overflow">${
    order.id
  }</div>`;
  return opt;
}
