import { baseApiAxios } from './js/portal/api';
import {
  allowedtoViewContent,
  isAllowedToViewContent,
  frontendConfigItemIds,
  showErrorFromApiOperation,
} from './js/portal/main';
import { showCompareVPCModal } from './js/portal/networks';
import { getSearchParamsUrl, removeAllSearchParamsUrlExcept, removeSearchParamsUrl } from './js/portal/search_params';
import { addSpinner, removeSpinners } from './js/portal/sidebar';
import DXGWDropdown from './jsx/components/forms/DXGWDropdown';
import DXGWTabs from './jsx/components/forms/DXGWTabs';
import VPCDropdown from './jsx/components/forms/VPCDropdown';
import VPCTabs from './jsx/components/forms/VPCTabs';

function loadVPCData(_eventTypeId, _eventCategory, _option, _vpc_id, id, uuid) {
  if (!id || !uuid) return;
  setTimeout(async () => {
    try {
      addSpinner();
      const network_item_result = await baseApiAxios.getNetworkItem(id, uuid, 'VPC');
      removeSpinners();
      $('#network-details-main').html(<VPCTabs data={network_item_result} />);
      const resource_dropdown_container = document.getElementById('resource-dropdown-container');
      if (network_item_result?.network_item?.vpc_id) {
        $('#compare-vpc-button').show();
        resource_dropdown_container.style = 'min-width:50px;width:auto;';
      } else {
        $('#compare-vpc-button').hide();
        resource_dropdown_container.style = '';
      }
    } catch (err) {
      showErrorFromApiOperation('Fetching VPC information failed.')(err);
    }
  }, 0);
}

function loadDXGWData(_eventTypeId, _eventCategory, _option, _dx_gw_id, id, uuid) {
  $('#compare-vpc-button').hide();
  document.getElementById('resource-dropdown-container').style = '';
  if (!id || !uuid) return;
  setTimeout(async () => {
    try {
      addSpinner();
      const network_item_result = await baseApiAxios.getNetworkItem(id, uuid, 'DX_GW');
      removeSpinners();
      $('#network-details-main').html(<DXGWTabs data={network_item_result} />);
    } catch (err) {
      showErrorFromApiOperation('Fetching VPC information failed.')(err);
    }
  }, 0);
}

export default async function NetworksDetailsPage() {
  await isAllowedToViewContent(frontendConfigItemIds['dxgw']);

  removeAllSearchParamsUrlExcept(['vpc_id', 'dx_gw_id']);
  const vpc_id_param = getSearchParamsUrl('vpc_id');
  const dx_gw_id_param = getSearchParamsUrl('dx_gw_id');

  const breadcrumb = (
    <>
      <li
        class="breadcrumb-item no-padding"
        style={allowedtoViewContent[frontendConfigItemIds['dxgw']] ? '' : 'margin: auto 0'}>
        {allowedtoViewContent[frontendConfigItemIds['dxgw']] ? (
          <select
            class="form-control form-select selectpicker"
            id="breadcrumb-type-select"
            name="breadcrumb-type-select"
            data-size="2"
            data-max-options="1"
            data-none-selected-text="- Select the Network Item type -"
            data-dropdown-align-right="auto"
            multiple={false}>
            <option class="breadcrumb-dropdown" value="vpc" selected={true}>
              VPC
            </option>
            {allowedtoViewContent[frontendConfigItemIds['dxgw']] && (
              <option class="breadcrumb-dropdown" value="dxgw">
                DXGW
              </option>
            )}
          </select>
        ) : (
          <span value="vpc" selected={true}>
            VPC
          </span>
        )}
      </li>
    </>
  );

  const page = (
    <>
      <div class="row">
        <div class="col-lg-12">
          <div class="my-2">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb d-flex flex-row">
                {breadcrumb}
                <li class="breadcrumb-item"></li>
                <li id="resource-dropdown-container">
                  <span id="resource-vpc"></span>
                  <span id="resource-dxgw"></span>
                </li>
                <li class="compare-vpc-button-container">
                  <button
                    id="compare-vpc-button"
                    style="display:none;margin:0 0 0 1rem;"
                    class="dt-button"
                    onclick={() => showCompareVPCModal(false, getSearchParamsUrl('vpc_id'))}>
                    <i class="fas fa-highlighter" style="margin-right:4px;" />
                    Compare VPCs
                  </button>
                </li>
              </ol>
            </nav>
          </div>
          <div id="network-details-main" class="edit-account-hide my-5">
            {vpc_id_param && <VPCTabs data={{}} />}
            {dx_gw_id_param && <DXGWTabs data={{}} />}
          </div>
        </div>
      </div>
    </>
  );

  function onPageReady() {
    const $resourceVPCContainer = $('#resource-vpc');
    const $resourceDXGWContainer = $('#resource-dxgw');
    const $mainContent = $('#network-details-main');

    const $type_select = $('#breadcrumb-type-select');
    $type_select.selectpicker('refresh');
    $type_select.on('changed.bs.select', () => {
      const selectedOption = $type_select.find(':selected');
      const selectedType = selectedOption.val();
      if (selectedType === 'vpc') {
        $resourceVPCContainer.html(<VPCDropdown label="none" onchange={loadVPCData} listAll={true} urlParams />);
        $resourceDXGWContainer.html('');
        $('#vpc-id').val(1);
        $('#vpc-id').selectpicker('refresh');
        removeSearchParamsUrl('dx_gw_id');
        $mainContent.html(<VPCTabs data={{}} />);
      } else {
        $resourceDXGWContainer.html(<DXGWDropdown label="none" onchange={loadDXGWData} listAll={true} urlParams />);
        $resourceVPCContainer.html('');
        $('#dxgw-id').val(1);
        $('#dxgw-id').selectpicker('refresh');
        removeSearchParamsUrl('vpc_id');
        $mainContent.html(<DXGWTabs data={{}} />);
      }
    });

    if (vpc_id_param) {
      $resourceVPCContainer.html(
        <VPCDropdown label="none" selectedValues={[vpc_id_param]} onchange={loadVPCData} listAll={true} urlParams />,
      );
      $resourceDXGWContainer.html('');
    } else if (dx_gw_id_param) {
      $type_select.val('dxgw');
      $type_select.selectpicker('refresh');
      $resourceDXGWContainer.html(
        <DXGWDropdown
          label="none"
          selectedValues={[dx_gw_id_param]}
          onchange={loadDXGWData}
          listAll={true}
          urlParams
        />,
      );
      $resourceVPCContainer.html('');
    } else {
      $resourceVPCContainer.html(<VPCDropdown label="none" onchange={loadVPCData} listAll={true} urlParams />);
      $resourceDXGWContainer.html('');
      $('#vpc-id').val(1);
      $('#vpc-id').selectpicker('refresh');
      $mainContent.html(<VPCTabs data={{}} />);
    }
  }

  return [page, onPageReady];
}
